import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyInput from 'react-currency-input-field';

import { Balance } from '../../../assets/icons';

import {
  formatToken,
  fromEurToToken,
  formatEur,
  fromTokenToEur,
  numberWithCommaDecimalsToNumber,
} from '../../../imports/utils';

const CustomTokenInput = ({
  amount,
  setAmount,
  isAmountInTokens,
  setIsAmountInTokens,
}: {
  amount: string;
  setAmount: any;
  isAmountInTokens: boolean;
  setIsAmountInTokens: any;
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<string | undefined>('0');

  const handleChange = (value?: string) => {
    if (!value) {
      value = '0,00';
    }

    const number = parseFloat(value.replace(",","."));

    if(number <= 10000000){
      setInputValue(value);
      setAmount(value?.toString());
    }
  };

  return (
    <div className="max-w-xs mx-auto">
      <div className="flex flex-col items-center justify-center">
        <span
          className="absolute top-[-9999px] opacity-0 text-[2.25rem] font-gotham-bold"
          aria-hidden="true"
        >
          {amount}
        </span>
        <div className="relative flex items-center mx-auto">
          <CurrencyInput
            id="currency-input"
            name="currency-input"
            className={`p-0 max-w-full text-center text-lg w-[${`inputWidth`}]`}
            value={inputValue}
            allowNegativeValue={false}
            placeholder="0,00"
            defaultValue={0}
            decimalsLimit={2}
            onValueChange={(value) => handleChange(value)}
          />
          <div className="absolute top-0 right-0 text-lg">
            {!isAmountInTokens ? '€' : t('token_name').toUpperCase()}
          </div>
        </div>
      </div>
      <div className="relative w-2/3 mx-auto mt-1">
        <hr className="w-full h-px border-none bg-bright-gray" />
        <button
          type="button"
          onClick={() => setIsAmountInTokens(!isAmountInTokens)}
          className="absolute top-0 right-0 translate-x-8 -translate-y-1/2"
        >
          <Balance className="w-5 h-5 text-black fill-current" />
        </button>
      </div>

      <div className="relative flex items-center justify-center mx-auto mt-2">
        <div className="max-w-full p-0 text-lg text-center">
          {!isAmountInTokens
            ? formatToken(
                fromEurToToken(Number(numberWithCommaDecimalsToNumber(amount))),
                true
              )
            : formatEur(
                fromTokenToEur(Number(numberWithCommaDecimalsToNumber(amount)))
              )}
        </div>
        <div className="absolute top-0 text-lg right-11">
          {isAmountInTokens ? '€' : t('token_name').toUpperCase()}
        </div>
      </div>
    </div>
  );
};

export default CustomTokenInput;
