import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store/store';

const currentEnv = process.env.REACT_APP_ENV;
const apiUrl = process.env[`REACT_APP_FIREBASE_API_END_POINT_${currentEnv}`];

export const firestoreRestApi = createApi({
  reducerPath: 'firestoreRestApi',
  tagTypes: ['Contacts'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.authToken;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    createOTPRequestApi: builder.mutation({
      query: (body) => ({ url: `createOTPSession`, method: 'POST', body }),
    }),
    validateOTPRequestApi: builder.mutation({
      query: (body) => ({ url: `validateOTPSession`, method: 'POST', body }),
    }),
    readDiscounts: builder.mutation({
      query: (body) => ({ url: `readDiscounts`, method: 'POST', body }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({ url: `resetPassword`, method: 'POST', body }),
    }),
    createCryptoCheckout: builder.mutation({
      query: (body) => ({ url: `createCryptoCheckout`, method: 'POST', body }),
    }),
    sendEmailNotificationApi: builder.mutation({
      query: (body) => ({ url: `sendEmailNotification`, method: 'POST', body }),
    }),
  }),
});

export const {
  useCreateOTPRequestApiMutation,
  useValidateOTPRequestApiMutation,
  useReadDiscountsMutation,
  useResetPasswordMutation,
  useCreateCryptoCheckoutMutation,
  useSendEmailNotificationApiMutation,
} = firestoreRestApi;
