import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  useValidation,
  useYupValidationResolver,
} from '../../../../../../hooks';

import CustomButton from '../../../../../atoms/Button';
import CustomInput from '../../../../../atoms/Input';
import CustomDatePicker from '../../../../../atoms/DatePicker';
import CustomSelect from '../../../../../atoms/Select';

interface FormTypes {
  document_type: string;
  document_number: string;
  document_release: Date | null;
  document_release_entity: string;
  document_expiry_date: Date | null;
  citizenship: string;
  fiscalCode: string;
}

type Props = {
  prevStep?: () => void;
  nextStep: () => void;
  updateFormData: any;
  formInitialData?: FormTypes;
  buttonLabel?: any;
  disabledForm?: boolean;
};

const StepTwo: React.FC<Props> = ({
  prevStep,
  nextStep,
  updateFormData,
  formInitialData,
  buttonLabel,
  disabledForm,
}) => {
  const { t } = useTranslation();

  const { validatePastDate, validateFutureDate, validateFiscalCode } =
    useValidation();

  const personalDataForm = {
    initialValues: formInitialData,
    validationSchema: Yup.object({
      document_type: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      document_number: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      document_release: validatePastDate().required(
        t('validation.error_field_is_required')
      ),
      document_release_entity: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      document_expiry_date: validateFutureDate().required(
        t('validation.error_field_is_required')
      ),
      citizenship: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      fiscalCode: validateFiscalCode().notRequired().when(
        'citizenship',
        (citizenship, schema) => {
          if (citizenship === 'Italian' || citizenship === 'Italiana') {
            return schema.required(t('validation.error_field_is_required'));
          }
          delete errors.fiscalCode;
          return schema;
        }
      ),
    }),
  };

  const { initialValues, validationSchema } = personalDataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = formState;

  const watchedFields = watch();

  const goBack = () => {
    prevStep?.();
  };

  const onSubmit = (data: any) => {
    updateFormData(data);
    nextStep();
  };

  const isError = !isEmpty(errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:justify-between md:flex-wrap">
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="document_type"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_type')}
          </label>
          <Controller
            control={control}
            name="document_type"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  value={[{ label: 'Seleziona...', id: value }]}
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  options={[
                    { label: t('driver_license'), id: t('driver_license') },
                    { label: t('identity_card'), id: t('identity_card') },
                    { label: t('passport'), id: t('passport') },
                  ]}
                  error={errors.document_type}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.document_type && (
              <p className="text-xs text-error">
                {errors.document_type.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="document_number"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_number')}
          </label>
          <Controller
            control={control}
            name="document_number"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.document_number}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.document_number && (
              <p className="text-xs text-error">
                {errors.document_number.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="document_release"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_release')}
          </label>
          <Controller
            name="document_release"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="relative mt-2">
                <CustomDatePicker
                  onChange={onChange}
                  value={value ? [new Date(value)] : null}
                  error={errors.document_release}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.document_release && (
              <p className="text-xs text-error">
                {errors.document_release.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="document_release_entity"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_release_entity')}
          </label>
          <Controller
            control={control}
            name="document_release_entity"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.document_release_entity}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.document_release_entity && (
              <p className="text-xs text-error">
                {errors.document_release_entity.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="document_expiry_date"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_expiry_date')}
          </label>
          <Controller
            name="document_expiry_date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="relative mt-2">
                <CustomDatePicker
                  onChange={onChange}
                  value={value ? [new Date(value)] : null}
                  error={errors.document_expiry_date}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.document_expiry_date && (
              <p className="text-xs text-error">
                {errors.document_expiry_date.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="citizenship"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('citizenship')}
          </label>
          <Controller
            control={control}
            name="citizenship"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  value={[{ label: t('select_placeholder'), id: value }]}
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  options={[
                    {
                      label: t('citizenship_italian'),
                      id: t('citizenship_italian'),
                    },
                    {
                      label: t('citizenship_other'),
                      id: t('citizenship_other'),
                    },
                  ]}
                  error={errors.citizenship}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.citizenship && (
              <p className="text-xs text-error">{errors.citizenship.message}</p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="fiscalCode"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('fiscalCode')}
          </label>
          <Controller
            control={control}
            name="fiscalCode"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.fiscalCode}
                  disabled={
                    watchedFields.citizenship !== t('citizenship_italian') ||
                    disabledForm
                  }
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.fiscalCode && (
              <p className="text-xs text-error">{errors.fiscalCode.message}</p>
            )}
          </div>
        </div>
      </div>
      {!disabledForm && (
        <div className="flex gap-x-6">
          <CustomButton
            primaryAction={goBack}
            label={t('back_to_prev_step')}
            type="button"
          />
          <CustomButton
            label={buttonLabel !== '' ? buttonLabel : t('next_step')}
            disabled={isError}
            type="submit"
          />
        </div>
      )}
    </form>
  );
};

StepTwo.defaultProps = {
  buttonLabel: '',
  disabledForm: false,
  formInitialData: {
    document_type: '',
    document_number: '',
    document_release: null,
    document_release_entity: '',
    document_expiry_date: null,
    citizenship: '',
    fiscalCode: '',
  },
  prevStep: () => {},
};

export default StepTwo;
