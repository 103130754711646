import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { mit, ProfileFilled } from '../../../assets/images';

import { color } from '../../../imports/utils';

const ProfilePhoto = ({
  image,
  userName,
  noRing,
  userType,
  avatarWithInitial,
  avatarMitToken,
  isActive,
}: {
  image?: string | undefined;
  userName?: string;
  noRing?: boolean;
  userType?: string;
  avatarWithInitial?: boolean;
  avatarMitToken?: boolean;
  isActive?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`w-full h-full overflow-hidden rounded-full ${
        noRing ? 'ring-0' : 'ring'
      } inset-ring ${!isActive ? 'ring-primary' : 'ring-secondary'} ${
        !avatarMitToken ? 'bg-light-gray-variant' : 'bg-light-gray'
      } ${
        userType &&
        (userType === 'consumer' ? 'ring-[#42c3bf]' : 'ring-[#38043a]')
      }`}
    >
      {image && (
        <img
          src={!avatarMitToken ? image : mit}
          alt={
            !avatarMitToken ? userName ?? t('profile_photo') : t('token_name')
          }
          className="object-cover w-full h-full"
        />
      )}
      {!image &&
        (!avatarWithInitial ? (
          <ProfileFilled primaryColor={color('primary')} />
        ) : (
          userName && (
            <p className="flex items-center justify-center w-full h-full text-lg text-white font-gotham-bold">
              {userName.charAt(0).toUpperCase()}
            </p>
          )
        ))}
    </div>
  );
};

ProfilePhoto.defaultProps = {
  image: undefined,
  userName: '',
  noRing: false,
  userType: '',
  avatarWithInitial: false,
  avatarMitToken: false,
  isActive: false,
};

export default ProfilePhoto;
