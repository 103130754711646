import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { application, paypalClientId } from '../../../imports/constants';

const Head = ({ pageTitle }: { pageTitle: string | undefined }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>
        {pageTitle ? `${t('mitgate')} - ${pageTitle}` : t('mitgate')}
      </title>
      <link
        rel="shortcut icon"
        href={`/${application}/favicon.ico`}
        type="image/x-icon"
      />
      <link
        rel="icon"
        href={`/${application}/favicon.ico`}
        type="image/x-icon"
      />
      <link rel="manifest" href={`/${application}/manifest.json`} />
      <script
        src={`https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=EUR&disable-funding=credit,card`}
      />
    </Helmet>
  );
};

export default Head;
