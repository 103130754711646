import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { userSlice } from './user/user.slice';
import { transfersSlice } from './transfers/transfers.slice';
import { publicProfilesSlice } from './publicProfiles/publicProfiles.slice';

const transfersPersistConfig = {
  key: 'transfers',
  storage,
  whitelist: ['list', 'fromBlock'],
};

const reducers = {
  user: userSlice.reducer,
  transfers: persistReducer(transfersPersistConfig, transfersSlice.reducer),
  publicProfiles: publicProfilesSlice.reducer,
};

export default reducers;
