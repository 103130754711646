import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';
import { Copy } from '../../../assets/icons';

type Props = {
  label: string;
  valueToShow: string;
  valueToCopy: string;
};

const InputCopy: React.FC<Props> = ({ label, valueToShow, valueToCopy }) => {
  const { t } = useTranslation();
  const [{ error }, copyToClipboard] = useCopyToClipboard();

  const handleClick = (event: any) => {
    event.stopPropagation();
    copyToClipboard(valueToCopy);
    if (!error) {
      toast.success(t('copy_succesfull'));
    }
  };

  return (
    <div className="my-6">
      <span>{label}</span>
      <div className="flex justify-between items-center border border-[#D7D7D7] min-w-[320px] p-3 rounded-md mt-2">
        <p>{valueToShow}</p>
        <div
          role="button"
          tabIndex={0}
          className="ml-2"
          onClick={handleClick}
          onKeyDown={handleClick}
        >
          <Copy className="w-4 h-4 fill-current" />
        </div>
      </div>
    </div>
  );
};

export default InputCopy;
