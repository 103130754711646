import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Roles } from '../../imports/types';
import { UserState } from '../../store/user/types';

import { useAppSelector } from '../utils/reduxUtils';

const withAuth = (
  Component: React.FC<any>,
  {
    roles,
    redirectTo,
    onRole,
  }: {
    roles: Roles[];
    redirectTo?: string;
    onRole?: { [key: string]: string };
  }
) => {
  const RequiresAuthentication = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { role, privateProfile } = useAppSelector(
      ({ user }: { user: UserState }) => user
    );
    const { tierOne, tierTwo } = privateProfile;

    const redirect = t(redirectTo ?? 'paths.home');

    useEffect(() => {
      if (!roles.includes(role)) {
        if (onRole && onRole[role]) {
          navigate(t(onRole[role]));
          return;
        }

        navigate(redirect);
      }
    }, [role, tierOne, tierTwo]);

    return roles.includes(role) && <Component />;
  };

  return RequiresAuthentication as React.ComponentType<any>;
};

export default withAuth;
