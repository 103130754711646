import * as React from 'react';
import { Input } from 'baseui/input';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import { Alert } from '../../../assets/icons';
import { color } from '../../../imports/utils';

const CustomInput = ({
  name,
  type,
  onBlur,
  onChange,
  inputRef,
  value,
  placeholder,
  error,
  disabled,
}: {
  name?: string;
  type?: string;
  onBlur?: any;
  onChange?: any;
  inputRef?: any;
  value: string | number;
  placeholder?: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  disabled?: boolean;
}) => (
  <>
    <Input
      overrides={{
        Root: {
          style: () => ({
            borderRightColor: 'transparent',
            borderLeftColor: 'transparent',
            borderTopColor: 'transparent',
            borderBottomColor: `${color(!error ? 'primary' : 'error')}`,
            backgroundColor: 'transparent',
            borderBottomWidth: '0.125rem',
          }),
        },
        Input: {
          style: () => ({
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: '0.25rem',
            paddingRight: !error ? '0.25rem' : '1.75rem',
            backgroundColor: 'transparent',
            color: color('gray'),
            fontFamily: 'GothamBookRnd',
          }),
        },
        MaskToggleButton: {
          style: () => ({ display: 'none' }),
        },
      }}
      id={name}
      name={name}
      type={type}
      onBlur={onBlur}
      onChange={onChange}
      inputRef={inputRef}
      value={value}
      placeholder={placeholder}
      clearOnEscape
      disabled={disabled}
    />
    {error && (
      <Alert className="absolute right-0 w-5 h-5 translate-y-[calc(-50%-0.25rem)] -translate-x-1 fill-current top-1/2 text-error" />
    )}
  </>
);

CustomInput.defaultProps = {
  name: undefined,
  type: 'text',
  onBlur: undefined,
  onChange: undefined,
  inputRef: undefined,
  placeholder: '',
  error: undefined,
  disabled: false,
};

export default CustomInput;
