import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { Balance } from '../../../assets/icons';

import { screen, fromTokenToEur, formatEur } from '../../../imports/utils';

const BalanceCard = ({
  balance,
  hasIcon,
  inTokens,
  showVipLevel,
  vipLevel,
  notInteractive,
}: {
  balance: number;
  hasIcon?: boolean;
  inTokens?: boolean; // NOTE is balance in MIT tokens
  showVipLevel?: boolean;
  vipLevel?: number;
  notInteractive?: boolean;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMedia(`(min-width: ${screen('md')})`);

  const [isBalanceInTokens, setIsBalanceInTokens] = useState(inTokens);

  return (
    <article
      className={`relative px-4 pt-4 ${
        !showVipLevel ? 'pb-4' : 'pb-24'
      } bg-white shadow-primary-xl rounded-3xl`}
    >
      <header className="relative flex items-center">
        <h2 className="mx-auto text-lg font-gotham-bold text-primary-text">
          {t('your_balance')}
        </h2>
        {(isDesktop || hasIcon) && (
          <button
            type="button"
            disabled={notInteractive}
            onClick={() => setIsBalanceInTokens(!isBalanceInTokens)}
            className={`absolute right-0 p-2 ml-auto bg-primary-icon rounded-xl ${
              notInteractive ? 'cursor-default' : 'cursor-pointer'
            }`}
          >
            <Balance className="w-5 h-5 fill-current text-primary-text" />
          </button>
        )}
      </header>
      <p className="text-[2.5rem] text-gray font-gotham-bold mt-6 text-center">
        {!isBalanceInTokens ? formatEur(fromTokenToEur(balance)) : balance}
        {isBalanceInTokens && (
          <span className="ml-4 text-[1.7rem]">
            {t('token_name').toUpperCase()}
          </span>
        )}
      </p>
      {showVipLevel && (
        <p className="absolute py-2 mx-auto -translate-x-1/2 rounded-full bottom-8 left-1/2 px-14 bg-orange text-gray font-gotham-bold whitespace-nowrap">{`${t(
          'vip'
        ).toUpperCase()} - ${t('vip_level', { level: vipLevel })}`}</p>
      )}
    </article>
  );
};

BalanceCard.defaultProps = {
  hasIcon: false,
  inTokens: false,
  showVipLevel: false,
  vipLevel: undefined,
  notInteractive: false,
};

export default BalanceCard;
