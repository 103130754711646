import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useApp } from '../../../context/app.context';

import { Close } from '../../../assets/icons';
import { Unsuccessful } from '../../../assets/images';

const UnsuccessfulTransactionDialog = () => {
  const { t } = useTranslation();
  const { dispatch } = useApp();

  const closeDialog = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
  };

  return (
    <div className="p-8 pb-16">
      <header className="flex justify-end">
        <button
          type="button"
          onClick={closeDialog}
          className="p-2 duration-300 ease-in rounded-full hover:bg-light-gray"
        >
          <Close className="w-5 h-5 fill-current text-gray" />
        </button>
      </header>
      <h2 className="mt-2 text-lg text-center font-gotham-bold text-gray">
        {t('unsuccessful_transaction')}
      </h2>
      <div className="flex justify-center mt-8">
        <Unsuccessful className="w-20 h-20" />
      </div>
    </div>
  );
};

export default UnsuccessfulTransactionDialog;
