import * as React from 'react';
import { Checkbox, CheckboxOverrides, LABEL_PLACEMENT } from 'baseui/checkbox';
import { FieldError } from 'react-hook-form';

import { color } from '../../../imports/utils';

const CustomCheckbox = ({
  name,
  onBlur,
  onChange,
  inputRef,
  checked,
  children,
  error,
  overrides,
  disabled,
}: {
  name?: string;
  onBlur?: any;
  onChange?: any;
  inputRef?: any;
  checked: boolean;
  children: React.ReactNode;
  error?: FieldError | undefined;
  overrides?: CheckboxOverrides;
  disabled?: boolean;
}) => (
  <Checkbox
    overrides={{
      Root: {
        style: () => ({
          display: 'flex',
          alignItems: 'center',
        }),
      },
      Checkmark: {
        style: () => ({
          width: '1.5em',
          height: '1.5em',
          borderWidth: '0.125rem',
          borderTopLeftRadius: '0.25rem',
          borderTopRightRadius: '0.25rem',
          borderBottomLeftRadius: '0.25rem',
          borderBottomRightRadius: '0.25rem',
          borderColor: `${color(!error ? 'primary' : 'error')} !important`,
          backgroundColor: checked ? color('primary') : "#fff",
        }),
      },
      ...overrides,
    }}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    inputRef={inputRef}
    checked={checked}
    disabled={disabled}
    labelPlacement={LABEL_PLACEMENT.right}
  >
    {children}
  </Checkbox>
);

CustomCheckbox.defaultProps = {
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  inputRef: undefined,
  error: undefined,
  overrides: undefined,
  disabled: false,
};

export default CustomCheckbox;
