import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { toast } from 'react-toastify';
import { CustomButton, CustomInput } from '../..';
import { Close } from '../../../assets/icons';
import { QrCodeScannerLabels } from '../../../imports/types';
import { Tab, Tabber, useTabber } from '../../molecules/Tabber';

const QrCodeScanner = ({
  handleClose,
  primaryAction,
  scannerOnly,
  labels,
}: {
  handleClose: () => void;
  primaryAction: any;
  scannerOnly: boolean;
  labels: QrCodeScannerLabels;
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { tab, goToTab } = useTabber(0, 2);

  const isScanningQrCode = tab === 0;

  const handleScan = async (data: string) => {
    if (data && !isLoading && isScanningQrCode) {
      setIsLoading(true);
      await primaryAction(data);
      setIsLoading(false);
    }
  };

  const handleScannerPermissionsError = () => {
    toast.error('qr_code_scanner_permissions_error');
  };

  return (
    <div className="fixed top-0 left-0 z-30 w-screen h-screen bg-black">
      <header className="fixed z-10 flex items-center justify-between w-full h-16 px-8">
        <button
          type="button"
          onClick={handleClose}
          className="p-2 duration-300 ease-in rounded-full bg-light-gray hover:bg-light-gray-variant"
        >
          <Close className="w-4 h-4 text-black fill-current" />
        </button>
      </header>
      <div className="fixed z-10 w-full -translate-x-1/2 left-1/2 top-20">
        <h2 className="px-8 text-2xl text-center text-white">
          {isScanningQrCode ? labels.scanCode.title : labels.enterCode?.title}
        </h2>
        <h3 className="px-8 text-center text-white">
          {isScanningQrCode
            ? labels.scanCode.subtitle
            : labels.enterCode?.subtitle}
        </h3>
      </div>
      <QrReader
        className="qr-code-scanner"
        delay={3000}
        onError={handleScannerPermissionsError}
        onScan={handleScan}
        showViewFinder={false}
      />
      {isScanningQrCode && (
        <div
          className="fixed -translate-x-1/2 -translate-y-1/2 w-72 h-72 top-1/2 left-1/2 rounded-2xl"
          style={{ boxShadow: '0 0 0 1000px rgba(0, 0, 0, 0.3)' }}
        />
      )}
      {!isScanningQrCode && !scannerOnly && (
        <>
          <div className="fixed top-0 left-0 w-full h-full bg-black opacity-30" />
          <div className="fixed w-[calc(100%-(2rem*2))] -translate-x-1/2 -translate-y-1/2 bg-light-gray top-1/2 left-1/2 rounded-2xl overflow-hidden p-8">
            <h4 className="text-gray font-gotham-bold">
              {labels.enterCode?.inputLabel}
            </h4>
            <div className="relative mt-4">
              <CustomInput
                value={inputValue}
                placeholder={labels.enterCode?.inputPlaceholder}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInputValue(e.target.value)
                }
              />
            </div>
            <div className="max-w-sm mx-auto mt-12">
              <CustomButton
                label={
                  labels.enterCode?.primaryButton.toUpperCase() || t('add')
                }
                primaryAction={async () => {
                  setIsLoading(true);
                  await primaryAction(inputValue);
                  setIsLoading(false);
                }}
                disabled={inputValue === '' || isLoading}
              />
            </div>
          </div>
        </>
      )}
      {!scannerOnly && (
        <div className="fixed bottom-0 flex items-center justify-center w-full h-24 px-8">
          <Tabber tab={tab} goToTab={goToTab}>
            <Tab>
              <h3 className="font-gotham-bold">{t('scan')}</h3>
            </Tab>
            <Tab>
              <h3 className="font-gotham-bold">{t('enter')}</h3>
            </Tab>
          </Tabber>
        </div>
      )}
    </div>
  );
};

export default QrCodeScanner;
