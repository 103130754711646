import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { Skeleton } from 'baseui/skeleton';

import { screen } from '../../../imports/utils';

interface StakingItem {
  hash: string;
  lockDate: string;
  vipLevel: number | undefined;
  lockedAmount: number;
  unlockDate: string;
}

type Props = {
  stakingItem: StakingItem;
};

const StakeItem: React.FC<Props> = ({ stakingItem }) => {
  const { t } = useTranslation();
  const isDesktop = useMedia(`(min-width: ${screen('md')})`);

  const isLoading = false;

  return isLoading ? (
    <div className="flex items-center justify-between mt-4">
      <Skeleton
        width="3rem"
        height="3rem"
        overrides={{
          Root: {
            style: {
              borderTopLeftRadius: '50%',
              borderTopRightRadius: '50%',
              borderBottomLeftRadius: '50%',
              borderBottomRightRadius: '50%',
              marginRight: '1rem',
              flexShrink: 0,
            },
          },
        }}
        animation
      />
      <Skeleton
        width="100%"
        height="5rem"
        overrides={{
          Root: {
            style: {
              borderTopLeftRadius: '1.5rem',
              borderTopRightRadius: '1.5rem',
              borderBottomLeftRadius: '1.5rem',
              borderBottomRightRadius: '1.5rem',
              boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
            },
          },
        }}
        animation
      />
    </div>
  ) : (
    <div className="flex items-center justify-between mt-4">
      <div className="w-full px-4 py-6 bg-white shadow-primary-xl rounded-3xl text-gray">
        <div className="flex items-center justify-between p-2">
          <p className="text-xl font-gotham-bold text-gray">
            {`${stakingItem.lockedAmount} ${isDesktop ? 'MIT' : ''}`}
          </p>
          <p className="px-4 py-2 text-base rounded-full lg:px2 lg:py-1 md:text-sm font-gotham-bold bg-orange">
            {isDesktop
              ? `${t('vip')} - ${t('vip_level', {
                  level: stakingItem.vipLevel,
                })}`
              : stakingItem.vipLevel}
          </p>
          <p className="text-xs text-right font-gotham-bold">
            {isDesktop && `${t('lock_tokens.lock_date')}:`}{' '}
            {stakingItem.lockDate}
          </p>
          <p className="text-xs text-right font-gotham-bold">
            {isDesktop && `${t('lock_tokens.unlock_date')}:`}{' '}
            {stakingItem.unlockDate}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StakeItem;
