import { lazy } from 'react';

const Home = lazy(() => import('./Home'));
const Wallet = lazy(() => import('./Wallet'));
const NotFound = lazy(() => import('./NotFound'));
const Register = lazy(() => import('./Register'));
const Login = lazy(() => import('./Login'));
const RecoveryPassword = lazy(() => import('./RecoveryPassword'));
const Transaction = lazy(() => import('./Transaction'));
const Discounts = lazy(() => import('./Discounts'));
const Discount = lazy(() => import('./Discount'));
const Buy = lazy(() => import('./Buy'));
const PersonalData = lazy(() => import('./PersonalData'));
const AccountSecurity = lazy(() => import('./AccountSecurity'));
const Send = lazy(() => import('./Send'));
const Pay = lazy(() => import('./Pay'));
const PaymentRequest = lazy(() => import('./PaymentRequest'));
const ManageDiscounts = lazy(() => import('./ManageDiscounts'));

export {
  Home,
  Wallet,
  NotFound,
  Register,
  Login,
  RecoveryPassword,
  Transaction,
  Discounts,
  Discount,
  Buy,
  PersonalData,
  AccountSecurity,
  Send,
  Pay,
  PaymentRequest,
  ManageDiscounts,
};
