import { PayloadAction } from '@reduxjs/toolkit';

import { PublicProfiles, PublicData } from './types';

import { publicProfilesInitialState } from './publicProfiles.slice';

export const publicProfilesReducer = {
  setLoader: {
    reducer: (
      state: PublicProfiles,
      action: PayloadAction<{
        value: boolean;
      }>
    ): void => {
      state.loader = action.payload.value;
    },
    prepare: (value: boolean) => ({
      payload: {
        value,
      },
    }),
  },
  addPublicProfile: {
    reducer: (
      state: PublicProfiles,
      action: PayloadAction<{
        profileData: PublicData;
      }>
    ): void => {
      state.list.push(action.payload.profileData);
    },
    prepare: (profileData: PublicData) => ({
      payload: {
        profileData,
      },
    }),
  },
  updatePublicProfile: {
    reducer: (
      state: PublicProfiles,
      action: PayloadAction<{
        profileData: PublicData;
      }>
    ): void => {
      const { profileData } = action.payload;
      state.list = state.list.map((user) =>
        user.id === profileData.id
          ? ({
              category: user.category,
              status: user.status,
              mitBalance: user.mitBalance,
              ...profileData,
            } as PublicData)
          : user
      );
    },
    prepare: (profileData: PublicData) => ({
      payload: {
        profileData,
      },
    }),
  },
  reset: () => publicProfilesInitialState,
};
