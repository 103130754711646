import React, { Suspense, useEffect, useRef, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';

import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMedia, usePrevious } from 'react-use';
import SimpleBar from 'simplebar-react';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import 'simplebar-react/dist/simplebar.min.css';

import { auth } from '../firebase';

import { QuestionCircle } from '../assets/icons';
import {
  DelayedRender,
  Dialog,
  Footer,
  Head,
  Header,
  Loader,
  QrCodeScanner,
} from '../components';
import FixedBanner from '../components/molecules/FixedBanner';
import { useApp } from '../context/app.context';
import { useCheckSession, useAppSelector, useAppDispatch } from '../hooks';
import { routes } from '../imports/config';
import { NotFound } from '../pages';
import { UserState } from '../store/user/types';

import { updateEmail, updateAuthToken, logout } from '../store/user/user.slice';

import { application } from '../imports/constants';
import useScreenOrientation from '../hooks/utils/useScreenOrientation';
import { screen } from '../imports/utils';

const Layout = ({
  pageTitle,
  component,
  isHeaderHidden,
  isSidebarHidden,
  isFooterHidden,
}: {
  pageTitle: string | undefined;
  component: React.ReactNode;
  isHeaderHidden: boolean;
  isSidebarHidden: boolean;
  isFooterHidden: boolean;
}) => {
  const {
    state: {
      isSidebarOpen,
      dialog: {
        isOpen: isDialogOpen,
        content: dialogContent,
        fromBottom,
        isBgOverlayInteractive,
      },
      qrCodeScanner: {
        isOpen: isQrCodeScannerOpen,
        primaryAction: qrCodeScannerPrimaryAction,
        scannerOnly,
        labels: qrCodeScannerLabels,
      },
    },
    dispatch,
  } = useApp();

  const {
    role,
    privateProfile: {
      tierOne,
      tierTwo,
      tier,
      pendingVerificationRequest,
      active,
    },
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const scrollContainerRef: React.RefObject<any> = useRef(null);
  const [showCompleteRegistration, setShowCompleteRegistration] = useState(
    tierOne === undefined
  );

  const [showKyc, setShowKyc] = useState(tierTwo === undefined);
  const [showRegistrationReviewBanner, setShowRegistrationReviewBanner] =
    useState(true);

  const { t } = useTranslation();

  const location = useLocation();
  const prevLocation = usePrevious(location);

  const currentPath = location.pathname.split('/')[2];
  const Component: any = component;

  const closeSidebar = () => {
    dispatch({ type: 'CLOSE_SIDEBAR' });
  };

  const closeDialog = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
  };

  const closeQrCodeScanner = () => {
    dispatch({ type: 'CLOSE_QR_SCANNER' });
  };

  useEffect(() => {
    if (location.key !== prevLocation?.key) {
      if (isSidebarOpen) {
        closeSidebar();
      }

      if (isDialogOpen) {
        closeDialog();
      }

      if (isQrCodeScannerOpen) {
        closeQrCodeScanner();
      }

      scrollContainerRef.current.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Head pageTitle={pageTitle} />
      <Header
        isHeaderHidden={isHeaderHidden}
        isSidebarHidden={isSidebarHidden}
      />
      <SimpleBar
        className={`absolute w-full h-screen min-h-screen ${
          !isHeaderHidden ? 'pt-24 md:pt-24 sm:pt-14' : 'pt-0'
        } `}
        scrollableNodeProps={{
          ref: scrollContainerRef,
        }}
        style={{
          height: window.innerHeight,
          minHeight: '-webkit-fill-available',
        }}
      >
        <div style={{ minHeight: `${window.innerHeight - 160}px` }}>
          <Component />
        </div>
        <Footer isFooterHidden={isFooterHidden} />
      </SimpleBar>
      {isDialogOpen && (
        <Dialog
          handleClose={closeDialog}
          fromBottom={fromBottom}
          isBgOverlayInteractive={isBgOverlayInteractive}
        >
          {dialogContent}
        </Dialog>
      )}
      {isQrCodeScannerOpen && (
        <QrCodeScanner
          handleClose={closeQrCodeScanner}
          primaryAction={qrCodeScannerPrimaryAction}
          scannerOnly={scannerOnly}
          labels={qrCodeScannerLabels}
        />
      )}

      {(pendingVerificationRequest || !active) &&
        ['wallet', undefined].includes(currentPath) &&
        role !== 'guest' && (
          <FixedBanner
            show={showRegistrationReviewBanner}
            handleClose={() => setShowRegistrationReviewBanner(false)}
            position="top"
            backgroundColor={!active ? 'error' : 'primary-lighter'}
            hideClose={active}
          >
            <div
              className={`text-center justify-center flex mt-2 ${
                !active ? 'text-white' : 'text-[#58585A]'
              } font-gotham-bold`}
            >
              {!active && t('inactive_user')}

              {tier === 0 && t('wait_kyc_check_banner_tier_one')}

              {tier === 1 && t('wait_kyc_check_banner_tier_two')}
            </div>
          </FixedBanner>
        )}

      {!tierOne &&
        role === 'consumer' &&
        ['wallet', undefined].includes(currentPath) &&
        active && (
          <FixedBanner
            show={tier === 0 ? showCompleteRegistration : showKyc}
            handleClose={
              tier === 0
                ? () => setShowCompleteRegistration(false)
                : () => setShowKyc(false)
            }
            buttonLabel={tier === 0 ? t('kyc_tier_one') : t('kyc_rules')}
            navigatePath={
              tier === 0 ? t('paths.kyc_tier_one') : t('paths.kyc_tier_two')
            }
          >
            {tier === 0 ? (
              <>
                <div className="text-center justify-center flex mt-2 font-gotham-bold text-[#58585A]">
                  <p className="mr-2">{t('subscription_almost_finish')}</p>
                  <div>
                    <StatefulPopover
                      placement="topRight"
                      content={
                        <div className="p-2 text-center">
                          <strong className="text-center mt-8 font-gotham-bold text-sm text-[#58585A]">
                            {t('subscription_almost_finish_text')}
                          </strong>
                          <br />
                          <strong className="text-center font-gotham-bold text-sm text-[#58585A]">
                            {t('mef_2022')}
                          </strong>
                        </div>
                      }
                      accessibilityType="tooltip"
                      triggerType={TRIGGER_TYPE.hover}
                    >
                      <QuestionCircle />
                    </StatefulPopover>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex mt-2 font-gotham-bold text-[#58585A] justify-center">
                  <p className="mr-2">{t('subscription_completed_title')}</p>
                  <div>
                    <StatefulPopover
                      placement="topRight"
                      content={
                        <div className="p-2 text-center">
                          <strong className="text-center mt-8 font-gotham-bold text-sm text-[#58585A]">
                            {t('remove_limit')}
                          </strong>
                          <br />
                          <strong className="text-center font-gotham-bold text-sm text-[#58585A]">
                            {t('complete_in_second_moment')}
                          </strong>
                        </div>
                      }
                      accessibilityType="tooltip"
                      triggerType={TRIGGER_TYPE.hover}
                    >
                      <QuestionCircle />
                    </StatefulPopover>
                  </div>
                </div>
              </>
            )}
          </FixedBanner>
        )}
    </>
  );
};

const App = () => {
  const { t, i18n } = useTranslation();
  const { role } = useAppSelector(({ user }: { user: UserState }) => user);
  const currentPath = window.location.pathname;
  const dispatch = useAppDispatch();
  const isDesktop = useMedia(`(min-width: ${screen('md')})`);
  const orientation = useScreenOrientation();

  const prevWidthRef = useRef(window.innerWidth);

  const { logOutExpired } = useCheckSession();

  useEffect(() => {
    if (!isDesktop) {
      if (prevWidthRef.current !== window.innerWidth) {
        window.location.reload();
        prevWidthRef.current = window.innerWidth;
      }
    }
  }, [orientation]);

  useEffect(() => {
    const currentPathLng = currentPath.split('/')[1];
    i18n.changeLanguage(currentPathLng);
  }, [currentPath]);

  useEffect(() => {
    logOutExpired();

    const unsubAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { claims } = await user.getIdTokenResult();

        if (!claims.role || claims.role === application) {
          const authToken = await user.getIdToken();
          dispatch(updateAuthToken(authToken, user.uid));
          if (user.email) {
            dispatch(updateEmail(user.email));
          }
        } else {
          signOut(auth).then(() => {
            toast.error(t('errors.invalid_role', { role: application }));
            dispatch(logout());
          });
        }
      }
    });

    return () => {
      unsubAuth();
    };
  }, []);

  return (
    <div
      id="app"
      className="w-full h-screen text-base font-gotham bg-light-gray"
    >
      <Suspense
        fallback={
          <DelayedRender delay={500}>
            <div className="absolute flex items-center justify-center w-full h-full wrapper-loader">
              <Loader />
            </div>
          </DelayedRender>
        }
      >
        <BrowserRouter>
          <Routes>
            {routes.map((route) => {
              const routeOfRole = route.onRoles?.find(({ roles }) =>
                roles.includes(role)
              );

              const path =
                (routeOfRole?.customPath &&
                  `${t(routeOfRole?.customPath[0])}/${
                    routeOfRole?.customPath[1]
                  }`) ||
                (route.customPath &&
                  `${t(route.customPath[0])}/${route.customPath[1]}`) ||
                (route.path && t(route.path));
              const pageTitle =
                (routeOfRole?.name && t(routeOfRole?.name)) ||
                (route.name && t(route.name));
              const component = routeOfRole?.component || route.component;
              const isHeaderHidden = routeOfRole
                ? !!routeOfRole?.isHeaderHidden
                : !!route.isHeaderHidden;
              const isSidebarHidden = routeOfRole
                ? !!routeOfRole?.isSidebarHidden
                : !!route.isSidebarHidden;
              const isFooterHidden = routeOfRole
                ? !!routeOfRole?.isFooterHidden
                : !!route.isFooterHidden;

              return (
                path && (
                  <Route
                    key={path}
                    path={t(path)}
                    element={
                      <Layout
                        pageTitle={pageTitle}
                        component={component}
                        isHeaderHidden={isHeaderHidden}
                        isSidebarHidden={isSidebarHidden}
                        isFooterHidden={isFooterHidden}
                      />
                    }
                  />
                )
              );
            })}
            <Route
              path="*"
              element={
                <>
                  <Head pageTitle={t('page_not_found')} />
                  <NotFound />
                </>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;
