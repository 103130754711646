import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Close } from '../../../assets/icons';

import { useYupValidationResolver } from '../../../hooks';
import CustomButton from '../../atoms/Button';
import CustomInput from '../../atoms/Input';
import CustomSelect from '../../atoms/Select';

interface FormTypes {
  vip_level_to_get: string;
  blocked_mit: number;
}

type VipLevel = {
  level: number;
  name: string;
  threshold: number;
};

type Props = {
  onSubmitFunc: any;
  lockedAmount: number;
  vipLevels: VipLevel[];
  maxed: boolean;
  closeForm: () => void;
};

const StakingForm: React.FC<Props> = ({
  onSubmitFunc,
  lockedAmount,
  vipLevels,
  maxed,
  closeForm,
}) => {
  const { t } = useTranslation();

  const personalDataForm = {
    initialValues: {
      vip_level_to_get: lockedAmount.toString(),
      blocked_mit: lockedAmount,
    },
    validationSchema: Yup.object({
      vip_level_to_get: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      blocked_mit: Yup.string().required(
        t('validation.error_field_is_required')
      ),
    }),
  };

  const { initialValues, validationSchema } = personalDataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = formState;

  const watchedFields = watch();

  const onSubmit = (values: any) => {
    const amountToLock = parseInt(values.vip_level_to_get, 10) - lockedAmount;
    onSubmitFunc(amountToLock.toString());
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full flex justify-end">
          <button
            type="button"
            onClick={closeForm}
            className="p-2 duration-300 ease-in rounded-full hover:bg-light-gray"
          >
            <Close className="w-5 h-5 fill-current text-gray" />
          </button>
        </div>
        <div className="w-full">
          <label
            htmlFor="vip_level_to_get"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('vip_level_to_get')}
          </label>
          <Controller
            control={control}
            name="vip_level_to_get"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                {!maxed ? (
                  <CustomSelect
                    value={[{ label: t('select_placeholder'), id: value }]}
                    onBlur={onBlur}
                    onChange={onChange}
                    controlRef={ref}
                    options={vipLevels.map((vipLevel) => ({
                      label: `${
                        vipLevel.name
                      } - ${vipLevel.threshold.toString()} tokens`,
                      id: vipLevel.threshold.toString(),
                    }))}
                    error={errors.vip_level_to_get}
                  />
                ) : (
                  'Hai raggiunto il livello massimo'
                )}
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.vip_level_to_get && (
              <p className="text-xs text-error">
                {errors.vip_level_to_get.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="blocked_mit"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('blocked_mit')}
          </label>
          <Controller
            control={control}
            name="blocked_mit"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.blocked_mit}
                  disabled
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.blocked_mit && (
              <p className="text-xs text-error">{errors.blocked_mit.message}</p>
            )}
          </div>
        </div>
        <div className="w-full">
          <div className="text-center text-[45px] mt-4 mb-10 font-gotham-bold text-gray">
            {parseInt(watchedFields.vip_level_to_get, 10) - lockedAmount},00{' '}
            <span className="text-[35px]">MIT</span>
          </div>
        </div>
        <div className="max-w-sm mx-auto mt-8">
          <CustomButton label={t('get_vip_level')} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default StakingForm;
