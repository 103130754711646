import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import {
  useValidation,
  useYupValidationResolver,
} from '../../../../../../hooks';
import CloseButton from '../../../../../atoms/CloseButton';
import CustomButton from '../../../../../atoms/Button';
import CustomInput from '../../../../../atoms/Input';
import CustomCheckbox from '../../../../../atoms/Checkbox';
import CustomDatePicker from '../../../../../atoms/DatePicker';
import CustomSelect from '../../../../../atoms/Select';
import { maxUploadedFileSize } from '../../../../../../imports/constants';
import { QuestionCircle } from '../../../../../../assets/icons';

interface FormTypes {
  type: string;
  number: string;
  issuingDate: Date | null;
  issuingEntity: string;
  expirationDate: Date | null;
  citizenship: string;
  fiscalCode: string;
  economicallyExposedPerson: boolean;
  documentPhotoFront: string;
  documentPhotoBack: string;
  documentPhotoUser: string;
}

type Props = {
  prevStep?: () => void;
  nextStep: () => void;
  formInitialValues?: FormTypes;
  updateFormData: any;
  disabledForm?: boolean;
};

const StepSecond: React.FC<Props> = ({
  prevStep,
  nextStep,
  formInitialValues,
  updateFormData,
  disabledForm,
}) => {
  const { t } = useTranslation();

  const { validateFiscalCode, validatePastDate, validateFutureDate } =
    useValidation();

  const [documentBackFile, setDocumentBackFile] = useState<string | undefined>(
    undefined
  );
  const [documentFrontFile, setDocumentFrontFile] = useState<
    string | undefined
  >(undefined);
  const [profileFile, setProfileFile] = useState<string | undefined>(undefined);

  const personalDataForm = {
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      type: Yup.string().required(t('validation.error_field_is_required')),
      number: Yup.string().required(t('validation.error_field_is_required')),
      issuingDate: validatePastDate().required('error_field_is_required'),
      issuingEntity: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      expirationDate: validateFutureDate().required('error_field_is_required'),
      citizenship: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      fiscalCode: validateFiscalCode()
        .notRequired()
        .when('citizenship', (citizenship, schema) => {
          if (citizenship === 'Italian' || citizenship === 'Italiana') {
            return schema.required(t('validation.error_field_is_required'));
          }
          delete errors.fiscalCode;
          return schema;
        }),
      documentPhotoFront: Yup.mixed().test(
        'FILE_SIZE',
        t('file_upload_error'),
        (value) => value && value.size <= maxUploadedFileSize
      ),
      documentPhotoBack: Yup.mixed().test(
        'FILE_SIZE',
        t('file_upload_error'),
        (value) => value && value.size <= maxUploadedFileSize
      ),
      documentPhotoUser: Yup.mixed().test(
        'FILE_SIZE',
        t('file_upload_error'),
        (value) => value && value.size <= maxUploadedFileSize
      ),
    }),
  };

  const { initialValues, validationSchema } = personalDataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = formState;

  const watchedFields = watch();

  const inputFileRefFront = useRef<HTMLInputElement>(null);
  const inputFileRefBack = useRef<HTMLInputElement>(null);
  const inputFileRefProfile = useRef<HTMLInputElement>(null);

  const focusInputFileFront = () => {
    if (inputFileRefFront.current) {
      inputFileRefFront.current.click();
    }
  };

  const focusInputFileBack = () => {
    if (inputFileRefBack.current) {
      inputFileRefBack.current.click();
    }
  };

  const focusInputFileProfile = () => {
    if (inputFileRefProfile.current) {
      inputFileRefProfile.current.click();
    }
  };

  const goBack = () => {
    prevStep?.();
  };

  const onSubmit = (data: any) => {
    updateFormData(data);
    nextStep();
  };

  const isError = !isEmpty(errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:justify-between md:flex-wrap">
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="type"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_type')}
          </label>
          <Controller
            control={control}
            name="type"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  value={[{ label: t('select_placeholder'), id: value }]}
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  options={[
                    { label: t('driver_license'), id: t('driver_license') },
                    { label: t('identity_card'), id: t('identity_card') },
                    { label: t('passport'), id: t('passport') },
                  ]}
                  error={errors.type}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.type && (
              <p className="text-xs text-error">{errors.type.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="number"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_number')}
          </label>
          <Controller
            control={control}
            name="number"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.number}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.number && (
              <p className="text-xs text-error">{errors.number.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="issuingDate"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('issuingDate')}
          </label>
          <Controller
            name="issuingDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="relative mt-2">
                <CustomDatePicker
                  onChange={onChange}
                  value={value ? [new Date(value)] : null}
                  error={errors.issuingDate}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.issuingDate && (
              <p className="text-xs text-error">{errors.issuingDate.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="issuingEntity"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('issuing_entity')}
          </label>
          <Controller
            control={control}
            name="issuingEntity"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.issuingEntity}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.issuingEntity && (
              <p className="text-xs text-error">
                {errors.issuingEntity.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="expirationDate"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_expiry_date')}
          </label>
          <Controller
            name="expirationDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="relative mt-2">
                <CustomDatePicker
                  onChange={onChange}
                  value={value ? [new Date(value)] : null}
                  error={errors.expirationDate}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.expirationDate && (
              <p className="text-xs text-error">
                {errors.expirationDate.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="citizenship"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('citizenship')}
          </label>
          <Controller
            control={control}
            name="citizenship"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  value={[{ label: t('select_placeholder'), id: value }]}
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  options={[
                    {
                      label: t('citizenship_italian'),
                      id: t('citizenship_italian'),
                    },
                    {
                      label: t('citizenship_other'),
                      id: t('citizenship_other'),
                    },
                  ]}
                  error={errors.citizenship}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.citizenship && (
              <p className="text-xs text-error">{errors.citizenship.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="fiscalCode"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('fiscalCode')}
          </label>
          <Controller
            control={control}
            name="fiscalCode"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.fiscalCode}
                  disabled={
                    disabledForm ||
                    watchedFields.citizenship !== t('citizenship_italian')
                  }
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.fiscalCode && (
              <p className="text-xs text-error">{errors.fiscalCode.message}</p>
            )}
          </div>
        </div>
        <div className="w-full">
          <Controller
            control={control}
            name="economicallyExposedPerson"
            render={({ field: { onBlur, onChange, value } }) => (
              <div className="relative mt-2">
                <CustomCheckbox
                  onBlur={onBlur}
                  onChange={onChange}
                  checked={value}
                  error={errors.economicallyExposedPerson}
                  disabled={disabledForm}
                  overrides={{
                    Checkmark: {
                      style: () => ({
                        width: '2.5em',
                        height: '2.5em',
                        borderWidth: '0.25rem',
                        borderTopLeftRadius: '0.5rem',
                        borderTopRightRadius: '0.5rem',
                        borderBottomLeftRadius: '0.5rem',
                        borderBottomRightRadius: '0.5rem',
                        backgroundColor: '#D9D9D9',
                        borderColor: `transparent`,
                      }),
                    },
                  }}
                >
                  {' '}
                  <label
                    htmlFor="economicallyExposedPerson"
                    className="text-sm cursor-pointer md:text-base text-gray font-gotham-bold"
                  >
                    <StatefulPopover
                      placement="topRight"
                      content={
                        <div className="p-2 text-center max-w-sm">
                          <strong className="text-center mt-8 font-gotham-bold text-sm text-[#58585A]">
                            {t('exposed_politically_person_description')}
                          </strong>
                        </div>
                      }
                      accessibilityType="tooltip"
                      triggerType={TRIGGER_TYPE.hover}
                    >
                      <div className="flex justify-center items-center">
                        <p className="pr-2">{t('economicallyExposedPerson')}</p>
                        <QuestionCircle />
                      </div>
                    </StatefulPopover>
                  </label>
                </CustomCheckbox>
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.economicallyExposedPerson && (
              <p className="text-xs text-error">
                {errors.economicallyExposedPerson.message}
              </p>
            )}
          </div>
        </div>
        {!disabledForm && (
          <div className="w-full">
            <label
              htmlFor="documentPhotoFront"
              className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
            >
              {t('document_photo_front')}
            </label>
            {!documentFrontFile ? (
              <Controller
                name="documentPhotoFront"
                control={control}
                render={({ field: { name, onChange } }) => (
                  <div className="flex">
                    <input
                      name={name}
                      type="file"
                      accept="image/*"
                      hidden
                      ref={inputFileRefFront}
                      onChange={async () => {
                        if (inputFileRefFront.current?.files) {
                          const file = inputFileRefFront.current.files[0];

                          setDocumentFrontFile(file.name);
                          onChange(file);
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="text-[#6B6B6B] font-gotham-bold justify-start pl-2"
                      onClick={focusInputFileFront}
                    >
                      {t('click_to_upload')}
                    </button>
                  </div>
                )}
              />
            ) : (
              <div className="grid grid-cols-2 pl-2">
                <p className="flex items-center font-gotham-bold text-[#6B6B6B]">
                  {documentFrontFile}
                </p>
                <CloseButton
                  handleClose={() => setDocumentFrontFile(undefined)}
                />
              </div>
            )}
            <div className="min-h-[2rem] mt-2">
              {errors.documentPhotoFront && (
                <p className="text-xs text-error">
                  {errors.documentPhotoFront.message}
                </p>
              )}
            </div>
          </div>
        )}
        {!disabledForm && (
          <div className="w-full">
            <label
              htmlFor="documentPhotoBack"
              className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
            >
              {t('document_photo_back')}
            </label>
            {!documentBackFile ? (
              <Controller
                name="documentPhotoBack"
                control={control}
                render={({ field: { name, onChange } }) => (
                  <div className="flex">
                    <input
                      name={name}
                      type="file"
                      accept="image/*"
                      hidden
                      ref={inputFileRefBack}
                      onChange={async () => {
                        if (inputFileRefBack.current?.files) {
                          const file = inputFileRefBack.current.files[0];

                          setDocumentBackFile(file.name);
                          onChange(file);
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="text-[#6B6B6B] font-gotham-bold justify-start pl-2"
                      onClick={focusInputFileBack}
                    >
                      {t('click_to_upload')}
                    </button>
                  </div>
                )}
              />
            ) : (
              <div className="grid grid-cols-2 pl-2">
                <p className="flex items-center font-gotham-bold text-[#6B6B6B]">
                  {documentBackFile}
                </p>
                <CloseButton
                  handleClose={() => setDocumentBackFile(undefined)}
                />
              </div>
            )}
            <div className="min-h-[2rem] mt-2">
              {errors.documentPhotoBack && (
                <p className="text-xs text-error">
                  {errors.documentPhotoBack.message}
                </p>
              )}
            </div>
          </div>
        )}
        {!disabledForm && (
          <div className="w-full">
            <label
              htmlFor="documentPhotoUser"
              className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
            >
              {t('kyc_profile_proof')}
            </label>
            {!profileFile ? (
              <Controller
                name="documentPhotoUser"
                control={control}
                render={({ field: { name, onChange } }) => (
                  <div className="flex">
                    <input
                      name={name}
                      type="file"
                      accept="image/*"
                      hidden
                      ref={inputFileRefProfile}
                      onChange={async () => {
                        if (inputFileRefProfile.current?.files) {
                          const file = inputFileRefProfile.current.files[0];

                          setProfileFile(file.name);
                          onChange(file);
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="text-[#6B6B6B] font-gotham-bold justify-start pl-2"
                      onClick={focusInputFileProfile}
                    >
                      {t('click_to_upload')}
                    </button>
                  </div>
                )}
              />
            ) : (
              <div className="grid grid-cols-2 pl-2">
                <p className="flex items-center font-gotham-bold text-[#6B6B6B]">
                  {profileFile}
                </p>
                <CloseButton handleClose={() => setProfileFile(undefined)} />
              </div>
            )}
            <div className="min-h-[2rem] mt-2">
              {errors.documentPhotoUser && (
                <p className="text-xs text-error">
                  {errors.documentPhotoUser.message}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {!disabledForm && (
        <div className="flex gap-x-6">
          <CustomButton
            primaryAction={goBack}
            label={t('back_to_prev_step')}
            type="button"
          />
          <CustomButton
            label={t('next_step')}
            type="submit"
            disabled={isError}
          />
        </div>
      )}
    </form>
  );
};

StepSecond.defaultProps = {
  disabledForm: false,
  formInitialValues: {
    type: '',
    number: '',
    issuingDate: null,
    issuingEntity: '',
    expirationDate: null,
    citizenship: '',
    fiscalCode: '',
    economicallyExposedPerson: false,
    documentPhotoFront: '',
    documentPhotoBack: '',
    documentPhotoUser: '',
  },
  prevStep: () => {},
};

export default StepSecond;
