const loader = (primaryColor: number[]) => ({
  v: '5.7.4',
  fr: 60,
  ip: 0,
  op: 79,
  w: 800,
  h: 800,
  nm: 'loader-3dots',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: '#dot03',
      ln: 'dot03',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.6, y: 1 },
              o: { x: 0.583, y: 0 },
              t: 8,
              s: [501.814, 439.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.599, y: 1 },
              o: { x: 0.4, y: 0 },
              t: 25,
              s: [501.814, 379.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.58, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 44,
              s: [501.814, 479.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.58, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 57,
              s: [501.814, 429.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.5, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 69,
              s: [501.814, 445.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 79, s: [501.814, 439.774, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -16.569],
                    [16.569, 0],
                    [0, 16.569],
                    [-16.569, 0],
                  ],
                  o: [
                    [0, 16.569],
                    [-16.569, 0],
                    [0, -16.569],
                    [16.569, 0],
                  ],
                  v: [
                    [30, 0],
                    [0, 30],
                    [-30, 0],
                    [0, -30],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: primaryColor,
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 79,
      st: 6,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: '#dot02',
      ln: 'dot02',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.513, y: 1 },
              o: { x: 0.583, y: 0 },
              t: 4,
              s: [401.814, 439.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.575, y: 1 },
              o: { x: 0.474, y: 0 },
              t: 20,
              s: [401.814, 379.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.58, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 39,
              s: [401.814, 479.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.58, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 53,
              s: [401.814, 429.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.5, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 65,
              s: [401.814, 445.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 75, s: [401.814, 439.774, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -16.569],
                    [16.569, 0],
                    [0, 16.569],
                    [-16.569, 0],
                  ],
                  o: [
                    [0, 16.569],
                    [-16.569, 0],
                    [0, -16.569],
                    [16.569, 0],
                  ],
                  v: [
                    [30, 0],
                    [0, 30],
                    [-30, 0],
                    [0, -30],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: primaryColor,
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 79,
      st: 3,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: '#dot01',
      ln: 'dot01',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.4, y: 1 },
              o: { x: 0.583, y: 0 },
              t: 0,
              s: [301.814, 439.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.599, y: 1 },
              o: { x: 0.7, y: 0 },
              t: 15,
              s: [301.814, 379.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.58, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 35,
              s: [301.814, 479.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.58, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 49,
              s: [301.814, 429.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.5, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 61,
              s: [301.814, 445.774, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 71, s: [301.814, 439.774, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -16.569],
                    [16.569, 0],
                    [0, 16.569],
                    [-16.569, 0],
                  ],
                  o: [
                    [0, 16.569],
                    [-16.569, 0],
                    [0, -16.569],
                    [16.569, 0],
                  ],
                  v: [
                    [30, 0],
                    [0, 30],
                    [-30, 0],
                    [0, -30],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: primaryColor,
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 79,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
});

export default loader;
