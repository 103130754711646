import * as React from 'react';

const HomeMobileOverlay = ({
  primaryColor,
  isExpanded,
}: {
  primaryColor: string;
  isExpanded?: boolean;
}) => (
  <svg viewBox="0 0 378 384" version="1.1" xmlns="http://www.w3.org/2000/svg">
    {!isExpanded ? (
      <>
        <path
          d="M377.998 72.1571C377.998 150.22 380.215 214.686 232.029 237.403C47.8735 265.635 -41.1885 382 1.56032 382C17.4523 382 76.3827 382 180.866 382C216.759 382 282.47 382 377.998 382C377.998 16.6506 377.998 -86.6304 377.998 72.1571Z"
          fill="#EDE823"
        />
        <path
          d="M378 384H0V352.094C143.745 293.792 237.096 280.684 280.053 312.772C323.01 344.86 355.659 334.936 378 283V384Z"
          fill={primaryColor}
        />
      </>
    ) : (
      <>
        <path
          d="M377.998 -21.8429C377.998 56.2196 380.238 120.686 230.52 143.403C44.4613 171.635 -45.5212 288 -2.33054 288C13.7257 288 73.2652 288 178.829 288C215.093 288 281.482 288 377.998 288C377.998 -77.3494 377.998 -180.63 377.998 -21.8429Z"
          fill="#EDE823"
        />
        <path
          d="M0 286.13H378V185.263C355.659 237.131 323.01 247.042 280.053 214.996C237.096 182.951 143.745 196.041 0 254.266V286.13ZM378 286.13H0V384H378V286.13Z"
          fill={primaryColor}
        />
      </>
    )}
  </svg>
);

HomeMobileOverlay.defaultProps = {
  isExpanded: false,
};

export default HomeMobileOverlay;
