const loader = (primaryColor: number[], secondaryColor: number[]) => ({
  v: '4.6.0',
  fr: 29.9700012207031,
  ip: 0,
  op: 49.0000019958109,
  w: 200,
  h: 200,
  nm: 'loading_ring_medium',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'green ring 1',
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [360],
            },
            { t: 49.0000019958109 },
          ],
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [200, 200, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [54, 54] },
              p: { a: 0, k: [0, 0] },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: primaryColor,
              },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 6 },
              lc: 2,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          mn: 'ADBE Vector Group',
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.644], y: [0] },
                n: ['0p667_1_0p644_0'],
                t: 10,
                s: [0],
                e: [100],
              },
              { t: 50.0000020365418 },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p667_1_0p333_0'],
                t: -1,
                s: [0],
                e: [100],
              },
              { t: 37.0000015070409 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
        },
      ],
      ip: 0,
      op: 50.0000020365418,
      st: -1.00000004073083,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'flamingo ring 3',
      parent: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.785], y: [1] },
              o: { x: [1], y: [0] },
              n: ['0p785_1_1_0'],
              t: 17,
              s: [14.2],
              e: [360],
            },
            { t: 50.0000020365418 },
          ],
        },
        p: { a: 0, k: [0, 0, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [54, 54] },
              p: { a: 0, k: [0, 0] },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: secondaryColor,
              },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 6 },
              lc: 2,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          mn: 'ADBE Vector Group',
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                n: ['0p833_0p833_0p167_0p167'],
                t: 42,
                s: [0],
                e: [1],
              },
              { t: 44.0000017921567 },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p833_1_0p333_0'],
                t: 20,
                s: [0],
                e: [1],
              },
              { t: 44.0000017921567 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
        },
      ],
      ip: 17.0000006924242,
      op: 44.0000017921567,
      st: -1.00000004073083,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'flamingo ring 2',
      parent: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.612], y: [1] },
              o: { x: [1], y: [0] },
              n: ['0p612_1_1_0'],
              t: 17,
              s: [14.2],
              e: [360],
            },
            { t: 50.0000020365418 },
          ],
        },
        p: { a: 0, k: [0, 0, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [54, 54] },
              p: { a: 0, k: [0, 0] },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: secondaryColor,
              },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 6 },
              lc: 2,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          mn: 'ADBE Vector Group',
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                n: ['0p833_0p833_0p167_0p167'],
                t: 42,
                s: [0],
                e: [13.7],
              },
              { t: 44.0000017921567 },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [1] },
                o: { x: [0.333], y: [0] },
                n: ['0p833_1_0p333_0'],
                t: 20,
                s: [0],
                e: [13.7],
              },
              { t: 44.0000017921567 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
        },
      ],
      ip: 17.0000006924242,
      op: 44.0000017921567,
      st: -1.00000004073083,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'flaming ring 1',
      parent: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [0, 0, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [54, 54] },
              p: { a: 0, k: [0, 0] },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: secondaryColor,
              },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 6 },
              lc: 2,
              lj: 1,
              ml: 4,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          mn: 'ADBE Vector Group',
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [1], y: [0] },
                n: ['0p667_1_1_0'],
                t: 8,
                s: [0],
                e: [100],
              },
              { t: 48.0000019550801 },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [1], y: [0] },
                n: ['0p667_1_1_0'],
                t: -1,
                s: [0],
                e: [100],
              },
              { t: 37.0000015070409 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
        },
      ],
      ip: 15.0000006109625,
      op: 44.0000017921567,
      st: -1.00000004073083,
      bm: 0,
      sr: 1,
    },
  ],
});

export default loader;
