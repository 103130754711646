import { FunctionComponent, SVGProps } from 'react';
import {
  CompanyContact,
  DiscountIcon,
  Help,
  Profile,
  QrCode,
  QrCodeConsumer,
  QrCodeMerchant,
  RequestPayment,
  Security,
  Send as SendIcon,
  Wallet as WalletIcon,
} from '../assets/icons';
import {
  AccountSecurity,
  Buy,
  Discount,
  Discounts,
  Home,
  Login,
  ManageDiscounts,
  NotFound,
  Pay,
  PaymentRequest,
  PersonalData,
  RecoveryPassword,
  Register,
  Send,
  Transaction,
  Wallet,
} from '../pages';
import KycTierOne from '../pages/KycTierOne';
import KycTierTwo from '../pages/KycTierTwo';
import ShowQrCode from '../pages/ShowQrCode';
import Staking from '../pages/Staking';
import { application, roles } from './constants';
import { Route } from './types';

const routes: Route[] = [
  {
    path: 'paths.home',
    onRoles: [
      {
        name: 'home',
        roles: [roles.GUEST],
        component: Home,
        isHeaderHidden: true,
        isFooterHidden: true,
      },
      {
        name: 'wallet',
        roles: [roles.CONSUMER, roles.MERCHANT],
        component: Wallet,
      },
    ],
  },
  {
    path: 'paths.wallet',
    name: 'wallet',
    component: Wallet,
  },
  {
    path: 'paths.register',
    name: 'register',
    component: Register,
    isSidebarHidden: true,
  },
  {
    path: 'paths.login',
    name: 'login',
    component: Login,
    isSidebarHidden: true,
  },
  {
    path: 'paths.recovery_password',
    name: 'recovery_password',
    component: RecoveryPassword,
    isSidebarHidden: true,
  },
  {
    path: 'paths.recovery_password',
    name: 'recovery_password',
    component: RecoveryPassword,
    isSidebarHidden: true,
  },
  {
    customPath: ['paths.transaction', '*'],
    name: 'transaction',
    component: Transaction,
  },
  {
    path: 'paths.discounts',
    name: 'discounts',
    component: Discounts,
  },
  {
    customPath: ['paths.discount', '*'],
    name: 'discount',
    component: Discount,
  },
  {
    path: 'paths.buy',
    name: 'buy',
    component: Buy,
  },
  {
    path: 'paths.personal_data',
    name: 'personal_data',
    component: PersonalData,
  },
  {
    path: 'paths.show_qr_code',
    name: 'show_qrcode',
    component: ShowQrCode,
  },
  {
    customPath: ['paths.company_contact', '*'],
    name: 'company_contact',
    component: PersonalData,
  },
  {
    path: 'paths.account_security',
    name: 'account_security',
    component: AccountSecurity,
  },
  {
    path: 'paths.send',
    name: 'send',
    component: Send,
  },
  {
    path: 'paths.payment_request',
    name: 'payment_request',
    component: PaymentRequest,
  },
  {
    path: 'paths.pay',
    name: 'pay',
    component: Pay,
  },
  {
    path: 'paths.lock',
    name: 'pay',
    component: Staking,
  },
  {
    path: 'paths.manage_discounts',
    name: 'manage_discounts',
    component: ManageDiscounts,
  },
  {
    path: 'paths.not_found',
    name: 'page_not_found',
    component: NotFound,
  },
  {
    path: 'paths.kyc_tier_one',
    name: 'kyc_tier_one',
    component: KycTierOne,
  },
  {
    path: 'paths.kyc_tier_two',
    name: 'kyc_tier_two',
    component: KycTierTwo,
  },
];

const twTheme: {
  screens: {
    [key: string]: string;
  };
  fontSize: {
    [key: string]: string;
  };
  colors: {
    [key: string]: {
      [key: string]: string;
    };
  };
} = {
  screens: {
    xs: '420px',
    sm: '640px',
    md: '913px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1480px',
    '3xl': '1600px',
  },
  fontSize: {
    xs: '.75rem',
    sm: '.875rem',
    tiny: '.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
    '7xl': '5rem',
  },
  colors: {
    consumer: {
      primary: '#42c3bf',
      'primary-lighter': '#e4f0f1',
      'primary-darker': '#3bb0ac',
      'primary-variant': '#379f9f',
      'primary-lighter-hover': '#f3f9f9',
      'primary-text': '#38a0a0',
      'primary-divider': '#deeded',
      'primary-icon': '#d8edec',
      'primary-icon-lighter': '#eef4f5',
      'primary-inverted': '#38043a',
      'primary-disabled': '#a3e1e0',
      'primary-hover': '#69cecc',
      secondary: '#eee91d',
      'light-gray': '#f7f7f8',
      'light-gray-variant': '#dededf',
      'bright-gray': '#b2b2b2',
      'bright-gray-variant': '#6b6b6b',
      white: '#ffffff',
      gray: '#58585a',
      black: '#000000',
      error: '#e46a68',
      'error-bg': '#ffefec',
      orange: '#ffab3e',
      'black-transparent': 'rgba(0, 0, 0, 0.8)',
      green: '#05c46b',
      bonus: '#fed330',
    },
    merchant: {
      primary: '#38043a',
      'primary-darker': ':#320434',
      'primary-lighter': '#e4dfe4',
      'primary-variant': '#623963',
      'primary-lighter-hover': '#f2f1f2',
      'primary-text': '#38043a',
      'primary-divider': '#c6bac6',
      'primary-icon': '#ece7eb',
      'primary-icon-lighter': '#e3dfe4',
      'primary-inverted': '#42c3bf',
      'primary-disabled': '#9f86a0',
      'primary-hover': '#623963',
      secondary: '#eee91d',
      'light-gray': '#f7f7f8',
      'light-gray-variant': '#dededf',
      'bright-gray': '#b2b2b2',
      'bright-gray-variant': '#6b6b6b',
      white: '#ffffff',
      gray: '#58585a',
      black: '#000000',
      error: '#e46a68',
      'error-bg': '#ffefec',
      orange: '#ffab3e',
      'black-transparent': 'rgba(0, 0, 0, 0.8)',
      green: '#05c46b',
      bonus: '#fed330',
    },
  },
};

const baseUiTheme = {
  primitives: {
    primaryFontFamily: 'GothamBookRnd',
  },
  overrides: {
    colors: {
      inputFill: 'none',
      inputFillActive: 'none',
      buttonPrimaryFill: twTheme.colors[application].primary,
      buttonPrimaryActive: twTheme.colors[application]['primary-darker'],
      buttonPrimaryHover: twTheme.colors[application]['primary-darker'],
      buttonDisabledFill: twTheme.colors[application]['primary-disabled'],
      buttonDisabledText: twTheme.colors[application].white,
      inputBorder: twTheme.colors[application].primary,
      tickBorder: twTheme.colors[application].primary,
      tickFillSelected: twTheme.colors[application].primary,
      tickFillSelectedHover: twTheme.colors[application]['primary-darker'],
      tickFillSelectedHoverActive:
        twTheme.colors[application]['primary-darker'],
      datepickerDayFont: twTheme.colors[application].gray,
      calendarForeground: twTheme.colors[application].gray,
      calendarHeaderForeground: twTheme.colors[application].gray,
      calendarDayForegroundPseudoSelectedHighlighted:
        twTheme.colors[application].gray,
      calendarDayBackgroundSelected: twTheme.colors[application].gray,
      calendarDayForegroundPseudoSelected: twTheme.colors[application].primary,
      calendarDayBackgroundSelectedHighlighted:
        twTheme.colors[application].primary,
      menuFillHover: twTheme.colors[application]['primary-lighter-hover'],
    },
  },
};

const headAttributes = [
  {
    key: 'appleIcon57',
    name: 'apple-icon-57x57.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '57x57' },
  },
  {
    key: 'appleIcon60',
    name: 'apple-icon-60x60.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '60x60' },
  },
  {
    key: 'appleIcon72',
    name: 'apple-icon-72x72.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '72x72' },
  },
  {
    key: 'appleIcon76',
    name: 'apple-icon-76x76.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '76x76' },
  },
  {
    key: 'appleIcon114',
    name: 'apple-icon-114x114.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '114x114' },
  },
  {
    key: 'appleIcon120',
    name: 'apple-icon-120x120.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '120x120' },
  },
  {
    key: 'appleIcon144',
    name: 'apple-icon-144x144.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '144x144' },
  },
  {
    key: 'appleIcon152',
    name: 'apple-icon-152x152.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '152x152' },
  },
  {
    key: 'appleIcon180',
    name: 'apple-icon-180x180.png',
    tag: { tagType: 'apple-icon', rel: 'apple-touch-icon', sizes: '180x180' },
  },
  {
    key: 'favIcon16',
    name: 'favicon-16x16.png',
    tag: { tagType: 'icon', rel: 'icon', type: 'image/png', sizes: '16x16' },
  },
  {
    key: 'favIcon32',
    name: 'favicon-32x32.png',
    tag: { tagType: 'icon', rel: 'icon', type: 'image/png', sizes: '32x32' },
  },
  {
    key: 'favIcon96',
    name: 'favicon-96x96.png',
    tag: { tagType: 'icon', rel: 'icon', type: 'image/png', sizes: '96x96' },
  },
  {
    key: 'androidIcon36',
    name: 'android-icon-36x36.png',
    tag: { tagType: 'icon', rel: 'icon', type: 'image/png', sizes: '36x36' },
  },
  {
    key: 'manifest',
    name: 'manifest.json',
    tag: { tagType: 'manifest', rel: 'manifest' },
  },
  {
    key: 'msIcon144',
    name: 'ms-icon-144x144.png',
    tag: { tagType: 'ms-tile-img', name: 'msapplication-TileImage' },
  },
];

const navigationItemsKeys: {
  [key: string]: {
    label: string;
    path?: string;
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    activePaths: string[];
    items?: {
      consumer: {
        label: string;
        path: string;
        icon: FunctionComponent<SVGProps<SVGSVGElement>>;
      }[];
      merchant: {
        label: string;
        path: string;
        icon: FunctionComponent<SVGProps<SVGSVGElement>>;
      }[];
    };
  };
} = {
  login: {
    label: 'login',
    path: 'paths.login',
    activePaths: ['paths.login'],
  },
  help: {
    label: 'help',
    path: 'https://newweb.mitgate.com/assistenza/',
    icon: Help,
    activePaths: ['paths.support'],
  },
  profile: {
    label: 'profile',
    activePaths: [
      'paths.show_qr_code',
      'paths.personal_data',
      'paths.account_security',
      'paths.support',
      'paths.company_contact',
      'paths.account_security',
    ],
    items: {
      consumer: [
        {
          label: 'show_qrcode',
          path: 'paths.show_qr_code',
          icon: QrCodeConsumer,
        },
        {
          label: 'public_data',
          path: 'paths.personal_data',
          icon: Profile,
        },
        {
          label: 'account_security',
          path: 'paths.account_security',
          icon: Security,
        },
        {
          label: 'help',
          path: 'https://newweb.mitgate.com/assistenza/',
          icon: Help,
        },
      ],
      merchant: [
        {
          label: 'show_qrcode',
          path: 'paths.show_qr_code',
          icon: QrCodeMerchant,
        },
        {
          label: 'company_contact',
          path: 'paths.company_contact',
          icon: CompanyContact,
        },
        {
          label: 'company_data_label',
          path: 'paths.account_security',
          icon: Security,
        },
        {
          label: 'help',
          path: 'https://newweb.mitgate.com/assistenza/',
          icon: Help,
        },
      ],
    },
  },
  discounts: {
    label: 'discounts',
    path: 'paths.discounts',
    activePaths: ['paths.discounts', 'paths.discount'],
  },
  wallet: {
    label: 'wallet',
    activePaths: [
      'paths.home',
      'paths.wallet',
      'paths.transaction',
      'paths.send',
      'paths.lock',
      'paths.pay',
      'paths.payment_request',
      'paths.manage_discounts',
    ],
    items: {
      consumer: [
        {
          label: 'your_transactions',
          path: 'paths.wallet',
          icon: WalletIcon,
        },
        {
          label: 'send',
          path: 'paths.send',
          icon: SendIcon,
        },
        {
          label: 'pay',
          path: 'paths.pay',
          icon: QrCode,
        },
        {
          label: 'lock',
          path: 'paths.lock',
          icon: Security,
        },
      ],
      merchant: [
        {
          label: 'your_transactions',
          path: 'paths.wallet',
          icon: WalletIcon,
        },
        {
          label: 'payment_request',
          path: 'paths.payment_request',
          icon: RequestPayment,
        },
        {
          label: 'send',
          path: 'paths.send',
          icon: SendIcon,
        },
        {
          label: 'manage_discounts',
          path: 'paths.manage_discounts',
          icon: DiscountIcon,
        },
      ],
    },
  },
  buy: {
    label: 'buy',
    path: 'paths.buy',
    activePaths: ['paths.buy'],
  },
};

const navigationItems = {
  guest: ['login', 'help'],
  consumer: ['profile', 'buy', 'wallet', 'discounts'],
  merchant: ['profile', 'wallet', 'buy'],
};

export {
  routes,
  twTheme,
  baseUiTheme,
  headAttributes,
  navigationItemsKeys,
  navigationItems,
};
