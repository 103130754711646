import { ethers } from 'ethers';

import { Roles, UserKeys } from './types';

const VERSION = '0.1.0';

const roles: { [key: string]: Roles } = {
  GUEST: 'guest',
  CONSUMER: 'consumer',
  MERCHANT: 'merchant',
};

const environments = {
  DEV: 'DEV',
  PROD: 'PROD',
};

const currentEnv = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV
  : 'DEV';

const application: string = process.env.REACT_APP_APPLICATION
  ? process.env.REACT_APP_APPLICATION
  : 'consumer';

const paypalClientId = `${
  process.env[`REACT_APP_PAYPAL_CLIENT_ID_${currentEnv}`]
}`;

const regex = {
  phone: /^[0-9]*$/gm,
  /* eslint-disable */
  email:
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
  /* eslint-disable */
  fiscalCode:
    /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,

  cap: /^[0-9]{5}$/,
  address: /^0x[a-fA-F0-9]{40}$/,
  nickname: /^[@]/,
};

const tokenToEurConversionRate = 0.1;

const provider = new ethers.providers.JsonRpcProvider(
  process.env[`REACT_APP_BLOCKCHAIN_PROVIDER_${currentEnv}`]
    ? process.env[`REACT_APP_BLOCKCHAIN_PROVIDER_${currentEnv}`]
    : ''
);

const addressZero = '0x0000000000000000000000000000000000000000';

const maxUploadedFileSize = 8000000;

const mitigateBeneficiary = 'MITGATE S.R.L.';
const mitigateIban = 'IT42 O 03032 12301 010000156071';
const mitigatePaypalEmail = 'https://www.paypal.com/paypalme/mitgate';

const safeProxyAddress =
  process.env[`REACT_APP_SAFE_PROXY_ADDRESS_${currentEnv}`] || '';

const acceptedCryptoCurrencies = ['ethereum'];

const minTokenPurchaseAmount = 10;
const bonusSteps = [
  {
    bonusPercentage: 50,
    startDate: 1642201200,
    endDate: 1643065199,
    maxSupply: 7500000,
  },
  {
    bonusPercentage: 25,
    startDate: 1643065200,
    endDate: 1644361199,
    maxSupply: 7500000,
  },
  {
    bonusPercentage: 10,
    startDate: 1644361200,
    endDate: 1646089199,
    maxSupply: 10000000,
  },
];

// TODO: update
const smartContractsAddress = [
  '0x4fedc9bd08552820d4a1edc8e850e78d050bd471', // paypalcontract_address
  '0xbd8411764334bfd94f0f3e8d010051ecb498e100', // banktransfercontract_address
  '0x12787eeca727a5fc9a187f97b6249a1883e5f125', // personaldata_address
  '0xc24e9b1ffc1b9724ab74bae19cd3b1b7866a042c', // mitcoin_address
  '0xf742d698d672575b9ff3654ac5a0f329ae5d2762', // cashback_address
  '0xe543bbadde57a6358d461f424e728dc9162aeff6', // vipstatus_address
  '0x82de9c619b54850d3a6f85b611774949ba0db1e8', // multisend_address
  '0xb0e458431277278b7a43a98873ecd85e51b26115', // safe_mastercopy_address
  '0xa05a12dca16cc4565190b4ef1e6f13dc4072393e', // safe_proxy_address
  '0xc8c721d8ac4797db99b47ce80495009aa2071469', // proxyfactory_address
  '0x99fdc0c7bbb6a0a50021c66c5a664d0d6024eba5', // rolemanager_address
  '0x4889e160abc0a1e7332dc4efef0041b043904ea4', // personaldata_address
  '0x5a7948073b957ff59a1c43c7bcc1b23202be505c', // mitcoin_address
];

const minAmountOfCreditToSend = 1;
const maxAmountOfCreditToSend = 99999;

const minAmountOfCreditToRequest = 1;
const maxAmountOfCreditToRequest = 99999;

const companyTypes: string[] = [];

for (let i = 1; i < 26; i++) {
  companyTypes.push(`company_type_${i}`);
}

const worldCountries = [
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BA',
  'BW',
  'BR',
  'IO',
  'VG',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CD',
  'CG',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'XK',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'KP',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'KR',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'VI',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UY',
  'UZ',
  'VU',
  'VA',
  'VE',
  'VN',
  'WF',
  'YE',
  'ZM',
  'ZW',
  'AX',
];

const merchantDataToFillOutToBeVerified: UserKeys[] = ['tierOne', 'tierTwo'];

const transactionsFilters = ['all', 'incoming', 'outgoing'];

const annualVolumes = [
  {
    label: '< 1.000 €',
    id: '< 1.000 €',
  },
  {
    label: '1.000 - 9.999 €',
    id: '1.000 - 9.999 €',
  },
  {
    label: '10.000 - 99.999 €',
    id: '10.000 - 99.999 €',
  },
  {
    label: '> €100.000 ',
    id: '> €100.000 ',
  },
];

const foundsProvenance = [
  'job_provenance',
  'freelance_provenance',
  'commercial_provenance',
  'investment_provenance',
  'financing_provenance',
  'inheritance_or_donations',
  'savings_provenance',
  'other_provenance',
];

export {
  VERSION,
  roles,
  environments,
  currentEnv,
  application,
  regex,
  tokenToEurConversionRate,
  provider,
  addressZero,
  mitigateIban,
  mitigatePaypalEmail,
  acceptedCryptoCurrencies,
  minTokenPurchaseAmount,
  bonusSteps,
  smartContractsAddress,
  mitigateBeneficiary,
  minAmountOfCreditToSend,
  maxAmountOfCreditToSend,
  minAmountOfCreditToRequest,
  maxAmountOfCreditToRequest,
  paypalClientId,
  companyTypes,
  merchantDataToFillOutToBeVerified,
  transactionsFilters,
  safeProxyAddress,
  annualVolumes,
  foundsProvenance,
  worldCountries,
  maxUploadedFileSize,
};
