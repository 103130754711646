import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomButton, FloatingDialog, Loader } from '../../../..';
import {
  withAuth,
  useStorage,
  useAppSelector,
  useUpdateTier,
} from '../../../../../hooks';
import { roles } from '../../../../../imports/constants';
import RegistrationStepper from '../../../../molecules/Stepper';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from '../../UploadIdentityDoc';
import { UserState } from '../../../../../store/user/types';

const CompleteRegistrationConsumer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { updateTier, loader: isLoading } = useUpdateTier();

  const {
    privateProfile: { uid },
    email,
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const { upload } = useStorage(`consumers/${uid}/kwc/tier_one`);

  const [activeStep, setActiveStep] = useState(1);
  const [stepOneData, setTierOneData] = useState({
    firstName: '',
    lastName: '',
    email,
    birthDate: null,
    birthplace: '',
    city: '',
    country: '',
    district: '',
    cap: '',
    streetAddress: '',
    streetNumber: '',
    job: '',
    phone: '',
    economicActivity: '',
  });

  const [stepTwoData, setTierTwoData] = useState({
    document_type: '',
    document_number: '',
    document_release: null,
    document_release_entity: '',
    document_expiry_date: null,
    citizenship: '',
    fiscalCode: '',
  });

  const [documents, setDocuments] = useState({
    document_photo_front: undefined,
    document_photo_back: undefined,
    profile_photo: undefined,
  });

  const incrementStep = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const decrementStep = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const updateDataOnFirestore = async (data: any) => {
    await upload({ document_photo_back: data.document_photo_back });
    await upload({ document_photo_front: data.document_photo_front });
    await upload({ profile_photo: data.profile_photo });

    const tierData = {
      ...stepOneData,
      identificationDocument: {
        type: stepTwoData.document_type,
        number: stepTwoData.document_number,
        issuingDate: stepTwoData.document_release,
        issuingEntity: stepTwoData.document_release_entity,
        expirationDate: stepTwoData.document_expiry_date,
      },
      citizenship: stepTwoData.citizenship,
      fiscalCode: stepTwoData.fiscalCode,
    };

    await updateTier('consumers', 'tierOne', tierData);

    incrementStep();
  };

  const backToHome = () => {
    navigate(t('paths.wallet'));
  };

  const activeTier = () => {
    switch (activeStep) {
      case 2:
        return (
          <StepTwo
            prevStep={decrementStep}
            nextStep={incrementStep}
            formInitialData={stepTwoData}
            updateFormData={setTierTwoData}
          />
        );
      case 3:
        return (
          <StepThree
            prevStep={decrementStep}
            formInitialData={{
              document_photo_back: documents.document_photo_back
                ? documents.document_photo_back
                : '',
              document_photo_front: documents.document_photo_front
                ? documents.document_photo_front
                : '',
              profile_photo: documents.profile_photo
                ? documents.profile_photo
                : '',
            }}
            updateFormData={updateDataOnFirestore}
            files={documents}
            setFiles={setDocuments}
          />
        );
      case 4:
        return (
          <div>
            <h4 className="mb-5 text-2xl text-center font-gotham-bold text-primary">
              {t('subscription_completed')}
            </h4>
            <p className="my-10 text-center">{t('kyc_completed_message')}</p>
            <div className="max-w-sm mx-auto mt-5">
              <CustomButton
                label={t('back_to_wallet')}
                primaryAction={backToHome}
              />
            </div>
          </div>
        );
      default:
        return (
          <StepOne
            nextStep={incrementStep}
            formInitialData={stepOneData}
            updateFormData={setTierOneData}
          />
        );
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="relative w-full mt-8 h-96">
          <Loader />
        </div>
      ) : (
        <div className="pt-14">
          {activeStep <= 3 && (
            <>
              <h1 className="px-8 text-2xl text-center font-gotham-bold text-primary-text xs:px-14 sm:px-20 md:px-8 md:mb-4">
                {t('complete_registration_step_one')}
              </h1>

              <RegistrationStepper
                currentStep={activeStep}
                labels={[
                  t('subscription_step_one'),
                  t('subscription_step_two'),
                  t('subscription_step_three'),
                ]}
              />
            </>
          )}
          <div className="md:mt-24 md:mb-20">
            <FloatingDialog>{activeTier()}</FloatingDialog>
          </div>
        </div>
      )}
    </>
  );
};

export default withAuth(CompleteRegistrationConsumer, {
  roles: [roles.CONSUMER],
});
