import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useMedia } from 'react-use';

import { FloatingDialog } from '../components';
import InputCopy from '../components/molecules/InputCopy';
import { useAppSelector, useNickname, withAuth } from '../hooks';
import { roles } from '../imports/constants';
import { screen } from '../imports/utils';
import { UserState } from '../store/user/types';

const ShowQrCode = () => {
  const { t } = useTranslation();
  const {
    wallet: { address },
  } = useAppSelector(({ user }: { user: UserState }) => user);
  const { nickname } = useNickname(address);
  const isDesktop = useMedia(`(min-width: ${screen('md')})`);

  return (
    <div className="pt-14 xs:pt-20 md:pt-14">
      <h1 className="text-[2rem] md:text-[2.5rem] leading-10 text-center font-gotham-bold text-primary-text px-8 xs:px-14 sm:px-20 md:px-8">
        {t('qr_code')}
      </h1>
      <div className="md:mt-4 md:mb-20">
        <FloatingDialog>
          <QRCode
            value={address}
            size={isDesktop ? 320 : 220}
            className="mx-auto"
          />
          <InputCopy
            label="Address"
            valueToShow={
              address.slice(0, 14).concat('....') + address.slice(32)
            }
            valueToCopy={address}
          />
          {nickname[0] === '@' && (
            <InputCopy
              label="Nickname"
              valueToShow={nickname}
              valueToCopy={nickname}
            />
          )}
        </FloatingDialog>
      </div>
    </div>
  );
};

export default withAuth(ShowQrCode, {
  roles: [roles.CONSUMER, roles.MERCHANT],
});
