import * as React from 'react';

import { AppProvider } from '../context';

const providers = [AppProvider];

const ContextProvider = providers.reduce(
  (AccumulatedComponents, CurrentComponent) =>
    ({ children }: { children: React.ReactNode }) =>
      (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      ),
  ({ children }) => <>{children}</>
);

export default ContextProvider;
