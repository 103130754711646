import React from 'react';
import { useSelector } from 'react-redux';
import { withAuth } from '../hooks';
import { roles } from '../imports/constants';
import { UserState } from '../store/user/types';
import KycVerifyConsumer from '../components/organisms/KycSteps/Consumer/TierTwo';
import KycVerifyMerchant from '../components/organisms/KycSteps/Merchant/TierTwo';

const KycTierTwo: React.FC = () => {
  const { role } = useSelector(({ user }: { user: UserState }) => user);

  return (
    <>{role === 'consumer' ? <KycVerifyConsumer /> : <KycVerifyMerchant />}</>
  );
};

export default withAuth(KycTierTwo, {
  roles: [roles.CONSUMER, roles.MERCHANT],
});
