import { PLACEMENT, Popover } from 'baseui/popover';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { color } from '../../../imports/utils';

const CustomPopover = ({
  content,
  children,
}: {
  content: React.ReactNode;
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (location.key !== prevLocation?.key) {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  }, [location]);

  return (
    <button type="button" onClick={() => setIsOpen(!isOpen)}>
      <Popover
        isOpen={isOpen}
        content={content}
        placement={PLACEMENT.bottomRight}
        overrides={{
          Body: {
            style: () => ({
              borderTopLeftRadius: '0.25rem',
              borderTopRightRadius: '0.25rem',
              borderBottomLeftRadius: '0.25rem',
              borderBottomRightRadius: '0.25rem',
              zIndex: 30,
            }),
          },
          Inner: {
            style: () => ({
              backgroundColor: color('white'),
              borderTopLeftRadius: '0.25rem',
              borderTopRightRadius: '0.25rem',
              borderBottomLeftRadius: '0.25rem',
              borderBottomRightRadius: '0.25rem',
            }),
          },
          Arrow: {
            style: () => ({
              backgroundColor: color('white'),
            }),
          },
        }}
        showArrow
        returnFocus
        autoFocus
      >
        {children}
      </Popover>
    </button>
  );
};

export default CustomPopover;
