import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CustomButton, FloatingDialog } from '../../../..';
import TierKyc from './TierKyc';
import { useStorage, withAuth } from '../../../../../hooks';
import { roles } from '../../../../../imports/constants';
import { UserState } from '../../../../../store/user/types';
import useUpdateTier from '../../../../../hooks/UserFlow/useUpdateTier';

const KycVerify = () => {
  const { t } = useTranslation();
  const { updateTier } = useUpdateTier();

  const {
    privateProfile: { uid },
  } = useSelector(({ user }: { user: UserState }) => user);

  const { upload } = useStorage(`consumers/${uid}/kwc/tier_two`);

  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const updateDataOnFirestore = async (data: any) => {
    await upload({ kyc_bill_proof: data.kyc_bill_proof });
    delete data.kyc_bill_proof;
    await updateTier('consumers', 'tierTwo', data);
  };

  return (
    <div className="pt-14">
      <h1 className="px-8 text-2xl text-center font-gotham-bold text-primary-text xs:px-14 sm:px-20 md:px-8">
        {t('verify')}
      </h1>
      <div className="md:mt-16 md:mb-20">
        <FloatingDialog>
          {step === 1 ? (
            <TierKyc
              updateFormData={updateDataOnFirestore}
              nextStep={() => setStep((prevState) => prevState + 1)}
            />
          ) : (
            <div>
              <h4 className="mb-5 text-2xl text-center font-gotham-bold text-primary">
                {t('verify_completed')}
              </h4>
              <div className="my-10">
                <p className="my-10 text-center">
                  {t('kyc_completed_message')}
                </p>
              </div>
              <div className="max-w-sm mx-auto mt-5">
                <CustomButton
                  label={t('back_to_wallet')}
                  primaryAction={() => navigate(t('paths.home'))}
                />
              </div>
            </div>
          )}
        </FloatingDialog>
      </div>
    </div>
  );
};

export default withAuth(KycVerify, { roles: [roles.CONSUMER] });
