import { initializeApp } from 'firebase/app';
// import { initializeApp, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const currentEnv = process.env.REACT_APP_ENV;

const firebase = initializeApp({
  apiKey: process.env[`REACT_APP_FIREBASE_API_KEY_${currentEnv}`],
  authDomain: process.env[`REACT_APP_FIREBASE_AUTH_DOMAIN_${currentEnv}`],
  projectId: process.env[`REACT_APP_FIREBASE_PROJECT_ID_${currentEnv}`],
  storageBucket: process.env[`REACT_APP_FIREBASE_STORAGE_BUCKET_${currentEnv}`],
  messagingSenderId: process.env[`FIREBASE_MESSAGING_SENDER_ID_${currentEnv}`],
  appId: process.env[`REACT_APP_FIREBASE_APP_ID_${currentEnv}`],
  measurementId: process.env[`REACT_APP_FIREBASE_MEASUREMENT_ID_${currentEnv}`],
});

export const firestore = getFirestore(firebase);
export const auth = getAuth(firebase);

// To instrument your app to interact with the emulators,
// you may need to do some additional configuration.
// const functions = getFunctions(getApp());
// connectFunctionsEmulator(functions, 'localhost', 5001);
