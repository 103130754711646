import { onSnapshot, doc, DocumentData, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';
import { signOut } from 'firebase/auth';

import { toast } from 'react-toastify';

import {
  createListenerMiddleware,
  Unsubscribe,
  isAnyOf,
} from '@reduxjs/toolkit';

import i18n from '../../i18n';

import { firestore, auth } from '../../firebase';
import { Consumer, Merchant, UserState } from './types';

import { application } from '../../imports/constants';

import {
  updateRole,
  updateAuthToken,
  logout,
  updatePrivateProfile,
  updateProfilePhoto,
} from './user.slice';
import { firestoreRestApi } from '../../services/firestoreRestApi';

const userMiddleware = createListenerMiddleware();

let unsubUser: Unsubscribe;

userMiddleware.startListening({
  matcher: isAnyOf(updateAuthToken, updateRole),
  effect: async (_, listenerApi) => {
    const {
      user: {
        email,
        role,
        wallet: { address },
        privateProfile: { uid },
      },
    } = listenerApi.getState() as {
      user: UserState;
    };

    if (uid && role !== 'guest') {
      const collectionRef =
        application === 'consumer' ? 'consumers' : 'merchants';

      const storage = getStorage();
      const fileRef = ref(storage, `${collectionRef}/${uid}/profile_photo.jpg`);

      const usersDirectoryList = await listAll(
        ref(storage, `${collectionRef}/${uid}`)
      );

      const fileExist = !!usersDirectoryList.items.find(
        (item) => item.name === 'profile_photo.jpg'
      );

      if (fileExist) {
        getDownloadURL(fileRef).then((url) => {
          listenerApi.dispatch(updateProfilePhoto(url));
        });
      }

      // Firestore listeners
      unsubUser = onSnapshot(doc(firestore, collectionRef, uid), (doc) => {
        const newDocData = doc.data() as DocumentData;

        if (newDocData.locked) {
          signOut(auth).then(() => {
            toast.error(newDocData.locked);
            listenerApi.dispatch(logout());
          });
        } else if (address === newDocData.walletAddress) {
          const formattedDocData = {
            uid: doc.id,
            ...newDocData,
          } as Consumer | Merchant;

          listenerApi.dispatch(updatePrivateProfile(formattedDocData));
        }
      });
    }
  },
});

userMiddleware.startListening({
  actionCreator: updateRole,
  effect: async (_, listenerApi) => {
    const {
      user: {
        email,
        wallet: { address },
        privateProfile: { uid },
      },
    } = listenerApi.getState() as {
      user: UserState;
    };

    const collectionRef =
      application === 'consumer' ? 'consumers' : 'merchants';

    const ref = doc(firestore, collectionRef, uid);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const newDocData = docSnap.data() as DocumentData;

      if (address !== newDocData.walletAddress) {
        const walletFromLocalStorage = localStorage.getItem('wallets');
        if (walletFromLocalStorage) {
          const formattedWallets = JSON.parse(walletFromLocalStorage);

          delete formattedWallets[email];

          localStorage.setItem('wallets', JSON.stringify(formattedWallets));
        }

        signOut(auth).then(() => {
          toast.error(i18n.t('errors.invalid_mnemonic'));
          listenerApi.dispatch(logout());
        });
      } else {
        listenerApi.dispatch(
          firestoreRestApi.endpoints.sendEmailNotificationApi.initiate({
            notificationType: `login_${i18n.language}`,
            email,
          })
        );
      }
    }
  },
});

userMiddleware.startListening({
  actionCreator: logout,
  effect: async () => {
    unsubUser();
  },
});

export default userMiddleware;
