import React from 'react';
import { Select } from 'baseui/select';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

import { Alert } from '../../../assets/icons';

import { twTheme } from '../../../imports/config';
import { application } from '../../../imports/constants';
import { color } from '../../../imports/utils';

const CustomSelect = ({
  onBlur,
  onChange,
  controlRef,
  value,
  options,
  placeholder,
  error,
  disabled,
}: {
  onBlur?: any;
  onChange?: any;
  controlRef?: any;
  value: { label: string; id: string }[];
  options: { label: string; id: string }[];
  placeholder?: string;
  error?: FieldError | undefined;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      // NOTE call on change when input is empty to reset the state
      onChange(e.target.value);
    }
  };

  const handleChange = (params: any) => {
    if (params.type !== 'remove') {
      onChange(params.value[0].id);
    }
  };

  return (
    <>
      <Select
        controlRef={controlRef}
        overrides={{
          Root: {
            style: () => ({
              borderRightColor: 'transparent',
              borderLeftColor: 'transparent',
              borderTopColor: 'transparent',
              borderBottomColor: `${color(
                !error ? 'primary' : 'error'
              )} !important`,
              backgroundColor: 'transparent',
              borderBottomWidth: `0.125rem`,
            }),
          },
          ControlContainer: {
            style: () => ({
              borderRightColor: 'transparent',
              borderLeftColor: 'transparent',
              borderTopColor: 'transparent',
              borderBottomColor: 'transparent',
            }),
          },
          ValueContainer: {
            style: () => ({
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: '0.125rem',
              paddingRight: !error ? '0.25rem' : '1.75rem',
              color: color('gray'),
              fontFamily: 'GothamBookRnd',
            }),
          },
          IconsContainer: {
            style: () => ({ display: 'none' }),
          },
          DropdownListItem: {
            style: () => ({
              ':hover': {
                background:
                  twTheme.colors[application]['primary-lighter-hover'],
              },
            }),
          },
        }}
        options={options}
        value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={onBlur}
        placeholder={placeholder}
        noResultsMsg={t('no_results')}
        maxDropdownHeight="300px"
        disabled={disabled}
      />
      {error && (
        <Alert className="absolute right-0 w-5 h-5 translate-y-[calc(-50%-0.25rem)] -translate-x-1 fill-current top-1/2 text-error" />
      )}
    </>
  );
};

CustomSelect.defaultProps = {
  onBlur: undefined,
  onChange: undefined,
  controlRef: undefined,
  placeholder: '',
  error: undefined,
  disabled: false,
};

export default CustomSelect;
