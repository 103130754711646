import { isFulfilled, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18n from '../i18n';

const rtkQueryResultToast: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if (action.payload?.data?.message) {
      toast.error(
        i18n.t(
          `firebase_callables_response.errors.${action.payload.data.message}`
        )
      );
    }
  } else if (isFulfilled(action) && !action.meta?.arg?.forceRefetch) {
    if (action.payload?.message) {
      toast.success(
        i18n.t(`firebase_callables_response.success.${action.payload.message}`)
      );
    }
  }

  return next(action);
};

export default rtkQueryResultToast;
