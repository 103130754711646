import { ethers } from 'ethers';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { logout, setWallet, updateAuthToken } from '../user/user.slice';
import {
  fetchTransfers,
  addTransfer,
  setFromBlock,
  fetchBalance,
} from './transfers.slice';
import { formatTransfer } from './fetchUtils';

import { mitToken } from '../../contracts';
import { provider } from '../../imports/constants';

const mitTokenContract = new ethers.Contract(
  mitToken.contract_address,
  mitToken.abi,
  provider
);

const transfersMiddleware = createListenerMiddleware();

transfersMiddleware.startListening({
  matcher: isAnyOf(setWallet, updateAuthToken),
  effect: async (_, listenerApi) => {
    const {
      user: {
        wallet: { address },
      },
    } = listenerApi.getState() as {
      user: { wallet: { address: string } };
    };

    if (address) {
      listenerApi.dispatch(fetchTransfers());
      listenerApi.dispatch(fetchBalance());
    }
  },
});

transfersMiddleware.startListening({
  actionCreator: fetchTransfers.fulfilled,
  effect: async (action, listenerApi) => {
    // Define events filters
    const incomingTransfersEvents = mitTokenContract.filters.Transfer(
      null,
      action.payload.address
    );
    const outcomingTransfersEvents = mitTokenContract.filters.Transfer(
      action.payload.address
    );

    // Blockchain listeners
    mitTokenContract.on(
      incomingTransfersEvents,
      async (from, to, amount, event) => {
        const formattedTransfer = await formatTransfer(event);
        const currentBlock = await provider.getBlockNumber();
        listenerApi.dispatch(setFromBlock(currentBlock + 1));
        listenerApi.dispatch(addTransfer(formattedTransfer));

        listenerApi.dispatch(fetchBalance());
      }
    );

    mitTokenContract.on(
      outcomingTransfersEvents,
      async (from, to, amount, event) => {
        const formattedTransfer = await formatTransfer(event);
        const currentBlock = await provider.getBlockNumber();
        listenerApi.dispatch(setFromBlock(currentBlock + 1));
        listenerApi.dispatch(addTransfer(formattedTransfer));

        listenerApi.dispatch(fetchBalance());
      }
    );
  },
});

transfersMiddleware.startListening({
  actionCreator: logout,
  effect: async () => {
    mitTokenContract.removeAllListeners();
    localStorage.removeItem('lockingEvents');
  },
});

export default transfersMiddleware;
