import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PublicData, PublicProfiles } from './types';
import { RootState } from '../store';
import { publicProfilesReducer } from './publicProfiles.reducer';
import { getFormattedProfiles } from './fetchUtils';

// Define initial state
export const publicProfilesInitialState: PublicProfiles = {
  loader: true,
  list: [],
};

// State slice
export const publicProfilesSlice = createSlice({
  name: 'publicProfiles',
  initialState: publicProfilesInitialState,
  reducers: publicProfilesReducer,
  extraReducers: (builder) => {
    builder.addCase(fetchPublicProfiles.pending, (state) => {
      state.loader = true;
    });

    builder.addCase(fetchPublicProfiles.fulfilled, (state, { payload }) => {
      state.list = state.list.concat(payload);

      state.loader = false;
    });

    builder.addCase(fetchPublicProfiles.rejected, (state) => {
      state.loader = false;
    });
  },
});

// Action creators
export const {
  actions: { setLoader, addPublicProfile, updatePublicProfile, reset },
} = publicProfilesSlice;

// Getters
export const getPublicProfiles = (state: RootState): PublicProfiles =>
  state.publicProfiles;

// Async thunks
export const fetchPublicProfiles = createAsyncThunk<PublicData[], void>(
  'publicProfiles/fetchPublicProfiles',
  async (_, { rejectWithValue }) => {
    try {
      const formattedProfiles = await getFormattedProfiles();

      return formattedProfiles;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
