import * as React from 'react';
import { useMedia } from 'react-use';
import { PinCode } from 'baseui/pin-code';

import { screen, color } from '../../../imports/utils';

const CustomPinCodeInput = ({
  name,
  onBlur,
  onChange,
  value,
  error,
}: {
  name?: string;
  onBlur?: any;
  onChange?: any;
  value: string[];
  error?: boolean;
}) => {
  const isDesktop = useMedia(`(min-width: ${screen('md')})`);

  return (
    <PinCode
      overrides={{
        Input: {
          props: {
            overrides: {
              Input: {
                style: () => ({
                  backgroundColor: color(!error ? 'primary-icon' : 'error-bg'),
                  fontSize: isDesktop ? '1.75rem' : '1.5rem',
                  fontFamily: 'GothamBookRndBold',
                  color: color(!error ? 'primary-text' : 'error'),
                }),
                inputMode: 'string',
              },
              Root: {
                style: () => ({
                  borderWidth: '0.2rem',
                  borderColor: `${color(
                    !error ? 'primary-text' : 'error'
                  )} !important`,
                  borderTopLeftRadius: '0.375rem',
                  borderTopRightRadius: '0.375rem',
                  borderBottomLeftRadius: '0.375rem',
                  borderBottomRightRadius: '0.375rem',

                  width: isDesktop ? '4rem' : '3rem',
                  height: isDesktop ? '4rem' : '3rem',
                  margin: isDesktop ? '0 1rem' : '0 0.25em',
                }),
              },
            },
          },
        },
      }}
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={({ values }) => onChange(values)}
      values={value}
      placeholder=""
      clearOnEscape
      error={error}
    />
  );
};

CustomPinCodeInput.defaultProps = {
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  error: false,
};

export default CustomPinCodeInput;
