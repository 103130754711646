import { ethers } from 'ethers';
import { setDoc, doc } from 'firebase/firestore';

// Firestore
import { useAppSelector } from '..';
import { getTransfers } from '../../store/transfers/transfers.slice';
import { getPublicProfiles } from '../../store/publicProfiles/publicProfiles.slice';

// Types
import { UserState } from '../../store/user/types';
import { FormattedTransfer } from '../../store/transfers/types';

// ABI
import { mitToken } from '../../contracts';

// Blockchain provider
import { provider } from '../../imports/constants';

import { firestore } from '../../firebase';

const mitTokenContract = new ethers.Contract(
  mitToken.contract_address,
  mitToken.abi,
  provider
);

const overrides = {
  gasLimit: 4000000,
  gasPrice: 0,
};

// Define pecurial context type

const UseTransferMit = () => {
  const {
    wallet: { privateKey, address },
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const { list: transfers, loader: contactsLoader } =
    useAppSelector(getTransfers);

  const { list: publicProfiles } = useAppSelector(getPublicProfiles);

  const signerWallet = new ethers.Wallet(privateKey, provider);
  const contractWithSigner = mitTokenContract.connect(signerWallet);

  const addresses = transfers.map((event: FormattedTransfer) =>
    event.from !== address ? event.from : event.to
  );
  const frequentAddresses = [...new Set(addresses)].slice(-10);

  const frequentContacts = publicProfiles.filter((publicProfile) =>
    frequentAddresses.includes(publicProfile.walletAddress)
  );

  const transferMit = async (
    recipient: string,
    amount: string,
    reason?: string
  ) => {
    const formattedAmount = ethers.utils.parseUnits(amount).toString();
    const txn = await contractWithSigner.transfer(
      recipient,
      formattedAmount,
      overrides
    );

    try {
      if (reason) {
        const transferReason = {
          from: address,
          to: recipient,
          reason,
        };

        const docRef = doc(firestore, 'transfersReasons', txn.hash);

        await setDoc(docRef, transferReason);

        await txn.wait();
      }

      return true;
    } catch {
      return false;
    }
  };

  return {
    transferMit,
    contactsLoader,
    frequentContacts,
  };
};

export default UseTransferMit;
