import { useDelayedRender } from '../hooks';

const DelayedRender = ({
  delay,
  children,
}: {
  delay: number;
  children: React.ReactElement;
}) => useDelayedRender(delay)(() => children);

export default DelayedRender;
