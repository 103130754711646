import { encrypt, decrypt } from 'crypto-js/aes';
import { enc } from 'crypto-js';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { firestoreRestApi } from '../services/firestoreRestApi';
import reducers from './root-reducer';

import rtkQueryResultToast from './toastMiddleware';
import userMiddleware from './user/user.middleware';
import transfersMiddleware from './transfers/transfers.middleware';
import publicProfilesMiddleware from './publicProfiles/publicProfiles.middleware';

import { UserState } from './user/types';

// Get Encrypt key from env variables
const currentEnv = process.env.REACT_APP_ENV;
const cacheEncryptKey =
  process.env[`REACT_APP_CACHE_ENCRYPT_KEY_${currentEnv}`];

const EncryptWallet = createTransform(
  // Transform state on its way to being serialized and persisted.
  (inboundState: UserState) => {
    // Encrypt wallet.
    const encryptedWallet = encrypt(
      JSON.stringify(inboundState.wallet),
      cacheEncryptKey || ''
    ).toString();

    return { ...inboundState, wallet: encryptedWallet };
  },
  // Transform state being rehydrated
  (outboundState) => {
    // Decrypt wallet
    const bytes = decrypt(outboundState.wallet, cacheEncryptKey || '');
    const decryptedWallet = JSON.parse(bytes.toString(enc.Utf8));

    return { ...outboundState, wallet: decryptedWallet };
  },
  // Define which reducers this transform gets called for.
  { whitelist: ['user'] }
);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [firestoreRestApi.reducerPath, 'publicProfiles', 'transfers'],
  transforms: [EncryptWallet],
};

const rootReducer = combineReducers({
  ...reducers,
  [firestoreRestApi.reducerPath]: firestoreRestApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .prepend(
        userMiddleware.middleware,
        transfersMiddleware.middleware,
        publicProfilesMiddleware.middleware
      )
      .concat(firestoreRestApi.middleware, rtkQueryResultToast),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
