import * as React from 'react';
import usePlacesAutocomplete, { RequestOptions } from 'use-places-autocomplete';
import { Select } from 'baseui/select';
import Lottie from 'react-lottie-player';
import { useTranslation } from 'react-i18next';

import { FieldError } from 'react-hook-form';

import { searchLoader } from '../../../assets/animations';
import { Alert } from '../../../assets/icons';

import { twTheme } from '../../../imports/config';
import { application } from '../../../imports/constants';
import { hexToLottieColorFormat, color } from '../../../imports/utils';

const SearchLoader = () => (
  <Lottie
    animationData={searchLoader(hexToLottieColorFormat(color('primary'), 1))}
    loop
    play
    className="absolute top-0 left-0 w-12 h-12 -translate-x-3.5 -translate-y-4"
  />
);

const CustomSelectPlacesAutocomplete = ({
  value,
  error,
  controlRef,
  onBlur,
  onChange,
  requestOptions,
  disabled,
}: {
  value: any;
  error?: FieldError | undefined;
  controlRef?: any;
  onBlur?: any;
  onChange?: any;
  requestOptions?: RequestOptions;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    ready,
    suggestions: { data, loading },
    setValue: setValueAutocomplete,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions,
    defaultValue: value,
  });

  const isLoading = !ready || loading;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueAutocomplete(e.target.value);

    if (e.target.value === '') {
      // NOTE call on change when input is empty to reset the state
      onChange(e.target.value);
    }
  };

  const handleChange = (params: any) => {
    if (params.type !== 'remove') {
      onChange(params.value[0].id);
      clearSuggestions();
    }
  };

  return (
    <>
      <Select
        controlRef={controlRef}
        overrides={{
          Root: {
            style: () => ({
              borderRightColor: 'transparent',
              borderLeftColor: 'transparent',
              borderTopColor: 'transparent',
              borderBottomColor: `${color(!error ? 'primary' : 'error')}`,
              backgroundColor: 'transparent',
              borderBottomWidth: `0.125rem`,
            }),
          },
          ControlContainer: {
            style: () => ({
              borderRightColor: 'transparent',
              borderLeftColor: 'transparent',
              borderTopColor: 'transparent',
              borderBottomColor: 'transparent',
            }),
          },
          ValueContainer: {
            style: () => ({
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: '0.125rem',
              paddingRight: !error ? '0.25rem' : '1.75rem',
              color: color('gray'),
              fontFamily: 'GothamBookRnd',
            }),
          },
          IconsContainer: {
            style: () => ({ display: 'none' }),
          },
          DropdownListItem: {
            style: () => ({
              ':hover': {
                background:
                  twTheme.colors[application]['primary-lighter-hover'],
              },
            }),
          },
        }}
        options={data.map((suggestion) => ({
          label: suggestion.description,
          id: suggestion.structured_formatting.main_text,
        }))}
        value={[{ label: value, id: value }]}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={onBlur}
        placeholder=""
        noResultsMsg={t('no_results')}
        maxDropdownHeight="300px"
        disabled={disabled}
      />
      {isLoading && (
        <div
          className={`absolute top-0 ${error ? 'right-8' : 'right-0'} w-5 h-5`}
        >
          <SearchLoader />
        </div>
      )}
      {error && (
        <Alert className="absolute right-0 w-5 h-5 translate-y-[calc(-50%-0.25rem)] -translate-x-1 fill-current top-1/2 text-error" />
      )}
    </>
  );
};

CustomSelectPlacesAutocomplete.defaultProps = {
  error: undefined,
  controlRef: undefined,
  onBlur: undefined,
  onChange: undefined,
  requestOptions: {},
  disabled: false,
};

export default CustomSelectPlacesAutocomplete;
