import { Button } from 'baseui/button';
import React from 'react';
import { CloseBanner, CloseBannerMerchant } from '../../../assets/icons';

import { application } from '../../../imports/constants';

type Props = {
  handleClose: () => void;
};

const CloseButton: React.FC<Props> = ({ handleClose }) => (
  <Button
    onClick={handleClose}
    overrides={{
      BaseButton: {
        style: () => ({
          backgroundColor: 'inherit',
          ':focus': {
            backgroundColor: 'inherit',
          },
          ':hover': {
            backgroundColor: 'inherit',
          },
        }),
      },
    }}
  >
    {application === 'consumer' ? <CloseBanner /> : <CloseBannerMerchant />}
  </Button>
);

export default CloseButton;
