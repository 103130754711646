import React from 'react';
import { useSelector } from 'react-redux';
import { withAuth } from '../hooks';
import { roles } from '../imports/constants';
import { UserState } from '../store/user/types';
import CompleteRegistrationConsumer from '../components/organisms/KycSteps/Consumer/TierOne';
import CompleteRegistrationMerchant from '../components/organisms/KycSteps/Merchant/TierOne';

const KycTierOne: React.FC = () => {
  const { role } = useSelector(({ user }: { user: UserState }) => user);

  return (
    <>
      {role === 'consumer' ? (
        <CompleteRegistrationConsumer />
      ) : (
        <CompleteRegistrationMerchant />
      )}
    </>
  );
};

export default withAuth(KycTierOne, {
  roles: [roles.CONSUMER, roles.MERCHANT],
});
