import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useYupValidationResolver } from '../../../../../../hooks';

import CustomButton from '../../../../../atoms/Button';
import CustomSelect from '../../../../../atoms/Select';

interface FormTypes {
  account_typology: string;
}

type Props = {
  nextStep: () => void;
  formInitialData: FormTypes;
  updateFormData: any;
};

const SelectAccountTypology: React.FC<Props> = ({
  nextStep,
  formInitialData,
  updateFormData,
}) => {
  const { t } = useTranslation();

  const dataForm = {
    initialValues: formInitialData,
    validationSchema: Yup.object({
      account_typology: Yup.string().required(
        t('validation.error_field_is_required')
      ),
    }),
  };

  const { initialValues, validationSchema } = dataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formState;

  const onSubmit = (data: any) => {
    updateFormData(data);
    nextStep();
  };

  const isError = !isEmpty(errors);

  return (
    <div>
      <h4 className="mb-5 text-sm font-gotham-bold text-primary">
        {t('subscription_step_zero')}
      </h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:flex md:justify-between md:flex-wrap">
          <div className="w-full">
            <label
              htmlFor="account_typology"
              className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
            >
              {t('account_typology')}
            </label>
            <Controller
              control={control}
              name="account_typology"
              render={({ field: { onBlur, onChange, ref, value } }) => (
                <div className="relative mt-2">
                  <CustomSelect
                    value={[{ label: 'Seleziona...', id: value }]}
                    onBlur={onBlur}
                    onChange={onChange}
                    controlRef={ref}
                    options={[
                      { label: t('subscription_select_one'), id: 'owner' },
                      {
                        label: t('subscription_select_two'),
                        id: 'legal_representative',
                      },
                      {
                        label: t('subscription_select_three'),
                        id: 'delegate',
                      },
                    ]}
                    error={errors.account_typology}
                  />
                </div>
              )}
            />
            <div className="min-h-[2rem] mt-2">
              {errors.account_typology && (
                <p className="text-xs text-error">
                  {errors.account_typology.message}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="max-w-sm mx-auto mt-5">
          <CustomButton
            label={t('next_step')}
            disabled={isError}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default SelectAccountTypology;
