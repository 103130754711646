import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'react-use';
import { Copy } from '../../../assets/icons';

type Props = {
  valueToCopy: string;
  iconColor?: string;
  textColor?: string;
};

const ShowCopy: React.FC<Props> = ({ valueToCopy, iconColor, textColor }) => {
  const { t } = useTranslation();
  const [{ error }, copyToClipboard] = useCopyToClipboard();

  const slicedValue =
    valueToCopy.slice(0, 4).concat('....') + valueToCopy.slice(38);

  const handleClick = (event: any) => {
    event.stopPropagation();
    copyToClipboard(valueToCopy);
    if (!error) {
      toast.success(t('copy_succesfull'));
    }
  };

  return (
    <div className="flex text-sm font-gotham-bold">
      <p className={`text-${textColor}`}>{slicedValue}</p>

      <div
        role="button"
        tabIndex={0}
        className="ml-2"
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <Copy className={`w-4 h-4 fill-current text-${iconColor}`} />
      </div>
    </div>
  );
};

ShowCopy.defaultProps = {
  iconColor: 'white',
  textColor: 'white',
};

export default ShowCopy;
