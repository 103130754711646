import { ethers } from 'ethers';
import { BytesLike, Hexable } from 'ethers/lib/utils';
import { Timestamp } from 'firebase/firestore';
import { mitToken } from '../contracts';
import { twTheme } from './config';
import {
  application,
  merchantDataToFillOutToBeVerified,
  provider,
  tokenToEurConversionRate,
} from './constants';
import { Consumer, Merchant } from '../store/user/types';

const hexToLottieColorFormat = (
  color: string,
  opacity: number = 1
): number[] => {
  if (color[0] === '#') {
    color = color.slice(1);
  }

  const hexSplit: RegExpMatchArray | null = color.match(/.{2}/g);

  let lottieColor: number[] = [];

  if (hexSplit) {
    lottieColor = hexSplit.map(
      (hexOfTwoChar: string) =>
        (parseInt(hexOfTwoChar[0], 16) * 16 + parseInt(hexOfTwoChar[1], 16)) /
        255
    );

    lottieColor.push(opacity);
  }

  return lottieColor;
};

const color = (color: string) => twTheme.colors[application][color];
const screen = (screen: string) => twTheme.screens[screen];

const numberToNumberWithCommaDecimals = (number: string) =>
  number.replace(/\./g, ',');

const numberWithCommaDecimalsToNumber = (number: string) =>
  number.replace(/,/g, '.');

const formatEur = (amount: number) =>
  `${numberToNumberWithCommaDecimals(amount.toFixed(2))}€`;

const formatToken = (amount: number, withDecimals?: boolean) =>
  `${numberToNumberWithCommaDecimals(
    withDecimals ? amount.toFixed(2).toString() : amount.toString()
  )}`;

const fromTokenToEur = (amount: number) => amount * tokenToEurConversionRate;

const fromEurToToken = (amount: number) => amount / tokenToEurConversionRate;

const lowerCaseFirstLetter = (string: string) =>
  string.charAt(0).toLowerCase() + string.slice(1);

const getWalletBalance = async (address: string): Promise<number> => {
  const contract = new ethers.Contract(
    mitToken.contract_address,
    mitToken.abi,
    provider
  );

  const balance = await contract.balanceOf(address);

  return Number(ethers.utils.formatEther(balance));
};

const signMessage = async (signer: any, address: string) => {
  const etherHash: number | BytesLike | Hexable = await new Promise((resolve) =>
    resolve(
      ethers.utils.solidityKeccak256(['string', 'bytes'], ['address:', address])
    )
  );

  const signedMessage = await signer.signMessage(
    ethers.utils.arrayify(etherHash)
  );

  return signedMessage;
};

const decodePaymentCode = (paymentCode: string) => {
  const params = paymentCode.split('?');
  const eachParamsArr = params[1] && params[1].split('&');

  const obj: { [key: string]: string } = {};

  if (eachParamsArr && eachParamsArr.length) {
    eachParamsArr.forEach((param) => {
      const keyValuePair = param.split('=');
      const key = keyValuePair[0];
      const value = keyValuePair[1];

      obj[key] = value;
    });
  }

  return obj;
};

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const getProfileCompletionPercentage = (profile: Consumer | Merchant) => {
  const fields = merchantDataToFillOutToBeVerified.map((key) => profile[key]);
  const filledOutFields = fields.filter((field) => !!field);

  const numberOfFields = merchantDataToFillOutToBeVerified.length;
  const numberOfFilledOutFields = filledOutFields.length;

  return Math.trunc((numberOfFilledOutFields / numberOfFields) * 100);
};

const firestoreTimestampToDate = (timestamp: Timestamp) =>
  new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);

export {
  hexToLottieColorFormat,
  color,
  screen,
  numberToNumberWithCommaDecimals,
  numberWithCommaDecimalsToNumber,
  formatEur,
  formatToken,
  fromTokenToEur,
  fromEurToToken,
  lowerCaseFirstLetter,
  getWalletBalance,
  signMessage,
  decodePaymentCode,
  toBase64,
  getProfileCompletionPercentage,
  firestoreTimestampToDate,
};
