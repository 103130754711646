import { PayloadAction } from '@reduxjs/toolkit';
import { UserState, Consumer, Merchant, Wallet } from './types';
import { userInitialState } from './user.slice';

export const userReducer = {
  updateRole: {
    reducer: (
      state: UserState,
      action: PayloadAction<{
        role: string;
      }>
    ): void => {
      const { role } = action.payload;

      state.role = role;
    },
    prepare: (role: string) => ({
      payload: {
        role,
      },
    }),
  },

  updateAuthToken: {
    reducer: (
      state: UserState,
      action: PayloadAction<{
        authToken: string;
        uid: string;
      }>
    ): void => {
      const { authToken, uid } = action.payload;

      state.authToken = authToken;
      state.privateProfile.uid = uid;
    },
    prepare: (authToken: string, uid: string) => ({
      payload: {
        authToken,
        uid,
      },
    }),
  },

  setWallet: {
    reducer: (
      state: UserState,
      action: PayloadAction<{
        wallet: Wallet;
      }>
    ): void => {
      const { wallet } = action.payload;

      state.wallet = wallet;
    },
    prepare: (wallet: Wallet) => ({
      payload: {
        wallet,
      },
    }),
  },

  updateEmail: {
    reducer: (
      state: UserState,
      action: PayloadAction<{
        email: string;
      }>
    ): void => {
      const { email } = action.payload;

      state.email = email;
    },
    prepare: (email: string) => ({
      payload: {
        email,
      },
    }),
  },

  updatePrivateProfile: {
    reducer: (
      state: UserState,
      action: PayloadAction<{
        privateProfile: Consumer | Merchant;
      }>
    ): void => {
      const { privateProfile } = action.payload;

      state.privateProfile = privateProfile;
    },
    prepare: (privateProfile: Consumer | Merchant) => ({
      payload: {
        privateProfile,
      },
    }),
  },

  updateProfilePhoto: {
    reducer: (
      state: UserState,
      action: PayloadAction<{
        profilePhoto: string;
      }>
    ): void => {
      const { profilePhoto } = action.payload;

      state.profilePhoto = profilePhoto;
    },
    prepare: (profilePhoto: string) => ({
      payload: {
        profilePhoto,
      },
    }),
  },

  acceptCookies: (state: UserState) => {
    state.areCookiesAccepted = true;

    return state;
  },

  logout: (state: UserState) => {
    state = userInitialState;

    return state;
  },

  setAvailableOffers: (
    state: UserState,
    action: PayloadAction<{
      availableOffers: any;
    }>
  ) => {
    const { payload } = action;

    const { availableOffers } = payload;

    state.availableOffers = availableOffers;
    return state;
  },

  setRedeemedOffers: (
    state: UserState,
    action: PayloadAction<{
      redeemedOffers: any;
    }>
  ) => {
    const { payload } = action;

    const { redeemedOffers } = payload;

    state.redeemedOffers = redeemedOffers;
    return state;
  },
};
