import * as React from 'react';

const HomeMobileOverlay = ({ primaryColor }: { primaryColor: string }) => (
  <svg viewBox="0 0 851 904" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M407.26 0C511.817 219.556 552.166 405.54 528.306 557.953C492.515 786.572 57.199 904 148.577 904C209.495 904 443.636 903.666 851 902.997V0H407.26Z"
      fill="#EDE823"
    />
    <path
      d="M678.51 714.328C628.283 654.307 524.726 599.195 477.928 661.736C384.605 786.454 225.296 867.209 0 904H851V572C817.976 764.838 760.48 812.281 678.51 714.328Z"
      fill={primaryColor}
    />
  </svg>
);

export default HomeMobileOverlay;
