import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  withAuth,
  useStorage,
  useUpdateTier,
  useAppSelector,
} from '../../../../../hooks';
import { roles } from '../../../../../imports/constants';
import CustomButton from '../../../../atoms/Button';
import FloatingDialog from '../../../../molecules/FloatingDialog';
import StepThree from '../../UploadIdentityDoc';
import SelectAccountTypology from './SelectAccountTypology';
import StepFour from './StepFour';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import RegistrationStepper from '../../../../molecules/Stepper';
import { UserState } from '../../../../../store/user/types';

const CompleteRegistrationMerchant: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { updateTier } = useUpdateTier();

  const {
    privateProfile: { uid },
    email,
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const { upload, uploadLoader } = useStorage(`merchants/${uid}/kwc/tier_one`);

  const [activeStep, setActiveStep] = useState(0);
  const [stepZeroData, setStepZeroData] = useState({
    account_typology: '',
  });

  const [stepOneData, setStepOneData] = useState({
    firstName: '',
    lastName: '',
    email,
    birthDate: null,
    birthplace: '',
    city: '',
    country: '',
    district: '',
    cap: '',
    streetAddress: '',
    streetNumber: '',
    phone: '',
  });

  const [stepTwoData, setStepTwoData] = useState({
    document_type: '',
    document_number: '',
    document_release: null,
    document_release_entity: '',
    document_expiry_date: null,
    citizenship: '',
    fiscalCode: '',
    job: '',
    economicActivity: '',
    pec: '',
  });

  const [documents, setDocuments] = useState({
    document_photo_front: undefined,
    document_photo_back: undefined,
    profile_photo: undefined,
  });

  const incrementStep = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const decrementStep = () => {
    setActiveStep((prevState) => prevState - 1);
  };

  const updateDataOnFirestore = async (data: any) => {
    const tierData = {
      ...stepOneData,
      identificationDocument: {
        type: stepTwoData.document_type,
        number: stepTwoData.document_number,
        issuingDate: stepTwoData.document_release,
        issuingEntity: stepTwoData.document_release_entity,
        expirationDate: stepTwoData.document_expiry_date,
      },
      citizenship: stepTwoData.citizenship,
      fiscalCode: stepTwoData.fiscalCode,
      job: stepTwoData.job,
      pec: stepTwoData.pec,
      business: { ...data, economicActivity: stepTwoData.economicActivity },
    };

    await updateTier('merchants', 'tierOne', tierData);

    incrementStep();
  };

  const updateAndIncreaseStep = async (data: any) => {
    if (uploadLoader) return;
    await upload({ document_photo_back: data.document_photo_back });
    await upload({ document_photo_front: data.document_photo_front });
    await upload({ profile_photo: data.profile_photo });
    incrementStep();
  };

  const activeTier = () => {
    switch (activeStep) {
      case 1:
        return (
          <StepOne
            nextStep={incrementStep}
            formInitialData={stepOneData}
            updateFormData={setStepOneData}
          />
        );
      case 2:
        return (
          <StepTwo
            prevStep={decrementStep}
            nextStep={incrementStep}
            formInitialData={stepTwoData}
            updateFormData={setStepTwoData}
          />
        );
      case 3:
        return (
          <StepThree
            prevStep={decrementStep}
            formInitialData={{
              document_photo_back: documents.document_photo_back
                ? documents.document_photo_back
                : '',
              document_photo_front: documents.document_photo_front
                ? documents.document_photo_front
                : '',
              profile_photo: documents.profile_photo
                ? documents.profile_photo
                : '',
            }}
            updateFormData={updateAndIncreaseStep}
            files={documents}
            setFiles={setDocuments}
          />
        );
      case 4:
        return (
          <StepFour
            prevStep={decrementStep}
            nextStep={incrementStep}
            updateFormData={updateDataOnFirestore}
          />
        );
      case 5:
      case 6:
        return (
          <div>
            <h4 className="mb-5 text-2xl text-center font-gotham-bold text-primary">
              {t('subscription_completed')}
            </h4>
            <p className="my-10 text-center">{t('kyc_completed_message')}</p>
            <div className="max-w-sm mx-auto mt-5">
              <CustomButton
                label={t('back_to_wallet')}
                primaryAction={() => navigate(t('paths.home'))}
              />
            </div>
          </div>
        );
      default:
        return (
          <SelectAccountTypology
            nextStep={() => setActiveStep((prevState) => prevState + 1)}
            formInitialData={stepZeroData}
            updateFormData={() => setStepZeroData}
          />
        );
    }
  };

  return (
    <div className="pt-14">
      {activeStep <= 4 && (
        <h1 className="px-8 text-2xl text-center font-gotham-bold text-primary-text xs:px-14 sm:px-20 md:px-8">
          {t('complete_registration_step_one')}
        </h1>
      )}
      {activeStep > 0 && activeStep <= 4 && (
        <RegistrationStepper
          currentStep={activeStep}
          labels={[
            t('subscription_step_one'),
            t('subscription_step_two'),
            t('subscription_step_three'),
            t('subscription_step_fourth'),
          ]}
        />
      )}
      <div className="md:mt-16 md:mb-20">
        <FloatingDialog>{activeTier()}</FloatingDialog>
      </div>
    </div>
  );
};

export default withAuth(CompleteRegistrationMerchant, {
  roles: [roles.MERCHANT],
});
