import { collection, getDocs, query, DocumentData } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';

import { firestore } from '../../firebase';
import { PublicData } from './types';

const formatProfile = async (publicProfileData: DocumentData) => {
  const storage = getStorage();
  const fileRef = ref(
    storage,
    `${publicProfileData.userType}s/${publicProfileData.id}/profile_photo.jpg`
  );

  let profilePhoto = '';

  const usersDirectoryList = await listAll(
    ref(storage, `${publicProfileData.userType}s/${publicProfileData.id}`)
  );

  const fileExist = !!usersDirectoryList.items.find(
    (item) => item.name === 'profile_photo.jpg'
  );

  if (fileExist) {
    const url = await getDownloadURL(fileRef);
    profilePhoto = url;
  }

  return {
    ...publicProfileData,
    profilePhoto,
  } as PublicData;
};

// Functions that fetch firestore data
export const getFormattedProfiles = async () => {
  const requestsRef = collection(firestore, 'publicProfiles');

  const requestsQuery = query(requestsRef);

  const requestsSnapshot = await getDocs(requestsQuery);

  const profilesData = requestsSnapshot.docs.map((doc) => {
    const formattedUser = { id: doc.id, ...doc.data() };
    return formattedUser;
  });

  const formattedProfiles = Promise.all(
    profilesData.map(async (profileData: DocumentData) => {
      const formattedProfile = await formatProfile(profileData);
      return formattedProfile;
    })
  );

  return formattedProfiles;
};
