import React, { useState } from 'react';
import { PhoneInput, COUNTRIES } from 'baseui/phone-input';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

import { color } from '../../../imports/utils';

const CustomPhoneInput = ({
  name,
  onBlur,
  onChange,
  inputRef,
  value,
  error,
  disabled,
}: {
  name?: string;
  onBlur?: any;
  onChange?: any;
  inputRef?: any;
  value: string;
  error?: FieldError | undefined;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState<any>(COUNTRIES.IT);

  return (
    <PhoneInput
      id={name}
      name={name}
      onBlur={onBlur}
      disabled={disabled}
      country={country}
      onCountryChange={({ option }) => setCountry(option)}
      onTextChange={onChange}
      inputRef={inputRef}
      mapIsoToLabel={(iso: string) => t(`countries:${iso}`)}
      overrides={{
        Input: {
          props: {
            overrides: {
              Root: {
                style: () => ({
                  borderRightColor: 'transparent',
                  borderLeftColor: 'transparent',
                  borderTopColor: 'transparent',
                  borderBottomColor: `${color(
                    !error ? 'primary' : 'error'
                  )} !important`,
                  backgroundColor: 'transparent',
                  borderBottomWidth: `0.125rem`,
                }),
              },
              Input: {
                style: () => ({
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: !error ? '0.25rem' : '1.75rem',
                  backgroundColor: 'transparent',
                  color: color('gray'),
                  fontFamily: 'GothamBookRnd',
                }),
              },
              InputContainer: {
                style: () => ({
                  backgroundColor: 'transparent',
                  color: color('gray'),
                  fontFamily: 'GothamBookRndBold',
                }),
              },
            },
          },
        },
        CountrySelect: {
          props: {
            overrides: {
              Root: {
                style: () => ({
                  width: '3.5rem',
                }),
              },
              ValueContainer: {
                style: () => ({
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: '0px',
                }),
              },
              ControlContainer: {
                style: () => ({
                  borderBottomWidth: 0,
                  borderTopWidth: 0,
                }),
              },
            },
          },
        },
      }}
      text={value}
    />
  );
};

CustomPhoneInput.defaultProps = {
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  inputRef: undefined,
  error: undefined,
  disabled: false,
};

export default CustomPhoneInput;
