import { collection, onSnapshot, QuerySnapshot } from 'firebase/firestore';
import { createListenerMiddleware, Unsubscribe } from '@reduxjs/toolkit';
import { firestore } from '../../firebase';
import { PublicData } from './types';

import { updateAuthToken, logout } from '../user/user.slice';
import {
  reset,
  addPublicProfile,
  fetchPublicProfiles,
  updatePublicProfile,
} from './publicProfiles.slice';

const publicProfilesMiddleware = createListenerMiddleware();

let unsubPublicProfiles: Unsubscribe;

publicProfilesMiddleware.startListening({
  actionCreator: updateAuthToken,
  effect: async (_, listenerApi) => {
    listenerApi.dispatch(fetchPublicProfiles());
  },
});

publicProfilesMiddleware.startListening({
  actionCreator: fetchPublicProfiles.fulfilled,
  effect: async (action, listenerApi) => {
    // Firestore listeners
    unsubPublicProfiles = onSnapshot(
      collection(firestore, 'publicProfiles'),
      (snapshot: QuerySnapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            // Ignore first cycle of added documents
            if (
              !action.payload.find(
                (publicProfile) => publicProfile.id === change.doc.id
              )
            ) {
              const newDocData = change.doc.data();
              const newDoc = {
                id: change.doc.id,
                ...newDocData,
              } as PublicData;
              listenerApi.dispatch(addPublicProfile(newDoc));
            }
          } else if (change.type === 'modified') {
            const newDocData = change.doc.data();
            const newDoc = {
              id: change.doc.id,
              ...newDocData,
            } as PublicData;
            listenerApi.dispatch(updatePublicProfile(newDoc));
          }
        });
      }
    );
  },
});

publicProfilesMiddleware.startListening({
  actionCreator: logout,
  effect: async (_, listenerApi) => {
    listenerApi.dispatch(reset());
    unsubPublicProfiles();
  },
});

export default publicProfilesMiddleware;
