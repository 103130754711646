import { Button, ButtonOverrides } from 'baseui/button';
import * as React from 'react';
import { Tooltip } from '../..';

const CustomButton = ({
  primaryAction,
  label,
  icon,
  disabled,
  overrides,
  type,
}: {
  primaryAction?: any;
  label: string;
  icon?: React.ReactElement | undefined;
  disabled?: boolean;
  overrides?: ButtonOverrides;
  type?: 'button' | 'reset' | 'submit' | undefined;
}) => (
  <Button
    type={type}
    overrides={{
      BaseButton: {
        style: () => ({
          width: '100%',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
          borderTopLeftRadius: '0.375rem',
          borderTopRightRadius: '0.375rem',
          borderBottomLeftRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
          fontFamily: 'GothamBookRndBold',
        }),
      },
      ...overrides,
    }}
    onClick={primaryAction && primaryAction}
    disabled={disabled}
  >
    {!icon ? (
      label
    ) : (
      <Tooltip content={<p className="text-white">{label}</p>}>{icon}</Tooltip>
    )}
  </Button>
);

CustomButton.defaultProps = {
  primaryAction: undefined,
  icon: undefined,
  disabled: false,
  overrides: {},
  type: undefined,
};

export default CustomButton;
