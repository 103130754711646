import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton, CustomInput } from '../..';
import { Close } from '../../../assets/icons';
import { useApp } from '../../../context/app.context';

const AddDiscountDialog = ({ primaryAction }: { primaryAction: any }) => {
  const { t } = useTranslation();
  const [discountCode, setDiscountCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { dispatch } = useApp();

  const closeDialog = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
  };

  return (
    <div className="p-8">
      <header className="flex items-center justify-between">
        <h2 className="text-[1.4rem] text-gray font-gotham-bold">
          {t('add_discount')}
        </h2>
        <button
          type="button"
          onClick={closeDialog}
          className="p-2 duration-300 ease-in rounded-full hover:bg-light-gray"
        >
          <Close className="w-5 h-5 fill-current text-gray" />
        </button>
      </header>
      <div className="mt-4">
        <div className="relative mt-4">
          <CustomInput
            value={discountCode}
            placeholder={t('discount_code')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDiscountCode(e.target.value)
            }
          />
        </div>
      </div>
      <div className="max-w-sm mx-auto mt-16">
        <CustomButton
          label={t('add').toUpperCase()}
          primaryAction={async () => {
            setIsLoading(true);
            await primaryAction(discountCode);
            setIsLoading(false);
          }}
          disabled={discountCode === '' || isLoading}
        />
      </div>
    </div>
  );
};

export default AddDiscountDialog;
