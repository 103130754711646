import React, { useState } from 'react';
import { Accordion, AccordionOverrides, SharedProps } from 'baseui/accordion';
import { isEmpty } from 'lodash';
import { motion } from 'framer-motion';

import { Chevron } from '../../../assets/icons';

const CustomAccordion = ({
  overrides,
  chevronIconColor,
  children,
}: {
  overrides: AccordionOverrides<SharedProps>;
  chevronIconColor: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion
      overrides={{
        ToggleIcon: () => (
          <motion.div
            initial={false}
            animate={isOpen ? 'open' : 'closed'}
            variants={{
              closed: {
                rotate: [180, 0],
              },
              open: {
                rotate: [0, 180],
              },
            }}
            transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
            className="ml-4 mr-auto"
          >
            <Chevron className={`w-6 h-6 fill-current ${chevronIconColor}`} />
          </motion.div>
        ),
        ...overrides,
      }}
      onChange={({ expanded }) => setIsOpen(!isEmpty(expanded))}
    >
      {children}
    </Accordion>
  );
};

export default CustomAccordion;
