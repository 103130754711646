import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  tabs: string[];
  activeTab: string;
  setActiveTab: any;
};

const Tabs: React.FC<Props> = ({ tabs, activeTab, setActiveTab }) => {
  const { t } = useTranslation();

  return (
    <ul className="flex flex-col flex-wrap pl-0 mb-6 mt-4 list-none border-b-0 nav nav-tabs md:flex-row">
      {tabs.map((tab) => (
        <li className="flex-auto text-center nav-item" key={tab}>
          <a
            href={`#${tab}-tab`}
            className={`uppercase border-x-0 border-t-0 border-b-2 px-6 py-3 my-2 font-gotham-bold flex justify-center ${
              activeTab === tab
                ? 'border-primary text-primary'
                : 'border-transparent text-light-gray-variant'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {t(tab)}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
