import React from 'react';
import { signOut } from 'firebase/auth';

import { useTranslation } from 'react-i18next';
import { Panel } from 'baseui/accordion';
import { useMedia } from 'react-use';
import { Link, useLocation } from 'react-router-dom';

import { CustomAccordion, CustomPopover, ProfilePhoto } from '../..';
import { logout } from '../../../store/user/user.slice';
import { navigationItemsKeys, navigationItems } from '../../../imports/config';
import { screen } from '../../../imports/utils';
import ShowCopy from '../../atoms/ShowCopy';
import { UserState } from '../../../store/user/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import { auth } from '../../../firebase';

const Navigation = ({ isExternalPage }: { isExternalPage?: boolean }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    privateProfile: { tierOne },
    profilePhoto,
    role,
    wallet,
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const isDesktop = useMedia(`(min-width: ${screen('md')})`);

  const dispatch = useAppDispatch();

  const logOut = () => {
    signOut(auth).then(() => {
      dispatch(logout());
    });
  };

  let userInfoToDisplay = '';
  if (role === 'merchant' && tierOne && 'business' in tierOne) {
    userInfoToDisplay = tierOne?.business?.businessName;
  } else if (tierOne) {
    userInfoToDisplay = `${tierOne?.firstName} ${tierOne?.lastName}`;
  }

  return (
    <nav>
      <ul className="flex-row-reverse items-center md:flex">
        {role !== 'guest' && isDesktop && (
          <li className="px-8 my-4 text-xl xs:px-14 sm:px-20 md:px-0 md:my-0 md:mx-4 font-gotham-bold">
            <button
              type="button"
              onClick={logOut}
              className="w-full py-2 text-left"
            >
              {t('logout')}
            </button>
          </li>
        )}
        {navigationItems[role as keyof typeof navigationItems].map(
          (item: string) => {
            const isAccordion = !!navigationItemsKeys[item]?.items;
            const path = navigationItemsKeys[item]?.path;
            const Icon:
              | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
              | undefined = navigationItemsKeys[item]?.icon;

            const subItemsOnRoles:
              | {
                  [key: string]: {
                    label: string;
                    path: string;
                    icon: React.FunctionComponent<
                      React.SVGProps<SVGSVGElement>
                    >;
                  }[];
                }
              | boolean = isAccordion && {
              consumer: navigationItemsKeys[item]?.items!.consumer,
              merchant: navigationItemsKeys[item]?.items!.merchant,
            };

            const isActive = navigationItemsKeys[item]?.activePaths
              ?.map((path) => t(path))
              .includes(location.pathname);

            return !isAccordion ? (
              <li
                className="px-8 my-4 text-xl xs:px-14 sm:px-20 md:px-0 md:my-0 md:mx-4 font-gotham-bold"
                key={navigationItemsKeys[item]?.label}
              >
                <Link
                  to={path ? t(path) : '#'}
                  className={`flex items-center py-2 rounded-lg md:ease-linear md:p-2 ${
                    isActive ? 'md:bg-primary-hover' : 'md:bg-none'
                  } ${
                    role !== 'guest'
                      ? 'md:hover:bg-primary-hover'
                      : 'md:hover:bg-NONE'
                  } md:transition md:duration-300`}
                >
                  <span>{t(navigationItemsKeys[item]?.label)}</span>
                  {Icon && isDesktop && (
                    <Icon
                      className={`w-7 h-7 ${
                        !isExternalPage ? 'text-white' : 'text-gray'
                      } fill-current md:ml-2`}
                    />
                  )}
                </Link>
              </li>
            ) : (
              <li
                className="my-4 md:my-0 md:mx-4"
                key={navigationItemsKeys[item].label}
              >
                {!isDesktop ? (
                  <CustomAccordion
                    overrides={{
                      Content: {
                        style: () => ({
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          backgroundColor: 'transparent',
                        }),
                      },
                      Header: {
                        style: () => ({
                          paddingTop: 0,
                          paddingRight: '0',
                          paddingBottom: 0,
                          paddingLeft: '0',
                          backgroundColor: 'transparent',
                        }),
                      },
                      PanelContainer: {
                        style: () => ({ border: 0 }),
                      },
                    }}
                    chevronIconColor={
                      !isExternalPage ? 'text-white' : 'text-gray'
                    }
                  >
                    <Panel
                      title={
                        <div
                          className={`py-2 pl-8 text-xl ${
                            !isExternalPage ? 'text-white' : 'text-gray'
                          } xs:pl-14 sm:pl-20 font-gotham-bold`}
                        >
                          {t(navigationItemsKeys[item].label)}
                        </div>
                      }
                    >
                      <ul>
                        {subItemsOnRoles &&
                          subItemsOnRoles[role].map((subItem) => {
                            const IconSubItem = subItem.icon;

                            return (
                              <li
                                key={subItem.label}
                                className={`px-8 mt-1 text-base ${
                                  !isExternalPage ? 'text-white' : 'text-gray'
                                } xs:px-14 sm:px-20 ${
                                  !isExternalPage
                                    ? 'bg-primary-variant'
                                    : 'bg-light-gray-variant'
                                } font-gotham`}
                              >
                                <Link
                                  to={t(subItem.path)}
                                  className="flex items-center py-2"
                                >
                                  <IconSubItem
                                    className={` w-6 h-6 ${
                                      !isExternalPage
                                        ? 'text-white'
                                        : 'text-gray'
                                    } ${
                                      subItem.label === 'personal_data' ||
                                      subItem.label === 'manage_discounts' ||
                                      subItem.label === 'show_qrcode'
                                        ? 'stroke-current'
                                        : 'fill-current'
                                    }`}
                                  />
                                  <span className="ml-2">
                                    {t(subItem.label)}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </Panel>
                  </CustomAccordion>
                ) : (
                  <CustomPopover
                    content={
                      <ul className="w-64">
                        {subItemsOnRoles &&
                          subItemsOnRoles[role].map((subItem) => {
                            const IconSubItem = subItem.icon;

                            return (
                              <li
                                key={subItem.label}
                                className="px-6 py-1 text-base rounded-b text-gray font-gotham hover:bg-primary-lighter-hover first:rounded-t"
                              >
                                <Link
                                  to={t(subItem.path)}
                                  className="flex items-center w-full py-2"
                                >
                                  <IconSubItem
                                    className={` w-6 h-6 text-primary ${
                                      subItem.label === 'personal_data' ||
                                      subItem.label === 'manage_discounts'
                                        ? 'stroke-current'
                                        : 'fill-current'
                                    }`}
                                  />
                                  <span className="ml-2">
                                    {t(subItem.label)}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    }
                  >
                    {navigationItemsKeys[item].label !== 'profile' ? (
                      <p
                        className={`p-2 text-xl text-white transition duration-300 ease-linear rounded-lg font-gotham-bold ${
                          isActive ? 'bg-primary-hover' : 'bg-none'
                        } ${
                          role !== 'guest'
                            ? 'hover:bg-primary-hover'
                            : 'hover:bg-NONE'
                        }`}
                      >
                        {t(navigationItemsKeys[item].label)}
                      </p>
                    ) : (
                      <div className="grid grid-cols-3 p-2 border-2 rounded-xl">
                        <div className="w-12 h-12">
                          <ProfilePhoto
                            image={profilePhoto}
                            isActive={isActive}
                          />
                        </div>
                        <div className="grid col-span-2 grid-rows-2">
                          <div className="flex">{userInfoToDisplay}</div>
                          <ShowCopy valueToCopy={wallet.address} />
                        </div>
                      </div>
                    )}
                  </CustomPopover>
                )}
              </li>
            );
          }
        )}
        {role !== 'guest' && !isDesktop && (
          <li className="w-full px-8 mt-16 mb-4 text-xl xs:px-14 sm:px-20 font-gotham-bold">
            <button type="button" onClick={logOut} className="py-2">
              {t('logout')}
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

Navigation.defaultProps = {
  isExternalPage: false,
};

export default Navigation;
