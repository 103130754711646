import { useState, useEffect } from 'react';

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState('');

  const updateOrientation = (e: any) => {
    let orientation = 'portrait';

    if (e.matches === true) {
      orientation = 'portrait';
    } else {
      orientation = 'landscape';
    }

    setOrientation(orientation);
  };

  useEffect(() => {
    const portrait = window.matchMedia('(orientation: portrait)');

    portrait.addEventListener('change', updateOrientation);
    return () => {
      portrait.removeEventListener('change', updateOrientation);
    };
  }, []);

  return orientation;
};

export default useScreenOrientation;
