import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';

const useStorage = (uploadPath?: string) => {
  const storage = getStorage();

  const [uploadLoader, setUploadLoader] = useState(false);
  const [returnPathLoader, setReturnPathLoader] = useState(false);

  const upload = async (files: any) => {
    setUploadLoader(true);
    await Promise.all(
      Object.keys(files).map((key) => {
        const storageRef = ref(storage, `${uploadPath}/${key}.jpg`);
        return uploadBytes(storageRef, files[key]);
      })
    );
    setUploadLoader(false);
  };

  const getFilePath = async (filePath: string) => {
    setReturnPathLoader(true);
    const storage = getStorage();
    const fileRef = ref(storage, filePath);

    let path = null;

    try {
      path = await getDownloadURL(fileRef);
    } catch {
      path = '';
    }

    setReturnPathLoader(false);
    return path;
  };

  return { upload, uploadLoader, getFilePath, returnPathLoader };
};

export default useStorage;
