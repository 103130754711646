import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import companyTypesEN from './imports/locales/en/companyTypes.json';
import countriesEN from './imports/locales/en/countries.json';
import translationEN from './imports/locales/en/translation.json';
import companyTypesIT from './imports/locales/it/companyTypes.json';
import countriesIT from './imports/locales/it/countries.json';
import translationIT from './imports/locales/it/translation.json';

const resources = {
  it: {
    translation: translationIT,
    countries: countriesIT,
    companyTypes: companyTypesIT,
  },
  en: {
    translation: translationEN,
    countries: countriesEN,
    companyTypes: companyTypesEN,
  },
};

const language = 'it';

i18n.use(initReactI18next).init(
  {
    resources,
    lng: language,

    interpolation: {
      escapeValue: false,
    },
  }
  // (err) => {
  //   if (err) console.error('i18n Error', err);
  // }
);

export default i18n;
