import { motion } from 'framer-motion';
import * as React from 'react';
import SimpleBar from 'simplebar-react';

const FloatingDialog = ({ children }: { children: React.ReactNode }) => (
  <motion.div
    initial={{ y: '100%' }}
    animate={{ y: 0 }}
    exit={{ y: '100%' }}
    transition={{ type: 'spring', bounce: 0.4, duration: 1 }}
    className="fixed sm:sticky sm:top-0 sm:mt-20 md:relative bottom-16 w-full md:w-[42.5rem] mx-auto bg-white shadow-primary-xl rounded-t-[2.75rem] md:rounded-[2.75rem] max-h-[calc(100%-350px)] overflow-y-scroll"
  >
    <SimpleBar
      className="w-full h-full px-8 pt-8 pb-8 md:max-h-full"
      forceVisible="y"
    >
      {children}
    </SimpleBar>
  </motion.div>
);

export default FloatingDialog;
