import * as React from 'react';
import Lottie from 'react-lottie-player';

import { loader } from '../../../assets/animations';

import { hexToLottieColorFormat, color } from '../../../imports/utils';

const Loader = () => (
  <div className="fixed top-0 left-0 w-full h-full bg-white">
    <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
      <Lottie
        animationData={loader(
          hexToLottieColorFormat(color('primary'), 1),
          hexToLottieColorFormat(color('secondary'), 1)
        )}
        loop
        play
        className="w-36 h-36"
      />
    </div>
  </div>
);

export default Loader;
