import { useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';

import { firestore } from '../../firebase';
import { UserState } from '../../store/user/types';
import useCallbackState from '../utils/useCallbackState';
import { useAppSelector } from '..';
import { getTransfers } from '../../store/transfers/transfers.slice';
import { minTokenPurchaseAmount } from '../../imports/constants';

type Volumes = {
  annualLimitVolumesBusiness: number;
  maxSingleOperationBusiness: number;
  annualLimitVolumesRetirees: number;
  maxSingleOperationRetirees: number;
  annualLimitVolumesStudents: number;
  maxSingleOperationStudents: number;
  annualLimitVolumesWorkers: number;
  maxSingleOperationWorkers: number;
  annualLimitVolumesUnemployed: number;
  maxSingleOperationUnemployed: number;
  maxSingleOperation: number;
  maxVolume: number;
};

const useLimits = () => {
  const [firebaseVolumes, setFirebaseVolumes] = useCallbackState<Volumes>({
    annualLimitVolumesBusiness: 0,
    maxSingleOperationBusiness: 0,
    annualLimitVolumesRetirees: 0,
    maxSingleOperationRetirees: 0,
    annualLimitVolumesStudents: 0,
    maxSingleOperationStudents: 0,
    annualLimitVolumesWorkers: 0,
    maxSingleOperationWorkers: 0,
    annualLimitVolumesUnemployed: 0,
    maxSingleOperationUnemployed: 0,
    maxSingleOperation: 0,
    maxVolume: 0,
  });

  const {
    privateProfile: { tier, tierOne, pendingVerificationRequest, active },
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const { list: transfers } = useAppSelector(getTransfers);

  const settingsRef = doc(firestore, 'settings', 'limits');

  const getAddressTotalVolumes = () => {
    let total = 0;
    const currentYear = new Date().getFullYear();
    const firstDay = new Date(currentYear, 0, 1).getTime() / 1000;
    const lastDay = new Date(currentYear, 11, 31).getTime() / 1000 + 86399;

    transfers.forEach((transfer) => {
      if (transfer.timestamp >= firstDay && transfer.timestamp <= lastDay) {
        total += transfer.amount / minTokenPurchaseAmount;
      }
    });
    return total;
  };

  const getLimits = async () => {
    const result = await getDoc(settingsRef);
    return result.data() as Volumes;
  };

  const checkVolumes = (operationAmount: number) => {
    const totalVolumes = getAddressTotalVolumes() + operationAmount;

    const exceeded = {
      singleOperation: false,
      volumes: false,
    };

    if (
      (pendingVerificationRequest === false && tier === 0) ||
      active === false
    ) {
      exceeded.singleOperation = true;
      exceeded.volumes = true;
      return exceeded;
    }

    if (tier === 2) {
      return exceeded;
    }

    if (tier === 1) {
      if ('business' in tierOne!) {
        exceeded.volumes =
          totalVolumes >= firebaseVolumes.annualLimitVolumesBusiness;
        exceeded.singleOperation =
          operationAmount >= firebaseVolumes.maxSingleOperationBusiness;
      } else
        switch (tierOne?.job) {
          case 'Studente':
          case 'Student': {
            exceeded.volumes =
              totalVolumes >= firebaseVolumes.annualLimitVolumesStudents;
            exceeded.singleOperation =
              operationAmount >= firebaseVolumes.maxSingleOperationStudents;
            break;
          }
          case 'Pensionato':
          case 'Retiree': {
            exceeded.volumes =
              totalVolumes >= firebaseVolumes.annualLimitVolumesRetirees;
            exceeded.singleOperation =
              operationAmount >= firebaseVolumes.maxSingleOperationRetirees;
            break;
          }
          case 'Unemployed':
          case 'Non occupato':
            exceeded.volumes =
              totalVolumes >= firebaseVolumes.annualLimitVolumesUnemployed;
            exceeded.singleOperation =
              operationAmount >= firebaseVolumes.maxSingleOperationUnemployed;
            break;
          default: {
            exceeded.volumes =
              totalVolumes >= firebaseVolumes.annualLimitVolumesWorkers;
            exceeded.singleOperation =
              operationAmount >= firebaseVolumes.maxSingleOperationWorkers;
            break;
          }
        }
    } else {
      exceeded.volumes = totalVolumes >= firebaseVolumes.maxVolume;
      exceeded.singleOperation =
        operationAmount >= firebaseVolumes.maxSingleOperation;
    }

    return exceeded;
  };

  const initiliaze = async () => {
    const limits = await getLimits();
    if (limits) {
      setFirebaseVolumes(limits);
    }
  };

  useEffect(() => {
    initiliaze();
  }, []);

  return { checkVolumes };
};

export default useLimits;
