import * as React from 'react';
import ReactDOM from 'react-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider, createTheme } from 'baseui';

import { QueryClient, QueryClientProvider } from 'react-query';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';

import './i18n';

import ContextProvider from './components/ContextProvider';

import App from './app/App';

import { baseUiTheme } from './imports/config';

import './index.css';

const engine = new Styletron();

const Theme = createTheme(baseUiTheme.primitives, baseUiTheme.overrides);

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyletronProvider value={engine}>
        <QueryClientProvider client={queryClient}>
          <BaseProvider theme={Theme}>
            <ContextProvider>
              <App />
            </ContextProvider>
          </BaseProvider>
        </QueryClientProvider>
      </StyletronProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
