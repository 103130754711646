import React, { useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useClickAway, useMedia } from 'react-use';
import SimpleBar from 'simplebar-react';

import { screen } from '../../../imports/utils';

const Dialog = ({
  children,
  handleClose,
  fromBottom,
  isBgOverlayInteractive,
}: {
  children: React.ReactNode;
  handleClose: () => void;
  fromBottom: boolean;
  isBgOverlayInteractive: boolean;
}) => {
  const ref = useRef(null);

  useClickAway(ref, () => {
    if (isBgOverlayInteractive) {
      handleClose();
    }
  });

  const isDesktop = useMedia(`(min-width: ${screen('md')})`);

  return (
    <AnimatePresence>
      <SimpleBar className="absolute z-30 w-full h-full">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex items-center justify-center w-full min-h-screen bg-black-transparent"
        >
          <motion.div
            initial={{ y: !fromBottom || isDesktop ? '-100%' : '100%' }}
            animate={{ y: 0 }}
            exit={{ y: !fromBottom || isDesktop ? '-100%' : '100%' }}
            transition={{ type: 'spring', bounce: 0.4, duration: 1 }}
            ref={ref}
            className={`bg-white z-30 ${
              !fromBottom || isDesktop
                ? 'my-8 w-[calc(100%-(2rem*2))] xs:w-[calc(100%-(3.5rem*2))] sm:w-[calc(100%-(5rem*2))] max-w-[42.5rem] min-h-[14rem] rounded-[2.75rem]'
                : 'fixed bottom-0 w-full rounded-t-[2.75rem]'
            }`}
          >
            {children}
          </motion.div>
        </motion.div>
      </SimpleBar>
    </AnimatePresence>
  );
};

export default Dialog;
