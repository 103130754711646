import React, { useState, cloneElement } from 'react';
import { nanoid } from 'nanoid';

const useTabber = (defaultValue: number, numberOfTabs: number) => {
  const [tab, setTab] = useState(defaultValue || 0);

  const goToTab = (tabNumber: number) => {
    if (tab >= 0 && tab <= numberOfTabs - 1) setTab(tabNumber);
  };

  const incrementTab = () => {
    if (tab < numberOfTabs - 1) {
      setTab((prevState) => prevState + 1);
    }
  };

  const decrementTab = () => {
    if (tab !== 0) {
      setTab((prevState) => prevState - 1);
    }
  };

  return { tab, goToTab, incrementTab, decrementTab };
};

const Tab = ({
  currentTab,
  numberOfTabs,
  goToTab,
  children,
}: {
  currentTab?: number;
  numberOfTabs?: number;
  goToTab?: (tab?: number) => void;
  children: React.ReactNode;
}) => (
  <button
    type="button"
    className="z-10 flex items-center justify-center px-6 py-2 text-center cursor-pointer text-gray"
    style={{ width: `calc(100% / ${numberOfTabs})` }}
    onClick={() => goToTab && goToTab(currentTab)}
  >
    {children}
  </button>
);

Tab.defaultProps = {
  currentTab: undefined,
  numberOfTabs: undefined,
  goToTab: undefined,
};

const Tabber = ({
  tab = 0,
  goToTab,
  children,
}: {
  tab: number;
  goToTab: (tab: number) => void;
  children: any;
}) => (
  <div className="relative flex w-full min-h-[3rem] py-3 rounded-xl bg-light-gray-variant">
    <div
      className="absolute top-0 left-0 flex items-center justify-center h-full"
      style={{
        width: `calc(100% / ${children.length})`,
        transform: `translateX(calc(${tab} * 100%))`,
        transition: 'transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55)',
      }}
    >
      <div className="w-[85%] h-[70%] rounded-xl bg-light-gray" />
    </div>
    {children &&
      children.constructor === Array &&
      children.map((child, i) => (
        <React.Fragment key={nanoid()}>
          {cloneElement(child, {
            currentTab: i,
            numberOfTabs: children.length,
            goToTab,
          })}
        </React.Fragment>
      ))}
  </div>
);

export { Tabber, Tab, useTabber };
