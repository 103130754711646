import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomButton, FloatingDialog } from '../../../..';
import { useStorage, withAuth } from '../../../../../hooks';
import { roles } from '../../../../../imports/constants';
import Stepper from '../../../../molecules/Stepper';
import StepOne from './StepOne';
import StepSecond from './StepTwo';
import StepSingle from './StepSingle';
import StepZero from './StepZero';
import { UserState } from '../../../../../store/user/types';
import useUpdateTier from '../../../../../hooks/UserFlow/useUpdateTier';
import { useAppSelector } from '../../../../../hooks/utils/reduxUtils';

const KycVerify = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { updateTier } = useUpdateTier();

  const {
    privateProfile: { uid },
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const { upload } = useStorage(`merchants/${uid}/kwc/tier_two`);

  const [step, setStep] = useState(1);
  const [showFinalBanner, setShowFinalBanner] = useState(false);

  const [kycData, setKycData] = useState({
    companyOwner: '',
  });

  const [kycOneData, setKycOneData] = useState({
    firstName: '',
    lastName: '',
    birthDate: null,
    birthplace: '',
    streetNumber: '',
    streetAddress: '',
    cap: '',
    city: '',
    district: '',
    country: '',
  });

  const [kycSecondData, setKycSecondData] = useState({
    type: '',
    number: '',
    issuingDate: null,
    issuingEntity: '',
    expirationDate: null,
    citizenship: '',
    fiscalCode: '',
    economicallyExposedPerson: false,
    documentPhotoFront: '',
    documentPhotoBack: '',
    documentPhotoUser: '',
  });

  useEffect(() => {
    if (
      step === 5 ||
      (step === 3 && kycData.companyOwner === 'registrationOne')
    ) {
      setShowFinalBanner((prevState) => !prevState);
    }
  }, [step]);

  const updateDataOnFirebaseFull = async (data: any) => {
    await upload({
      document_photo_front_delegate: kycSecondData.documentPhotoFront,
    });
    await upload({
      document_photo_back_delegate: kycSecondData.documentPhotoBack,
    });
    await upload({ profile_photo_delegate: kycSecondData.documentPhotoUser });
    await upload({ bill: data.kyc_bill_proof });
    const formattedData = { ...data };
    delete formattedData.kyc_bill_proof;

    const tierData = {
      ...formattedData,
      beneficialOwner: {
        ...kycOneData,
        identificationDocument: {
          type: kycSecondData.type,
          number: kycSecondData.number,
          issuingDate: kycSecondData.issuingDate,
          issuingEntity: kycSecondData.issuingEntity,
          expirationDate: kycSecondData.expirationDate,
        },
        citizenship: kycSecondData.citizenship,
        fiscalCode: kycSecondData.fiscalCode,
        economicallyExposedPerson: kycSecondData.economicallyExposedPerson,
      },
    };

    await updateTier('merchants', 'tierTwo', tierData);
  };

  const updateDataOnFirebaseDelegate = async (data: any) => {
    await upload({ kyc_bill_proof: data.kyc_bill_proof });
    delete data.kyc_bill_proof;
    const formattedData = { ...data };

    await updateTier('merchants', 'tierTwo', formattedData);
  };

  return (
    <div className="pt-14">
      <h1 className="px-8 text-2xl text-center font-gotham-bold text-primary-text xs:px-14 sm:px-20 md:px-8">
        {t('verify')}
      </h1>
      {kycData.companyOwner !== '' &&
        kycData.companyOwner !== 'registrationOne' &&
        step < 4 && (
          <div>
            <Stepper
              currentStep={step - 1}
              labels={[t('kyc_step_one'), t('kyc_step_two')]}
            />
          </div>
        )}
      <div className="md:mt-16 md:mb-20">
        <FloatingDialog>
          {step === 1 && (
            <StepZero
              formInitialData={kycData}
              updateFormData={setKycData}
              nextStep={() => setStep((prevState) => prevState + 1)}
            />
          )}
          {step === 2 && kycData.companyOwner !== 'registrationOne' && (
            <StepOne
              formInitialData={kycOneData}
              updateFormData={setKycOneData}
              nextStep={() => setStep((prevState) => prevState + 1)}
            />
          )}
          {step === 2 && kycData.companyOwner === 'registrationOne' && (
            <StepSingle
              updateFormData={updateDataOnFirebaseDelegate}
              nextStep={() => setStep((prevState) => prevState + 1)}
            />
          )}
          {step === 3 && kycData.companyOwner !== 'registrationOne' && (
            <StepSecond
              formInitialValues={kycSecondData}
              updateFormData={setKycSecondData}
              nextStep={() => setStep((prevState) => prevState + 1)}
              prevStep={() => setStep((prevState) => prevState - 1)}
            />
          )}
          {step === 4 && kycData.companyOwner !== 'registrationOne' && (
            <StepSingle
              updateFormData={updateDataOnFirebaseFull}
              disabledEconomicExposedPerson
              nextStep={() => setStep((prevState) => prevState + 1)}
            />
          )}
          {showFinalBanner && (
            <div>
              <h4 className="mb-5 text-2xl text-center font-gotham-bold text-primary">
                {t('verify_completed')}
              </h4>
              <p className="my-10 text-center">{t('kyc_completed_message')}</p>
              <div className="max-w-sm mx-auto mt-5">
                <CustomButton
                  label={t('back_to_wallet')}
                  primaryAction={() => navigate(t('paths.home'))}
                />
              </div>
            </div>
          )}
        </FloatingDialog>
      </div>
    </div>
  );
};

export default withAuth(KycVerify, { roles: [roles.MERCHANT] });
