import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';

import { useAppSelector } from '../utils/reduxUtils';
import { UserState } from '../../store/user/types';

const useUpdateTier = () => {
  const { t } = useTranslation();

  const [loader, setLoader] = useState<boolean>(false);

  const {
    privateProfile: { uid },
  } = useAppSelector(({ user }: { user: UserState }) => user);

  const updateTier = async (
    collectionRef: string,
    requestedTier: string,
    tierData: any
  ) => {
    if (loader) {
      toast.warning(t('update_tier_loading'));
      return;
    }

    setLoader(true);

    const docRef = doc(firestore, collectionRef, uid);

    try {
      await updateDoc(docRef, { [requestedTier]: tierData });
    } catch {
      toast.error(t('update_tier_error'));
    }

    setLoader(false);
  };

  return { updateTier, loader };
};

export default useUpdateTier;
