import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { LogoKnobsFilled } from '../../../assets/icons';

import { LogoWhite } from '../../../assets/images';

import { screen } from '../../../imports/utils';

const Footer = ({
  isFooterHidden,
  isExternalPage,
}: {
  isFooterHidden?: boolean;
  isExternalPage?: boolean;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMedia(`(min-width: ${screen('md')})`);

  return !isFooterHidden ? (
    <footer
      className={`relative bottom-0 w-full h-16 flex justify-center items-center ${
        !isExternalPage ? 'bg-primary-lighter' : 'bg-none'
      }`}
    >
      {isExternalPage && !isDesktop && (
        <LogoWhite className="absolute flex-shrink-0 mx-auto translate-y-[calc(-100%-1rem)] w-28" />
      )}
      {isExternalPage && (
        <p className="absolute px-8 text-sm text-center text-white xs:px-14 sm:px-20 md:px-8 md:right-0">
          <span>{t('rights_reserved')}</span>
          <span className="mx-2">{t('divider')}</span>
          <a target="_blank" rel="noreferrer" href={t('privacy_link')}>
            {t('privacy_and_cookie_policy')}
          </a>
        </p>
      )}
      {!isExternalPage && (
        <p className="flex flex-col items-center justify-center text-sm md:flex-row text-primary-text">
          <span>{t('rights_reserved')}</span>
          {isDesktop && <span className="mx-2">{t('divider')}</span>}
          <a target="_blank" rel="noreferrer" href={t('privacy_link')}>
            {t('privacy_and_cookie_policy')}
          </a>
          {isDesktop && (
            <>
              <span className="mx-2">{t('divider')}</span>
              <span className="flex items-center justify-center text-sm md:flex-row text-primary-text">
                <span className="pr-1">Powered by </span>
                <a target="_blank" rel="noreferrer" href={t('link_knobs')}>
                  <LogoKnobsFilled className="fill-current text-primary-text" />
                </a>
              </span>
            </>
          )}
        </p>
      )}
    </footer>
  ) : (
    <></>
  );
};

Footer.defaultProps = {
  isFooterHidden: false,
  isExternalPage: false,
};

export default Footer;
