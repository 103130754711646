import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';

import { useYupValidationResolver } from '../../../../../../hooks';
import CloseButton from '../../../../../atoms/CloseButton';
import CustomButton from '../../../../../atoms/Button';
import CustomSelect from '../../../../../atoms/Select';
import {
  annualVolumes,
  foundsProvenance,
  maxUploadedFileSize,
} from '../../../../../../imports/constants';
import { QuestionCircle } from '../../../../../../assets/icons';
import CustomCheckbox from '../../../../../atoms/Checkbox';

interface SelectProvenance {
  label: string;
  id: string;
}
interface FormTypes {
  annualVolumes: string;
  fundsProvenance: string;
  kyc_bill_proof: string;
  economicallyExposedPerson: boolean;
}

type Props = {
  nextStep: () => void;
  updateFormData: any;
  disabledEconomicExposedPerson?: boolean;
  initialDataValues?: FormTypes;
  disabledForm?: boolean;
};

const StepSingle: React.FC<Props> = ({
  nextStep,
  updateFormData,
  disabledEconomicExposedPerson,
  initialDataValues,
  disabledForm,
}) => {
  const { t } = useTranslation();
  const [documentFile, setDocumentFile] = useState<string | undefined>(
    undefined
  );

  const personalDataForm = {
    initialValues: initialDataValues,
    validationSchema: Yup.object({
      annualVolumes: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      fundsProvenance: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      kyc_bill_proof: Yup.mixed().test(
        'FILE_SIZE',
        t('file_upload_error'),
        (value) => value && value.size <= maxUploadedFileSize
      ),
    }),
  };

  const { initialValues, validationSchema } = personalDataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formState;

  const inputBillRef = useRef<HTMLInputElement>(null);

  const focusInputBill = () => {
    if (inputBillRef.current) {
      inputBillRef.current.click();
    }
  };

  const isError = !isEmpty(errors);

  const foundsProvenanceList: SelectProvenance[] = [];

  foundsProvenance.forEach((provenance) => {
    foundsProvenanceList.push({ label: t(provenance), id: provenance });
  });

  const onSubmit = (data: any) => {
    if (disabledEconomicExposedPerson) delete data.economicallyExposedPerson;
    updateFormData({
      ...data,
    });
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:justify-between md:flex-wrap">
        <div className="w-full">
          <label
            htmlFor="annualVolumes"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('annualVolumes')}
          </label>
          <Controller
            control={control}
            name="annualVolumes"
            render={({ field: { onBlur, onChange, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  onBlur={onBlur}
                  onChange={onChange}
                  value={[
                    {
                      label: t('select_placeholder'),
                      id: value,
                    },
                  ]}
                  options={annualVolumes}
                  error={errors.annualVolumes}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.annualVolumes && (
              <p className="text-xs text-error">
                {errors.annualVolumes.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="fundsProvenance"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('fundsProvenance')}
          </label>
          <Controller
            control={control}
            name="fundsProvenance"
            render={({ field: { onBlur, onChange, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  onBlur={onBlur}
                  onChange={onChange}
                  value={[
                    {
                      label: t('select_placeholder'),
                      id: value,
                    },
                  ]}
                  options={foundsProvenanceList}
                  error={errors.annualVolumes}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.annualVolumes && (
              <p className="text-xs text-error">
                {errors.annualVolumes.message}
              </p>
            )}
          </div>
        </div>
        {!disabledEconomicExposedPerson && (
          <div className="w-full">
            <Controller
              control={control}
              name="economicallyExposedPerson"
              render={({ field: { onBlur, onChange, value } }) => (
                <div className="relative mt-2">
                  <CustomCheckbox
                    onBlur={onBlur}
                    onChange={onChange}
                    checked={value}
                    error={errors.economicallyExposedPerson}
                    disabled={disabledForm}
                    overrides={{
                      Checkmark: {
                        style: () => ({
                          width: '2.5em',
                          height: '2.5em',
                          borderWidth: '0.25rem',
                          borderTopLeftRadius: '0.5rem',
                          borderTopRightRadius: '0.5rem',
                          borderBottomLeftRadius: '0.5rem',
                          borderBottomRightRadius: '0.5rem',
                          backgroundColor: '#D9D9D9',
                          borderColor: `transparent`,
                        }),
                      },
                    }}
                  >
                    {' '}
                    <label
                      htmlFor="economicallyExposedPerson"
                      className="text-sm cursor-pointer md:text-base text-gray font-gotham-bold"
                    >
                      <StatefulPopover
                        placement="topRight"
                        content={
                          <div className="p-2 text-center max-w-sm">
                            <strong className="text-center mt-8 font-gotham-bold text-sm text-[#58585A]">
                              {t('exposed_politically_person_description')}
                            </strong>
                          </div>
                        }
                        accessibilityType="tooltip"
                        triggerType={TRIGGER_TYPE.hover}
                      >
                        <div className="flex justify-center items-center">
                          <p className="pr-2">
                            {t('economicallyExposedPerson')}
                          </p>
                          <QuestionCircle />
                        </div>
                      </StatefulPopover>
                    </label>
                  </CustomCheckbox>
                </div>
              )}
            />
            <div className="min-h-[2rem] mt-2">
              {errors.economicallyExposedPerson && (
                <p className="text-xs text-error">
                  {errors.economicallyExposedPerson.message}
                </p>
              )}
            </div>
          </div>
        )}

        {!disabledForm && (
          <div className="w-full">
            <label
              htmlFor="kyc_bill_proof"
              className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
            >
              {t('kyc_bill_proof')}
              <p className="text-sm text-gray">
                {t('bill_description_upload')}
              </p>
            </label>

            <div className="mt-2">
              {!documentFile ? (
                <Controller
                  name="kyc_bill_proof"
                  control={control}
                  render={({ field: { name, onChange } }) => (
                    <div className="flex">
                      <input
                        name={name}
                        type="file"
                        accept="image/*"
                        hidden
                        ref={inputBillRef}
                        onChange={async () => {
                          if (inputBillRef.current?.files) {
                            const file = inputBillRef.current.files[0];

                            setDocumentFile(file.name);
                            onChange(file);
                          }
                        }}
                      />
                      <button
                        type="button"
                        className="text-[#B5B5B5] font-gotham-bold justify-start pl-2"
                        onClick={focusInputBill}
                      >
                        {t('click_to_upload')}
                      </button>
                    </div>
                  )}
                />
              ) : (
                <div className="grid grid-cols-2 pl-2">
                  <p className="flex items-center font-gotham-bold text-[#B5B5B5]">
                    {documentFile}
                  </p>
                  <CloseButton handleClose={() => setDocumentFile(undefined)} />
                </div>
              )}
            </div>

            <div className="min-h-[2rem] mt-2">
              {errors.kyc_bill_proof && (
                <p className="text-xs text-error">
                  {errors.kyc_bill_proof.message}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {!disabledForm && (
        <div className="max-w-sm mx-auto">
          <CustomButton
            label={t('removeLimit')}
            disabled={isError}
            type="submit"
          />
        </div>
      )}
    </form>
  );
};

StepSingle.defaultProps = {
  disabledForm: false,
  disabledEconomicExposedPerson: false,
  initialDataValues: {
    annualVolumes: '',
    fundsProvenance: '',
    kyc_bill_proof: '',
    economicallyExposedPerson: false,
  },
};

export default StepSingle;
