import { nanoid } from 'nanoid';
import React from 'react';

type Props = {
  currentStep: number;
  numberOfSteps?: number;
  showLabels?: boolean;
  labels?: string[];
};

const Stepper: React.FC<Props> = ({
  currentStep,
  numberOfSteps,
  showLabels,
  labels,
}) => (
    <>
      <div className="flex items-center justify-center">
        {labels && (
          <>
            <div className="md:w-[42.5rem] h-4 hidden md:flex justify-between">
              {labels.map((step, index) => (
                <div
                  key={step}
                  className={`${
                    currentStep >= index + 1 ? 'text-primary-text' : 'text-gray'
                  }`}
                >
                  <div className="flex items-center justify-center">
                    <div
                      className={`h-4 w-4 rounded-full border-bop bg-bop my-3 ${
                        currentStep >= index + 1 ? 'bg-primary' : 'bg-gray'
                      }`}
                    />
                  </div>
                  <div className="font-gotham-bold">{step}</div>
                </div>
              ))}
            </div>

            <div className="md:w-[42.5rem] h-4 flex justify-between md:hidden">
              <div className="grid grid-rows-2 my-6">
                <ul className="flex items-center justify-center">
                  {labels.map((value, i) => (
                    <li
                      key={nanoid()}
                      className={`w-3 h-3 mx-2 rounded-full ${
                        currentStep >= i + 1 ? 'bg-primary' : 'bg-gray'
                      }`}
                    />
                  ))}
                </ul>
                <div className="my-6 font-gotham-bold text-primary-text sm:mb-15">
                  {showLabels && labels[currentStep - 1]}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {numberOfSteps ? (
        <ul className="flex items-center justify-center">
          {Array(numberOfSteps)
            .fill(undefined)
            .map((value, i) => (
              <li
                key={nanoid()}
                className={`w-3 h-3 mx-2 rounded-full ${
                  currentStep >= i ? 'bg-primary' : 'bg-gray'
                }`}
              />
            ))}
        </ul>
      ) : (
        ''
      )}
    </>
  );

Stepper.defaultProps = {
  showLabels: true,
  numberOfSteps: 0,
  labels: [],
};

export default Stepper;
