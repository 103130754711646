import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Alert } from '../../../../../../assets/icons';
import {
  useValidation,
  useYupValidationResolver,
} from '../../../../../../hooks';
import CustomButton from '../../../../../atoms/Button';
import CustomInput from '../../../../../atoms/Input';
import CustomDatePicker from '../../../../../atoms/DatePicker';
import CustomPhoneInput from '../../../../../atoms/PhoneInput';
import CustomSelectPlacesAutocomplete from '../../../../../atoms/PlacesSelect';
import CustomSelect from '../../../../../atoms/Select';
import { worldCountries } from '../../../../../../imports/constants';

interface FormTypes {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: Date | null;
  birthplace: string;
  city: string;
  country: string;
  district: string;
  cap: string;
  streetAddress: string;
  streetNumber: string;
  phone: string;
}

type Props = {
  nextStep: () => void;
  formInitialData?: FormTypes;
  updateFormData: any;
  disabledForm?: boolean;
};

const StepOne: React.FC<Props> = ({
  nextStep,
  formInitialData,
  updateFormData,
  disabledForm,
}) => {
  const { t } = useTranslation();

  const { validatePastDate, validatePostalCode, validatePhoneNumber } =
    useValidation();

  const personalDataForm = {
    initialValues: formInitialData,
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('validation.error_field_is_required')),
      lastName: Yup.string().required(t('validation.error_field_is_required')),
      birthDate: validatePastDate()
        .required(t('validation.error_field_is_required'))
        .nullable(),
      birthplace: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      city: Yup.string().required(t('validation.error_field_is_required')),
      country: Yup.string().required(t('validation.error_field_is_required')),
      district: Yup.string().required(t('validation.error_field_is_required')),
      cap: validatePostalCode().required(
        t('validation.error_field_is_required')
      ),
      streetAddress: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      streetNumber: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      phone: validatePhoneNumber().required(
        t('validation.error_field_is_required')
      ),
    }),
  };

  const { initialValues, validationSchema } = personalDataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = formState;

  const isError = !isEmpty(errors);

  const onSubmit = (data: any) => {
    updateFormData(data);
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:justify-between md:flex-wrap">
        <div className="w-full md:w-[calc(50%-1rem)] mb-8">
          <label
            htmlFor="email"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('email_address')}
          </label>
          <div className="relative mt-2">
            <Controller
              control={control}
              name="email"
              render={({ field: { name, onBlur, onChange, ref, value } }) => (
                <div className="relative mt-2">
                  <CustomInput
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    value={value}
                    error={errors.email}
                    disabled
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="phone"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('phone_number')}
          </label>
          <div className="relative mt-2">
            <Controller
              name="phone"
              control={control}
              render={({ field: { name, onBlur, onChange, ref, value } }) => (
                <CustomPhoneInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.phone}
                  disabled={disabledForm}
                />
              )}
            />
            {errors.phone && (
              <Alert className="absolute right-0 w-5 h-5 translate-y-[calc(-50%-0.25rem)] fill-current top-1/2 text-error" />
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="firstName"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('first_name')}
          </label>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.firstName}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.firstName && (
              <p className="text-xs text-error">{errors.firstName.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="lastName"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('last_name')}
          </label>
          <Controller
            name="lastName"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.lastName}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.lastName && (
              <p className="text-xs text-error">{errors.lastName.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="birthDate"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('birth_date')}
          </label>
          <Controller
            name="birthDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="relative mt-2">
                <CustomDatePicker
                  onChange={onChange}
                  value={value ? [new Date(value)] : null}
                  error={errors.birthDate}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.birthDate && (
              <p className="text-xs text-error">{errors.birthDate.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="birthplace"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('birth_place')}
          </label>
          <Controller
            name="birthplace"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.birthplace}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.birthplace && (
              <p className="text-xs text-error">{errors.birthplace.message}</p>
            )}
          </div>
        </div>
        <div className="w-full">
          <div className="relative flex mb-7 items-center">
            <div className="flex-grow border-t-2 border-primary opacity-70 rounded-xl" />
            <span className="flex-shrink mx-4 text-primary opacity-70 font-bold">
              {t('residence')}
            </span>
            <div className="flex-grow border-t-2 border-primary opacity-70 rounded-xl" />
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="streetAddress"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('street_address')}
          </label>
          <Controller
            name="streetAddress"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.streetAddress}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.streetAddress && (
              <p className="text-xs text-error">
                {errors.streetAddress.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="streetNumber"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('street_number')}
          </label>
          <Controller
            name="streetNumber"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.streetNumber}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.streetNumber && (
              <p className="text-xs text-error">
                {errors.streetNumber.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="cap"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('cap')}
          </label>
          <Controller
            name="cap"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.cap}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.cap && (
              <p className="text-xs text-error">{errors.cap.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="city"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('city')}
          </label>
          <Controller
            name="city"
            control={control}
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelectPlacesAutocomplete
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  requestOptions={{
                    types: ['(cities)'],
                  }}
                  error={errors.city}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.city && (
              <p className="text-xs text-error">{errors.city.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="district"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('district')}
          </label>
          <Controller
            name="district"
            control={control}
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelectPlacesAutocomplete
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  requestOptions={{
                    types: ['(cities)'],
                  }}
                  error={errors.district}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.district && (
              <p className="text-xs text-error">{errors.district.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="country"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('country')}
          </label>
          <Controller
            name="country"
            control={control}
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  error={errors.country}
                  disabled={disabledForm}
                  value={[{ label: t('select_placeholder'), id: value }]}
                  options={worldCountries.map((country: string) => ({
                    label: t(`countries:${country}`),
                    id: t(`countries:${country}`, { lng: 'it' }),
                  }))}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.country && (
              <p className="text-xs text-error">{errors.country.message}</p>
            )}
          </div>
        </div>
      </div>
      {!disabledForm && (
        <div className="max-w-sm mx-auto">
          <CustomButton
            label={t('next_step')}
            disabled={isError}
            type="submit"
          />
        </div>
      )}
    </form>
  );
};

StepOne.defaultProps = {
  disabledForm: false,
  formInitialData: {
    firstName: '',
    lastName: '',
    email: '',
    birthDate: null,
    birthplace: '',
    city: '',
    country: '',
    district: '',
    cap: '',
    streetAddress: '',
    streetNumber: '',
    phone: '',
  },
};

export default StepOne;
