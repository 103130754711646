import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useYupValidationResolver } from '../../../../../../hooks';
import CustomButton from '../../../../../atoms/Button';
import CustomSelect from '../../../../../atoms/Select';

interface FormTypes {
  companyOwner: string;
}

type Props = {
  nextStep: () => void;
  formInitialData: FormTypes;
  updateFormData: any;
};

const StepZero: React.FC<Props> = ({
  nextStep,
  formInitialData,
  updateFormData,
}) => {
  const { t } = useTranslation();

  const dataForm = {
    initialValues: formInitialData,
    validationSchema: Yup.object({
      companyOwner: Yup.string().required(
        t('validation.error_field_is_required')
      ),
    }),
  };

  const { initialValues, validationSchema } = dataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formState;

  const onSubmit = (data: any) => {
    updateFormData(data);
    nextStep();
  };

  const isError = !isEmpty(errors);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {' '}
        <div className="md:flex md:justify-between md:flex-wrap">
          <div className="w-full">
            <label
              htmlFor="companyOwner"
              className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
            >
              {t('companyOwner')}
            </label>
            <Controller
              control={control}
              name="companyOwner"
              render={({ field: { onBlur, onChange, ref, value } }) => (
                <div className="relative mt-2">
                  <CustomSelect
                    value={[{ label: 'Seleziona...', id: value }]}
                    onBlur={onBlur}
                    onChange={onChange}
                    controlRef={ref}
                    options={[
                      {
                        label: t('select_kyc_registration_one'),
                        id: 'registrationOne',
                      },
                      {
                        label: t('select_kyc_shareholder'),
                        id: 'shareholder',
                      },
                      {
                        label: t('select_kyc_delegate'),
                        id: 'delegate',
                      },
                    ]}
                    error={errors.companyOwner}
                  />
                </div>
              )}
            />
            <div className="min-h-[2rem] mt-2">
              {errors.companyOwner && (
                <p className="text-xs text-error">
                  {errors.companyOwner.message}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="max-w-sm mx-auto mt-5">
          <CustomButton
            label={t('next_step')}
            type="submit"
            disabled={isError}
          />
        </div>
      </form>
    </div>
  );
};

export default StepZero;
