import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { sortBy } from 'lodash';

import { mitToken } from '../../contracts';

import { transfersReducer } from './transfers.reducer';
import { Transfers, FormattedTransfer } from './types';
import { formatTransfer } from './fetchUtils';

import { provider } from '../../imports/constants';

import { RootState } from '../store';

const mitTokenContract = new ethers.Contract(
  mitToken.contract_address,
  mitToken.abi,
  provider
);

// Define initial state
const transfersInitialState: Transfers = {
  loader: true,
  balance: 0,
  list: [],
  fromBlock: 0,
};

// State slice
export const transfersSlice = createSlice({
  name: 'transfers',
  initialState: transfersInitialState,
  reducers: transfersReducer,
  extraReducers: (builder) => {
    builder.addCase(fetchTransfers.pending, (state) => {
      state.loader = true;
    });

    builder.addCase(fetchTransfers.fulfilled, (state, { payload }) => {
      state.list =
        payload.fromBlock === 0
          ? sortBy(payload.transfers, ['timestamp'])
          : state.list.concat(sortBy(payload.transfers, ['timestamp']));

      state.loader = false;
    });

    builder.addCase(fetchTransfers.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(fetchBalance.fulfilled, (state, { payload }) => {
      state.balance = payload;
    });
  },
});

// Action creators
export const {
  actions: { setLoader, addTransfer, setFromBlock },
} = transfersSlice;

// Getters
export const getTransfers = (state: RootState): Transfers => state.transfers;

// Async thunks
export const fetchTransfers = createAsyncThunk<
  { address: string; transfers: FormattedTransfer[]; fromBlock: number },
  void
>(
  'transfers/fetchTransfers',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      transfers: { fromBlock },
      user: {
        wallet: { address },
      },
    } = getState() as {
      user: { wallet: { address: string } };
      transfers: { fromBlock: number };
    };

    // Define events filters
    const incomingTransfersEvents = mitTokenContract.filters.Transfer(
      null,
      address
    );
    const outcomingTransfersEvents = mitTokenContract.filters.Transfer(address);

    try {
      const incomingTransfers = await mitTokenContract.queryFilter(
        incomingTransfersEvents,
        fromBlock,
        'latest'
      );
      const outcomingTransfers = await mitTokenContract.queryFilter(
        outcomingTransfersEvents,
        fromBlock,
        'latest'
      );

      const currentBlock = await provider.getBlockNumber();

      dispatch(setFromBlock(currentBlock + 1));

      const formattedIncomingTransfers = await Promise.all(
        incomingTransfers
          .filter((transfer) => transfer.logIndex === 0)
          .map(async (transfer) => {
            const formattedTransfer = await formatTransfer(transfer);
            return formattedTransfer;
          })
      );

      const formattedOutcomingTransfers = await Promise.all(
        outcomingTransfers
          .filter((transfer) => transfer.logIndex === 0)
          .map(async (transfer) => {
            const formattedTransfer = await formatTransfer(transfer);
            return formattedTransfer;
          })
      );

      return {
        address,
        fromBlock,
        transfers: [
          ...formattedIncomingTransfers,
          ...formattedOutcomingTransfers,
        ],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBalance = createAsyncThunk<number, void>(
  'transfers/fetchBalance',
  async (_, { rejectWithValue, getState }) => {
    const {
      user: {
        wallet: { address },
      },
    } = getState() as {
      user: { wallet: { address: string } };
    };

    try {
      const balance = await mitTokenContract.balanceOf(address);

      return Number(ethers.utils.formatEther(balance));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
