import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';

import { Navigation } from '../..';
import { LogoKnobsFilled } from '../../../assets/icons';
import { UserState } from '../../../store/user/types';
import { useAppSelector } from '../../../hooks';

const Sidebar = ({
  isSidebarOpen,
  isExternalPage,
}: {
  isSidebarOpen: boolean;
  isExternalPage?: boolean;
}) => {
  const { t } = useTranslation();
  const { role } = useAppSelector(({ user }: { user: UserState }) => user);

  return (
    <AnimatePresence>
      {isSidebarOpen && (
        <motion.aside
          initial={{ x: '100%' }}
          animate={{
            x: 0,
          }}
          exit={{
            x: '100%',
          }}
          transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
          className={`fixed top-0 right-0 w-full h-full ${
            !isExternalPage
              ? 'bg-primary text-white'
              : 'bg-light-gray text-gray'
          } z-20`}
        >
          <div className="absolute w-full top-24 h-[calc(100%-6rem-5rem)]">
            <SimpleBar className="w-full h-full">
              <Navigation isExternalPage={isExternalPage} />
            </SimpleBar>
            <span
              className={`flex items-center justify-center text-sm md:flex-row ${
                role === 'guest' ? 'text-primary-text' : 'text-white'
              }`}
            >
              <span className="pt-1 pr-1">Powered by </span>
              <a target="_blank" rel="noreferrer" href={t('link_knobs')}>
                <LogoKnobsFilled
                  className={`fill-current ${
                    role === 'guest' ? 'text-primary-text' : 'text-white'
                  }`}
                />
              </a>
            </span>
          </div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
};

Sidebar.defaultProps = {
  isExternalPage: false,
};

export default Sidebar;
