import { signOut } from 'firebase/auth';
import { useDispatch } from 'react-redux';

import { logout } from '../../store/user/user.slice';
import { auth } from '../../firebase';

const useCheckSession = () => {
  const dispatch = useDispatch();

  const lastLoginDate = auth.currentUser?.metadata.lastSignInTime;

  const logOutExpired = () => {
    if (lastLoginDate) {
      const lastLoginTimeStamp = Date.parse(lastLoginDate);
      const daysPassedSinceLastLogin =
        (Date.now() - lastLoginTimeStamp) / (1000 * 3600 * 24);
      if (daysPassedSinceLastLogin >= 30) {
        signOut(auth).then(() => {
          dispatch(logout());
        });
      }
    }
  };

  return { logOutExpired };
};

export default useCheckSession;
