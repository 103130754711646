import React, { createContext, useReducer, useContext } from 'react';
import {
  AppAction,
  AppDispatch,
  AppState,
  ChildrenProp,
} from '../imports/types';

const initialState = {
  isSidebarOpen: false,
  dialog: {
    isOpen: false,
    content: undefined,
    fromBottom: false,
    isBgOverlayInteractive: true,
  },
  qrCodeScanner: {
    isOpen: false,
    primaryAction: undefined,
    scannerOnly: false,
    labels: {
      scanCode: {
        title: '',
        subtitle: '',
      },
      enterCode: {
        title: '',
        subtitle: '',
        inputLabel: '',
        inputPlaceholder: '',
        primaryButton: '',
      },
    },
  },
};

const AppContext = createContext<
  { state: AppState; dispatch: AppDispatch } | undefined
>(undefined);
const appReducer = (state: AppState, action: AppAction) => {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return { ...state, isSidebarOpen: true };
    }
    case 'CLOSE_SIDEBAR': {
      return { ...state, isSidebarOpen: initialState.isSidebarOpen };
    }
    case 'TOGGLE_SIDEBAR': {
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    }
    case 'OPEN_DIALOG': {
      return {
        ...state,
        dialog: {
          isOpen: true,
          content: action.payload.content,
          fromBottom: !!action.payload.fromBottom,
          isBgOverlayInteractive: action.payload.isBgOverlayInteractive ?? true,
        },
      };
    }
    case 'CLOSE_DIALOG': {
      return { ...state, dialog: initialState.dialog };
    }
    case 'OPEN_QR_SCANNER': {
      return {
        ...state,
        qrCodeScanner: {
          isOpen: true,
          primaryAction: action.payload.primaryAction,
          scannerOnly: action.payload.scannerOnly,
          labels: action.payload.labels,
        },
      };
    }
    case 'CLOSE_QR_SCANNER': {
      return { ...state, qrCodeScanner: initialState.qrCodeScanner };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const AppProvider = ({ children }: ChildrenProp) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const value = { state, dispatch };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider');
  }
  return context;
};

export { AppProvider, useApp };
