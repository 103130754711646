import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { regex } from '../../imports/constants';
import { numberWithCommaDecimalsToNumber } from '../../imports/utils';

const validateEmail = (errorMessage: string) =>
  Yup.string().email(errorMessage);

const validateAddress = (errorMessage: string) =>
  Yup.string().test('is-address-valid', errorMessage, (value = '') => {
    if (value[0] === '0') {
      return regex.address.test(value);
    }

    if (value[0] === '@') {
      return true;
    }

    return false;
  });

const validateNickname = (errorMessage: string) =>
  Yup.string().test('is-nickcname-valid', errorMessage, (value = '') =>
    regex.nickname.test(value)
  );

const validatePhoneNumber = (errorMessage: string) =>
  Yup.string().test('is-phone-number-valid', errorMessage, (value = '') => {
    if (Number.isNaN(Number(value)) && value !== '') {
      return false;
    }

    return true;
  });

const validatePassword = (
  errorMessageMinLength: string,
  errorMessageMaxLength: string,
  errorMessageAtLeastOneNumber: string,
  errorMessageAtLeastOneLowercaseChar: string,
  errorMessageAtLeastOneUppercaseChar: string
) =>
  Yup.string()
    .min(6, errorMessageMinLength)
    .max(21, errorMessageMaxLength)
    .matches(/\d/g, errorMessageAtLeastOneNumber)
    .matches(/[a-z]/g, errorMessageAtLeastOneLowercaseChar)
    .matches(/[A-Z]/g, errorMessageAtLeastOneUppercaseChar);

const validateConfirmPassword = (
  errorMessage: string,
  ref: string = 'password'
) => Yup.string().oneOf([Yup.ref(ref), null], errorMessage);

const validateVerificationCodeNumber = () =>
  Yup.array().min(6).max(6).of(Yup.number().min(0).max(9));

const validateFiscalCode = (errorMessage: string) =>
  Yup.string().test('is-fiscal-code-valid', errorMessage, (value = '') => {
    const isValidFiscalCode = regex.fiscalCode.test(value);

    if (value === '') {
      return true;
    }

    if (!isValidFiscalCode) {
      return false;
    }

    return true;
  });

const validatePostalCode = (errorMessage: string) =>
  Yup.string().test('is-postal-code-valid', errorMessage, (value = '') => {
    const isValidPostalCode = regex.cap.test(value);

    if (!isValidPostalCode) {
      return false;
    }

    return true;
  });

const validateFutureDate = (errorMessage: string) =>
  Yup.date().test('is-future-date', errorMessage, (value) => {
    const now = Date.now() / 1000;

    if (value) {
      const timestamp = value?.getTime() / 1000;
      if (timestamp > now) {
        return true;
      }
    }

    return false;
  });

const validatePastDate = (errorMessage: string) =>
  Yup.date().test('is-past-date', errorMessage, (value) => {
    const now = Date.now() / 1000;

    if (value) {
      const timestamp = value?.getTime() / 1000;
      if (timestamp < now) {
        return true;
      }
    }

    return false;
  });

const validatePhoneOrEmail = (errorMessage: string) =>
  Yup.string().test('test-login-method', errorMessage, (value = '') => {
    const isValidPhone = !Number.isNaN(Number(value));
    const isValidEmail = regex.email.test(value);

    if (!isValidEmail && !isValidPhone) {
      return false;
    }

    return true;
  });

const validateAmountInTokens = (
  {
    minAmountOfCreditToSend,
    maxAmountOfCreditToSend,
  }: {
    minAmountOfCreditToSend: number;
    maxAmountOfCreditToSend: number;
  },
  errorMessageAmountNotValid: string,
  errorMessageMinAmount: string,
  errorMessageMaxAmount: string
) =>
  Yup.string()
    .test('amount-not-valid', errorMessageAmountNotValid, (value) => {
      if (value) {
        const amountInTokens = Number(numberWithCommaDecimalsToNumber(value));

        if (Number.isNaN(amountInTokens)) {
          return false;
        }

        return true;
      }

      return false;
    })
    .test('min-amount-is-not-valid', errorMessageMinAmount, (value) => {
      if (value) {
        const amountInTokens = Number(numberWithCommaDecimalsToNumber(value));

        if (amountInTokens < minAmountOfCreditToSend) {
          return false;
        }

        return true;
      }

      return false;
    })
    .test('max-amount-is-not-valid', errorMessageMaxAmount, (value) => {
      if (value) {
        const amountInTokens = Number(numberWithCommaDecimalsToNumber(value));

        if (amountInTokens > maxAmountOfCreditToSend) {
          return false;
        }

        return true;
      }

      return false;
    });

const validateAmountToSendInTokens = (
  {
    minAmountOfCreditToSend,
    maxAmountOfCreditToSend,
    balance,
  }: {
    minAmountOfCreditToSend: number;
    maxAmountOfCreditToSend: number;
    balance: number;
  },
  errorMessageAmountNotValid: string,
  errorMessageMinAmount: string,
  errorMessageMaxAmount: string,
  errorMessageCreditNotEnough: string
) =>
  Yup.string()
    .test('amount-not-valid', errorMessageAmountNotValid, (value) => {
      if (value) {
        const amountInTokens = Number(numberWithCommaDecimalsToNumber(value));

        if (Number.isNaN(amountInTokens)) {
          return false;
        }

        return true;
      }

      return false;
    })
    .test('min-amount-is-not-valid', errorMessageMinAmount, (value) => {
      if (value) {
        const amountInTokens = Number(numberWithCommaDecimalsToNumber(value));

        if (amountInTokens < minAmountOfCreditToSend) {
          return false;
        }

        return true;
      }

      return false;
    })
    .test('max-amount-is-not-valid', errorMessageMaxAmount, (value) => {
      if (value) {
        const amountInTokens = Number(numberWithCommaDecimalsToNumber(value));

        if (amountInTokens > maxAmountOfCreditToSend) {
          return false;
        }

        return true;
      }

      return false;
    })
    .test('credit-not-enough', errorMessageCreditNotEnough, (value) => {
      if (value) {
        const amountInTokens = Number(numberWithCommaDecimalsToNumber(value));

        if (balance < amountInTokens) {
          return false;
        }

        return true;
      }

      return false;
    });

const required = (validation: Yup.AnySchema, errorMessage: string) =>
  validation.required(errorMessage);

export const useValidation = () => {
  const { t } = useTranslation();

  return {
    validateEmail: () => validateEmail(t('validation.error_email_not_valid')),
    validateAddress: () => validateAddress(t('validation.error_address')),
    validateNickname: () => validateAddress(t('validation.error_nickname')),
    validatePhoneNumber: () =>
      validatePhoneNumber(t('validation.error_phone_number_not_valid')),
    validatePassword: () =>
      validatePassword(
        t('validation.error_password_min_length', { minChars: 6 }),
        t('validation.error_password_max_length', { maxChars: 21 }),
        t('validation.error_password_must_contains_one_number'),
        t('validation.error_password_must_contains_one_lowercase_char'),
        t('validation.error_password_must_contains_one_uppercase_char')
      ),
    validateConfirmPassword: (ref?: string) =>
      validateConfirmPassword(t('validation.error_passwords_must_match'), ref),
    validateVerificationCodeNumber: () => validateVerificationCodeNumber(),
    validateFiscalCode: () =>
      validateFiscalCode(t('validation.error_fiscal_code_not_valid')),
    validatePostalCode: () =>
      validatePostalCode(t('validation.error_postal_code_not_valid')),
    validatePhoneOrEmail: () =>
      validatePhoneOrEmail(t('validation.error_login_method_not_valid')),
    validateFutureDate: () =>
      validateFutureDate(t('validation.error_future_date_not_valid')),
    validatePastDate: () =>
      validatePastDate(t('validation.error_past_date_not_valid')),
    validateAmountInTokens: ({
      minAmountOfCreditToSend,
      maxAmountOfCreditToSend,
    }: {
      minAmountOfCreditToSend: number;
      maxAmountOfCreditToSend: number;
    }) =>
      validateAmountInTokens(
        { minAmountOfCreditToSend, maxAmountOfCreditToSend },
        t('validation.error_amount_not_valid'),
        t('validation.error_min_amount_is_not_valid', {
          minAmount: minAmountOfCreditToSend,
        }),
        t('validation.error_max_amount_is_not_valid', {
          maxAmount: maxAmountOfCreditToSend,
        })
      ),
    validateAmountToSendInTokens: ({
      minAmountOfCreditToSend,
      maxAmountOfCreditToSend,
      balance,
    }: {
      minAmountOfCreditToSend: number;
      maxAmountOfCreditToSend: number;
      balance: number;
    }) =>
      validateAmountToSendInTokens(
        { minAmountOfCreditToSend, maxAmountOfCreditToSend, balance },
        t('validation.error_amount_not_valid'),
        t('validation.error_min_amount_is_not_valid', {
          minAmount: minAmountOfCreditToSend,
        }),
        t('validation.error_max_amount_is_not_valid', {
          maxAmount: maxAmountOfCreditToSend,
        }),
        t('insufficient_credit')
      ),
    required: (validation: Yup.AnySchema) =>
      required(validation, t('validation.error_field_is_required')),
  };
};

export default useValidation;
