import * as React from 'react';
import Tippy from '@tippyjs/react';
import { animateFill, followCursor } from 'tippy.js';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/themes/translucent.css';

const Tooltip = ({
  content,
  children,
}: {
  content: React.ReactNode;
  children: React.ReactElement;
}) => (
  <Tippy
    content={<div className="flex items-center">{content}</div>}
    maxWidth={200}
    animateFill
    followCursor
    theme="translucent"
    delay={[400, null]}
    plugins={[animateFill, followCursor]}
  >
    {children}
  </Tippy>
);

export default Tooltip;
