import * as React from 'react';

const ProfileFilled = ({ primaryColor }: { primaryColor: string }) => (
  <svg viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <circle id="path-1" cx="19" cy="19" r="19" />
    </defs>
    <g
      id="App-merchant"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="35_richiesta_Impronta"
        transform="translate(-307.000000, -57.000000)"
      >
        <g id="Group-17" transform="translate(307.000000, 57.000000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <use id="Mask" fill="#F8FCFC" xlinkHref="#path-1" />
          <g id="profile" mask="url(#mask-2)" fillOpacity="0.5">
            <g transform="translate(7.292929, 11.131313)" id="Group-22">
              <circle
                id="Oval"
                fill={primaryColor}
                cx="11.7070707"
                cy="6.33333333"
                r="6.33333333"
              />
              <path
                d="M0,27.2525253 C0,20.6808946 5.24143409,15.3535354 11.7070707,15.3535354 C18.1727073,15.3535354 23.4141414,20.6808946 23.4141414,27.2525253"
                id="Shape"
                fill={primaryColor}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ProfileFilled;
