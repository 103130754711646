import React, { useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { UserState } from '../../../../store/user/types';
import CustomButton from '../../../atoms/Button';
import CloseButton from '../../../atoms/CloseButton';
import { useYupValidationResolver } from '../../../../hooks';
import { maxUploadedFileSize } from '../../../../imports/constants';

interface FormTypes {
  document_photo_front: string;
  document_photo_back: string;
  profile_photo: string;
}

interface DocumentFiles {
  document_photo_front: File | undefined;
  document_photo_back: File | undefined;
  profile_photo: File | undefined;
}

type Props = {
  formInitialData: FormTypes;
  updateFormData: any;
  prevStep?: () => void;
  files: DocumentFiles;
  setFiles: any;
};

const StepThree: React.FC<Props> = ({
  prevStep,
  formInitialData,
  updateFormData,
  files,
  setFiles,
}) => {
  const { t } = useTranslation();

  const { role } = useSelector(({ user }: { user: UserState }) => user);

  const personalDataForm = {
    initialValues: formInitialData,
    validationSchema: Yup.object({
      document_photo_front: Yup.mixed().test(
        'FILE_SIZE',
        t('file_upload_error'),
        (value) => value && value.size <= maxUploadedFileSize
      ),
      document_photo_back: Yup.mixed().test(
        'FILE_SIZE',
        t('file_upload_error'),
        (value) => value && value.size <= maxUploadedFileSize
      ),
      profile_photo: Yup.mixed().test(
        'FILE_SIZE',
        t('file_upload_error'),
        (value) => value && value.size <= maxUploadedFileSize
      ),
    }),
  };

  const { initialValues, validationSchema } = personalDataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formState;

  const inputFileRefFirst = useRef<HTMLInputElement>(null);
  const inputFileRefSecond = useRef<HTMLInputElement>(null);
  const inputFileRefProfile = useRef<HTMLInputElement>(null);

  const focusInputFileFirst = () => {
    if (inputFileRefFirst.current) {
      inputFileRefFirst.current.click();
    }
  };

  const focusInputFileSecond = () => {
    if (inputFileRefSecond.current) {
      inputFileRefSecond.current.click();
    }
  };

  const focusInputFileProfile = () => {
    if (inputFileRefProfile.current) {
      inputFileRefProfile.current.click();
    }
  };

  const goBack = () => {
    prevStep?.();
  };

  const onSubmit = (data: FormTypes) => {
    updateFormData(data);
  };

  const isError = !isEmpty(errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:justify-between md:flex-wrap">
        <p>{t('document_upload_description')}</p>

        <div className="w-full mt-6">
          <label
            htmlFor="document_photo_front"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_photo_front')}
          </label>
          {!files.document_photo_front ? (
            <Controller
              name="document_photo_front"
              control={control}
              render={({ field: { name, onChange } }) => (
                <div className="flex">
                  <input
                    name={name}
                    type="file"
                    accept="image/*"
                    hidden
                    ref={inputFileRefSecond}
                    onChange={async () => {
                      if (inputFileRefSecond.current?.files) {
                        const file = inputFileRefSecond.current.files[0];

                        setFiles({ ...files, document_photo_front: file });
                        onChange(file);
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="text-[#6B6B6B] font-gotham-bold justify-start pl-2"
                    onClick={focusInputFileSecond}
                  >
                    {t('click_to_upload')}
                  </button>
                </div>
              )}
            />
          ) : (
            <div className="grid grid-cols-2 pl-2">
              <p className="flex items-center font-gotham-bold text-[#6B6B6B]">
                {files.document_photo_front.name}
              </p>
              <CloseButton
                handleClose={() =>
                  setFiles({ ...files, document_photo_front: undefined })
                }
              />
            </div>
          )}
          <div className="min-h-[2rem] mt-2">
            {errors.document_photo_front && (
              <p className="text-xs text-error">
                {errors.document_photo_front.message}
              </p>
            )}
          </div>
        </div>

        <div className="w-full mt-6">
          <label
            htmlFor="document_photo_back"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('document_photo_back')}
          </label>
          {!files.document_photo_back ? (
            <Controller
              name="document_photo_back"
              control={control}
              render={({ field: { name, onChange } }) => (
                <div className="flex">
                  <input
                    name={name}
                    type="file"
                    accept="image/*"
                    hidden
                    ref={inputFileRefFirst}
                    onChange={async () => {
                      if (inputFileRefFirst.current?.files) {
                        const file = inputFileRefFirst.current.files[0];

                        setFiles({ ...files, document_photo_back: file });
                        onChange(file);
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="text-[#6B6B6B] font-gotham-bold justify-start pl-2"
                    onClick={focusInputFileFirst}
                  >
                    {t('click_to_upload')}
                  </button>
                </div>
              )}
            />
          ) : (
            <div className="grid grid-cols-2 pl-2">
              <p className="flex items-center font-gotham-bold text-[#6B6B6B]">
                {files.document_photo_back.name}
              </p>
              <CloseButton
                handleClose={() =>
                  setFiles({ ...files, document_photo_back: undefined })
                }
              />
            </div>
          )}
          <div className="min-h-[2rem] mt-2">
            {errors.document_photo_back && (
              <p className="text-xs text-error">
                {errors.document_photo_back.message}
              </p>
            )}
          </div>
        </div>

        <div className="w-full mt-6">
          <label
            htmlFor="profile_photo"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('kyc_profile_proof')}
          </label>
          {!files.profile_photo ? (
            <Controller
              name="profile_photo"
              control={control}
              render={({ field: { name, onChange } }) => (
                <div className="flex">
                  <input
                    name={name}
                    type="file"
                    accept="image/*"
                    hidden
                    ref={inputFileRefProfile}
                    onChange={async () => {
                      if (inputFileRefProfile.current?.files) {
                        const file = inputFileRefProfile.current.files[0];

                        setFiles({ ...files, profile_photo: file });
                        onChange(file);
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="text-[#6B6B6B] font-gotham-bold justify-start pl-2"
                    onClick={focusInputFileProfile}
                  >
                    {t('click_to_upload')}
                  </button>
                </div>
              )}
            />
          ) : (
            <div className="grid grid-cols-2 pl-2">
              <p className="flex items-center font-gotham-bold text-[#6B6B6B]">
                {files.profile_photo.name}
              </p>
              <CloseButton
                handleClose={() =>
                  setFiles({ ...files, profile_photo: undefined })
                }
              />
            </div>
          )}
          <div className="min-h-[2rem] mt-2">
            {errors.profile_photo && (
              <p className="text-xs text-error">
                {errors.profile_photo.message}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-x-6">
        <CustomButton
          primaryAction={goBack}
          label={t('back_to_prev_step')}
          type="button"
        />
        <CustomButton
          label={role === 'consumer' ? t('ends_verification') : t('next_step')}
          type="submit"
          disabled={isError}
        />
      </div>
    </form>
  );
};

StepThree.defaultProps = {
  prevStep: () => {},
};

export default StepThree;
