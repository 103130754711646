import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  useValidation,
  useYupValidationResolver,
} from '../../../../../../hooks';
import CustomButton from '../../../../../atoms/Button';
import CustomInput from '../../../../../atoms/Input';
import CustomSelect from '../../../../../atoms/Select';
import CustomPhoneInput from '../../../../../atoms/PhoneInput';
import { Alert } from '../../../../../../assets/icons';
import {
  companyTypes,
  worldCountries,
} from '../../../../../../imports/constants';
import CustomSelectPlacesAutocomplete from '../../../../../atoms/PlacesSelect';

interface FormTypes {
  commercialName: string;
  businessName: string;
  address: string;
  cap: string;
  city: string;
  province: string;
  state: string;
  streetNumber: string;
  phone: string;
  email: string;
  fiscalCode: string;
  economicActivitySector: string;
  economicActivityDescription: string;
}

type Props = {
  prevStep?: () => void;
  nextStep: () => void;
  updateFormData: any;
  initialData?: FormTypes;
  disabledForm?: boolean;
};

const StepFour: React.FC<Props> = ({
  prevStep,
  nextStep,
  updateFormData,
  initialData,
  disabledForm,
}) => {
  const { t } = useTranslation();

  const { validatePostalCode, validateEmail, validatePhoneNumber } =
    useValidation();

  const personalDataForm = {
    initialValues: initialData,
    validationSchema: Yup.object({
      commercialName: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      businessName: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      address: Yup.string().required(t('validation.error_field_is_required')),
      cap: validatePostalCode(),
      city: Yup.string().required(t('validation.error_field_is_required')),
      province: Yup.string().required(t('validation.error_field_is_required')),
      state: Yup.string().required(t('validation.error_field_is_required')),
      streetNumber: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      email: validateEmail().required(t('validation.error_field_is_required')),
      phone: validatePhoneNumber().required(
        t('validation.error_field_is_required')
      ),
      economicActivitySector: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      fiscalCode: Yup.string().required(
        t('validation.error_field_is_required')
      ),
      economicActivityDescription: Yup.string().required(
        t('validation.error_field_is_required')
      ),
    }),
  };

  const { initialValues, validationSchema } = personalDataForm;

  const resolver = useYupValidationResolver(validationSchema);

  const formState = useForm<FormTypes>({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formState;

  const goBack = () => {
    prevStep?.();
  };

  const onSubmit = (data: any) => {
    updateFormData(data);
    nextStep();
  };

  const isError = !isEmpty(errors);

  const categories = companyTypes.map((type) => ({
    label: t(`companyTypes:${type}`),
    id: t(`companyTypes:${type}`, { lng: 'it' }),
  }));

  const companyCategoriesSorted = categories.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:justify-between md:flex-wrap">
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="commercialName"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('commercialName')}
          </label>
          <Controller
            control={control}
            name="commercialName"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.commercialName}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.commercialName && (
              <p className="text-xs text-error">
                {errors.commercialName.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="businessName"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('businessName')}
          </label>
          <Controller
            control={control}
            name="businessName"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.businessName}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.businessName && (
              <p className="text-xs text-error">
                {errors.businessName.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="email"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('email_address')}
          </label>
          <Controller
            name="email"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.email}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.email && (
              <p className="text-xs text-error">{errors.email.message}</p>
            )}
          </div>
        </div>

        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="phone"
            className="text-sm cursor-pointer md:text-base text-primary-text"
          >
            {t('phone_number_company')}
          </label>
          <div className="relative mt-2">
            <Controller
              name="phone"
              control={control}
              render={({ field: { name, onBlur, onChange, ref, value } }) => (
                <CustomPhoneInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.phone}
                  disabled={disabledForm}
                />
              )}
            />
            {errors.phone && (
              <Alert className="absolute right-0 w-5 h-5 translate-y-[calc(-50%-0.25rem)] fill-current top-1/2 text-error" />
            )}
          </div>
          <div className="min-h-[2rem] mt-2">
            {errors.phone && (
              <p className="text-xs text-error">{errors.phone.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="address"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('company_address')}
          </label>
          <Controller
            name="address"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.address}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.address && (
              <p className="text-xs text-error">{errors.address.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="streetNumber"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('street_number')}
          </label>
          <Controller
            name="streetNumber"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.streetNumber}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.streetNumber && (
              <p className="text-xs text-error">
                {errors.streetNumber.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="city"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('city')}
          </label>
          <Controller
            name="city"
            control={control}
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelectPlacesAutocomplete
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  requestOptions={{
                    types: ['(cities)'],
                  }}
                  error={errors.city}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.city && (
              <p className="text-xs text-error">{errors.city.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="province"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('district')}
          </label>
          <Controller
            name="province"
            control={control}
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelectPlacesAutocomplete
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  requestOptions={{
                    types: ['(cities)'],
                  }}
                  error={errors.province}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.province && (
              <p className="text-xs text-error">{errors.province.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="state"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('country')}
          </label>
          <Controller
            name="state"
            control={control}
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  error={errors.state}
                  disabled={disabledForm}
                  value={[{ label: t('select_placeholder'), id: value }]}
                  options={worldCountries.map((country: string) => ({
                    label: t(`countries:${country}`),
                    id: t(`countries:${country}`, { lng: 'it' }),
                  }))}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.state && (
              <p className="text-xs text-error">{errors.state.message}</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-[calc(50%-1rem)]">
          <label
            htmlFor="cap"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('cap')}
          </label>
          <Controller
            name="cap"
            control={control}
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.cap}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.cap && (
              <p className="text-xs text-error">{errors.cap.message}</p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="economicActivitySector"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('economicActivitySector')}
          </label>
          <Controller
            control={control}
            name="economicActivitySector"
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomSelect
                  onBlur={onBlur}
                  onChange={onChange}
                  controlRef={ref}
                  value={[{ label: t(`select_placeholder`), id: value }]}
                  options={companyCategoriesSorted}
                  error={errors.economicActivitySector}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.economicActivitySector && (
              <p className="text-xs text-error">
                {errors.economicActivitySector.message}
              </p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="fiscalCode"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('vat_number')}
          </label>
          <Controller
            control={control}
            name="fiscalCode"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.fiscalCode}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.fiscalCode && (
              <p className="text-xs text-error">{errors.fiscalCode.message}</p>
            )}
          </div>
        </div>
        <div className="w-full">
          <label
            htmlFor="economicActivityDescription"
            className="text-sm cursor-pointer md:text-base text-primary-text font-gotham-bold"
          >
            {t('company_description')}
          </label>
          <Controller
            control={control}
            name="economicActivityDescription"
            render={({ field: { name, onBlur, onChange, ref, value } }) => (
              <div className="relative mt-2">
                <CustomInput
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={errors.economicActivityDescription}
                  disabled={disabledForm}
                />
              </div>
            )}
          />
          <div className="min-h-[2rem] mt-2">
            {errors.economicActivityDescription && (
              <p className="text-xs text-error">
                {errors.economicActivityDescription.message}
              </p>
            )}
          </div>
        </div>
      </div>
      {!disabledForm && (
        <div className="flex gap-x-6">
          <CustomButton
            primaryAction={goBack}
            label={t('back_to_prev_step')}
            type="button"
          />
          <CustomButton
            label={t('ends_verification')}
            type="submit"
            disabled={isError}
          />
        </div>
      )}
    </form>
  );
};

StepFour.defaultProps = {
  disabledForm: false,
  initialData: {
    commercialName: '',
    businessName: '',
    address: '',
    cap: '',
    city: '',
    province: '',
    state: '',
    streetNumber: '',
    email: '',
    phone: '',
    economicActivitySector: '',
    fiscalCode: '',
    economicActivityDescription: '',
  },
  prevStep: () => {},
};

export default StepFour;
