import { useEffect, useState } from 'react';
import { getPublicProfiles } from '../../store/publicProfiles/publicProfiles.slice';
import { useAppSelector } from '../utils/reduxUtils';

const useNickname = (address: string) => {
  const [nickname, setNickname] = useState('0x00');
  const { list: publicProfiles, loader } = useAppSelector(getPublicProfiles);

  const getNickname = () => {
    let value = address;
    const ref = publicProfiles.filter(
      (publicProfile) => publicProfile.walletAddress === address
    );

    if (ref.length > 0) {
      value = ref[0].nickname;
    }

    if (address === '0x43b40B7b680FBF07C989Dc213933445373509585') {
      value = '@MitGate';
    }

    if (address === '0x0000000000000000000000000000000000000000') {
      value = '@MitGateStake';
    }

    if (value.includes('0x')) {
      value = address.slice(0, 4).concat('....') + address.slice(38);
    }

    setNickname(value);
  };

  useEffect(() => {
    if (loader === false) {
      getNickname();
    }
  }, [loader]);

  return { nickname };
};

export default useNickname;
