import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useMedia, usePrevious } from 'react-use';
import { Sidebar, Navigation } from '../..';

import { useApp } from '../../../context/app.context';

import { LogoConsumer, LogoMerchant, LogoWhite } from '../../../assets/images';

import { application, roles } from '../../../imports/constants';
import { screen } from '../../../imports/utils';

const Header = ({
  isHeaderHidden,
  isExternalPage,
  isSidebarHidden,
}: {
  isHeaderHidden?: boolean;
  isExternalPage?: boolean;
  isSidebarHidden?: boolean;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMedia(`(min-width: ${screen('md')})`);
  const prevIsDesktop = usePrevious(isDesktop);

  const currentPath = window.location.pathname;
  const currentPathLng = currentPath.split('/')[1];

  const {
    state: { isSidebarOpen },
    dispatch,
  } = useApp();

  const ThemedLogo =
    application === roles.MERCHANT ? LogoMerchant : LogoConsumer;

  const toggleSidebar = () => {
    dispatch({ type: 'TOGGLE_SIDEBAR' });
  };

  useEffect(() => {
    if (isDesktop && !prevIsDesktop) {
      if (isSidebarOpen) {
        dispatch({ type: 'CLOSE_SIDEBAR' });
      }
    }
  }, [isDesktop]);

  return !isHeaderHidden ? (
    <header>
      <div
        className={`fixed top-0 left-0 flex items-center ${
          isSidebarHidden
            ? 'justify-center md:justify-start'
            : 'justify-between'
        } w-full h-24 px-8 xs:px-14 sm:h-14 md:h-24 sm:px-20 md:px-8 ${
          !isExternalPage ? 'text-white bg-primary' : 'text-gray bg-transparent'
        } transform z-30`}
      >
        <a href={`/${currentPathLng}`}>
          {!isExternalPage && <LogoWhite className="w-36 md:w-48" />}
          {isExternalPage && <ThemedLogo className="w-36 md:w-48" />}
        </a>
        {!isSidebarHidden && !isDesktop && (
          <button
            type="button"
            aria-label={t('open_sidebar')}
            className="p-2"
            onClick={toggleSidebar}
          >
            <svg
              viewBox="0 0 29 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`w-9 h-9 fill-current ${
                !isExternalPage ? 'text-white' : 'text-gray'
              }`}
            >
              <path d="M8.75 13.5C8.336 13.5 8 13.164 8 12.75C8 12.336 8.336 12 8.75 12H28.25C28.664 12 29 12.336 29 12.75C29 13.164 28.664 13.5 28.25 13.5H8.75Z" />

              <motion.path
                initial={false}
                animate={isSidebarOpen ? 'open' : 'closed'}
                variants={{
                  closed: {
                    d: 'M8.75 7.5C8.336 7.5 8 7.164 8 6.75C8 6.336 8.336 6 8.75 6H28.25C28.664 6 29 6.336 29 6.75C29 7.164 28.664 7.5 28.25 7.5H8.75Z',
                  },
                  open: {
                    d: 'M0.75 7.5C0.336 7.5 0 7.164 0 6.75C0 6.336 0.336 6 0.75 6H20.25C20.664 6 21 6.336 21 6.75C21 7.164 20.664 7.5 20.25 7.5H0.75Z',
                  },
                }}
              />
              <path d="M8.75 1.5C8.336 1.5 8 1.164 8 0.75C8 0.336 8.336 0 8.75 0H28.25C28.664 0 29 0.336 29 0.75C29 1.164 28.664 1.5 28.25 1.5H8.75Z" />
            </svg>
          </button>
        )}
        {!isSidebarHidden && isDesktop && (
          <Navigation isExternalPage={isExternalPage} />
        )}
      </div>
      {!isSidebarHidden && !isDesktop && (
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          isExternalPage={isExternalPage}
        />
      )}
    </header>
  ) : (
    <></>
  );
};

Header.defaultProps = {
  isHeaderHidden: false,
  isExternalPage: false,
  isSidebarHidden: false,
};

export default Header;
