import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase';

const useCheckAddress = () => {
  const checkIfAddressExist = async (reference: string) => {
    const publicProfilesRef = collection(firestore, 'publicProfiles');

    const fieldToSearch = reference[0] === '@' ? 'nickname' : 'walletAddress';
    const q = query(publicProfilesRef, where(fieldToSearch, '==', reference));

    const querySnapshot = await getDocs(q);

    let recipientAddress = '';

    querySnapshot.forEach((doc) => {
      recipientAddress = doc.data().walletAddress;
    });

    return recipientAddress;
  };

  return { checkIfAddressExist };
};

export default useCheckAddress;
