import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

import { Wallet } from 'ethers';
import { encrypt } from 'crypto-js/aes';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import { userReducer } from './user.reducer';
import { UserState } from './types';

import { firestore, auth } from '../../firebase';

import { application } from '../../imports/constants';

import i18n from '../../i18n';

export const userInitialState: UserState = {
  loader: false,
  role: 'guest',
  authToken: '',
  wallet: {
    address: '',
    privateKey: '',
    mnemonic: '',
  },
  email: '',
  profilePhoto: '',
  privateProfile: {
    uid: '',
    tier: 0,
    createdAt: new Date(),
    walletAddress: '',
    active: true,
  },
  areCookiesAccepted: false,
  availableOffers: [],
  redeemedOffers: [],
};

export const userSlice = createSlice({
  name: 'user', // name used in action types
  initialState: userInitialState, // initial state for the reducer
  reducers: userReducer,
  extraReducers: (builder) => {
    // Registration
    builder.addCase(createUser.pending, (state) => {
      state.loader = true;
    });

    builder.addCase(createUser.fulfilled, (state) => {
      toast.success(i18n.t('successful_registration'));

      state.loader = false;
    });

    builder.addCase(createUser.rejected, (state, action) => {
      toast.error(i18n.t(`firebase_exceptions.${action.payload}`));

      state.loader = false;
    });

    builder.addCase(signIn.rejected, (state, action) => {
      toast.error(i18n.t(`errors.${action.payload}`));

      state.loader = false;
    });
  },
});

export const {
  actions: {
    updatePrivateProfile,
    acceptCookies,
    logout,
    setWallet,
    setAvailableOffers,
    setRedeemedOffers,
    updateAuthToken,
    updateProfilePhoto,
    updateRole,
    updateEmail,
  },
} = userSlice;

// Async thunks
export const createUser = createAsyncThunk<
  boolean,
  { email: string; password: string; wallet: Wallet }
>(
  'user/register',
  async ({ email, password, wallet }, { rejectWithValue, dispatch }) => {
    // Create firebase user

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Update wallets saved in the local storage
      const walletsFromLocalStorage = localStorage.getItem('wallets');

      const wallets: { [email: string]: Wallet | {} } = walletsFromLocalStorage
        ? JSON.parse(walletsFromLocalStorage)
        : {};

      const { address, privateKey, mnemonic } = wallet;
      const walletToSet = { address, privateKey, mnemonic: mnemonic.phrase };

      const encryptedWallet = encrypt(
        JSON.stringify(walletToSet),
        password
      ).toString();

      wallets[email] = encryptedWallet;

      localStorage.setItem('wallets', JSON.stringify(wallets));

      const collectionRef =
        application === 'consumer' ? 'consumers' : 'merchants';

      const docRef = doc(firestore, collectionRef, userCredential.user.uid);

      await setDoc(docRef, {
        tier: 0,
        created_at: new Date(),
        walletAddress: wallet.address,
        active: false,
      });

      // dispatch(setWallet(walletToSet));

      return true;
    } catch (error: any) {
      const formattedErrorMessage = error.message.substring(
        error.message.indexOf('(') + 1,
        error.message.indexOf(')')
      );

      return rejectWithValue(formattedErrorMessage);
    }
  }
);

export const signIn = createAsyncThunk<
  string,
  { mail: string; password: string }
>('user/login', async ({ mail, password }, { rejectWithValue }) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      mail,
      password
    );

    return userCredential.user.uid;
  } catch {
    return rejectWithValue('invalid_credentials');
  }
});
