import * as React from 'react';
import { DatePicker } from 'baseui/datepicker';
import { useMedia } from 'react-use';
import it from 'date-fns/locale/it';
import { FieldError } from 'react-hook-form';
import { Alert } from '../../../assets/icons';
import { twTheme } from '../../../imports/config';
import { application } from '../../../imports/constants';
import { color, screen } from '../../../imports/utils';

const CustomDatePicker = ({
  onChange,
  value,
  error,
  disabled,
}: {
  onChange: any;
  value: Date[] | null;
  error?: FieldError | undefined;
  disabled?: boolean;
}) => {
  const isDesktop = useMedia(`(min-width: ${screen('md')})`);

  const handleChange = (date: any, callback: (...event: any[]) => void) => {
    let value;

    console.log(typeof date);

    if (date) {
      if (Array.isArray(date)) {
        value = date[0].toISOString();
      } else {
        value = date.toISOString();
      }
    } else {
      value = date;
    }

    callback(value);
  };

  return (
    <>
      <DatePicker
        minDate={new Date('01/01/1930')}
        overrides={{
          Input: {
            props: {
              overrides: {
                Root: {
                  style: () => ({
                    borderRightColor: 'transparent',
                    borderLeftColor: 'transparent',
                    borderTopColor: 'transparent',
                    borderBottomColor: `${color(
                      !error ? 'primary' : 'error'
                    )} !important`,
                    backgroundColor: 'transparent',
                    borderBottomWidth: '0.125rem',
                  }),
                },
                Input: {
                  style: () => ({
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: '0.25rem',
                    paddingRight: !error ? '0.25rem' : '1.75rem',
                    backgroundColor: 'transparent',
                    color: color('gray'),
                    fontFamily: 'GothamBookRnd',
                  }),
                },
              },
            },
          },
          Day: {
            style: () => ({
              ':after': {
                borderRightColor: 'transparent',
                borderLeftColor: 'transparent',
                borderTopColor: 'transparent',
                borderBottomColor: 'transparent',
              },
            }),
          },
          MonthYearSelectStatefulMenu: {
            props: {
              overrides: {
                Option: {
                  props: {
                    overrides: {
                      ListItem: {
                        style: () => ({
                          ':hover': {
                            background:
                              twTheme.colors[application][
                                'primary-lighter-hover'
                              ],
                          },
                        }),
                      },
                    },
                  },
                },
              },
            },
          },
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: () => ({
                    display: isDesktop ? 'block' : 'none',
                  }),
                },
              },
            },
          },
        }}
        locale={it}
        value={value}
        onChange={({ date }) => {
          handleChange(date, onChange);
        }}
        formatString="dd/MM/yyyy"
        mask="99/99/9999"
        placeholder="GG/MM/YYYY"
        disabled={disabled}
      />
      {error && (
        <Alert className="absolute right-0 w-5 h-5 translate-y-[calc(-50%-0.25rem)] -translate-x-1 fill-current top-1/2 text-error" />
      )}
    </>
  );
};

CustomDatePicker.defaultProps = {
  error: undefined,
  disabled: false,
};

export default CustomDatePicker;
