import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CloseButton from '../../atoms/CloseButton';

import CustomButton from '../../atoms/Button';

type Props = {
  show: boolean;
  handleClose: () => void;
  buttonLabel?: string;
  navigatePath?: string;
  children: React.ReactNode;
  backgroundColor?: string;
  position?: 'top' | 'bottom';
  hideClose?: boolean;
};

const FixedBanner: React.FC<Props> = ({
  show,
  handleClose,
  buttonLabel,
  navigatePath,
  children,
  backgroundColor,
  position,
  hideClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const showBanner = !show ? 'hidden' : '';

  const handleClick = () => {
    handleClose();
    if (navigatePath) navigate(t(navigatePath));
  };

  return (
    <div
      className={`w-full fixed flex justify-between items-center ${
        position === 'bottom' ? 'p-8' : 'p-2'
      } border-solid border-2 border-primary ${
        position === 'bottom' ? 'bottom-0' : 'top-24 sm:top-14 md:top-24'
      } bg-${backgroundColor} ${showBanner}`}
    >
      <div className="flex-1 pr-2">
        {children}
        {buttonLabel && (
          <div className="max-w-sm p-1 mx-auto mt-8 text-center">
            <CustomButton
              label={buttonLabel.toUpperCase()}
              primaryAction={handleClick}
            />
          </div>
        )}
      </div>
      {!hideClose && (
        <div className="h-full">
          <div className="absolute right-0 top-1">
            <CloseButton handleClose={handleClose} />
          </div>
        </div>
      )}
    </div>
  );
};

FixedBanner.defaultProps = {
  backgroundColor: 'white',
  position: 'bottom',
  buttonLabel: '',
  navigatePath: '',
  hideClose: false,
};

export default FixedBanner;
