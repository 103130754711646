import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  BalanceCard,
  CustomButton,
  ProfilePhoto,
  Loader,
  FloatingDialog,
} from '../components';
import ShowCopy from '../components/atoms/ShowCopy';
import StakeItem from '../components/molecules/StakeItem';
import StakingForm from '../components/organisms/StakingForm';
import { withAuth, useLockMit, useAppSelector } from '../hooks';
import { roles } from '../imports/constants';
import { UserState } from '../store/user/types';
import { getTransfers } from '../store/transfers/transfers.slice';
import Tabs from '../components/molecules/Tabs';
import { LockEvents } from '../hooks/blockchain/useLockMit';

const Staking = () => {
  const { t } = useTranslation();

  const [showForm, setShowForm] = useState(false);
  const [activeTab, setActiveTab] = useState<
    'active-locking' | 'expired-locking'
  >('active-locking');
  const [validEvents, setValidEvents] = useState<LockEvents[]>();
  const [historicalEvents, setHistoricalEvents] = useState<LockEvents[]>();
  const user = useSelector(({ user }: { user: UserState }) => user);

  const {
    lock,
    unlock,
    lockingEvents,
    lockedAmount,
    loader: lockLoader,
    vipLevels,
    currentVipLevel,
    isUnlockable,
  } = useLockMit();

  const { loader: transfersLoader, balance } = useAppSelector(getTransfers);

  const {
    privateProfile: { tierOne },
    profilePhoto,
    wallet: { address },
  } = user;

  const handleLock = (amount: string) => {
    setShowForm(false);
    lock(amount);
  };

  const splitLockingEvents = () => {
    const active: LockEvents[] = [];
    const notActive: LockEvents[] = [];
    const timestamp = lockingEvents?.unlock.reverse()[0]?.unlockTimestamp;
    if (timestamp) {
      lockingEvents?.lock.forEach((el) => {
        if (el.lockTimestamp > timestamp) {
          active.push(el);
        }
        if (el.lockTimestamp <= timestamp) {
          notActive.push(el);
        }
      });
    } else if (timestamp === undefined && lockingEvents) {
      lockingEvents?.lock.forEach((el) => active.push(el));
    }
    setValidEvents(active);
    setHistoricalEvents(notActive);
  };

  useEffect(() => {
    if (lockingEvents) {
      splitLockingEvents();
    }
  }, [lockingEvents]);

  return showForm ? (
    <div className="pt-14">
      <h1 className="text-[2rem] md:text-[2.5rem] leading-10 text-center font-gotham-bold text-primary-text px-8 xs:px-14 sm:px-20 md:px-8">
        {`${t('staking_form_title')}`}
      </h1>
      <FloatingDialog>
        <StakingForm
          onSubmitFunc={handleLock}
          lockedAmount={lockedAmount}
          vipLevels={vipLevels.filter(
            (vipLevel) => vipLevel.level > currentVipLevel
          )}
          maxed={vipLevels.slice(-1)[0].level === currentVipLevel}
          closeForm={() => setShowForm((prev) => !prev)}
        />
      </FloatingDialog>
    </div>
  ) : (
    <div className="max-w-4xl px-8 mx-auto pt-14 xs:pt-20 md:pt-14 xs:px-14 sm:px-20 md:px-8">
      <div className="flex flex-col items-center justify-between md:flex-row md:mt-8">
        <div>
          <div className="flex flex-col items-center justify-center">
            <div className="w-32 h-32">
              <ProfilePhoto image={profilePhoto} />
            </div>
            <h2 className="mt-4 text-[1.4rem] text-center text-gray font-gotham-bold">
              {`${tierOne?.firstName || '-'} ${tierOne?.lastName || '-'}`}
            </h2>
          </div>
          <div className="mt-2 ml-1">
            <ShowCopy
              valueToCopy={address}
              textColor="[#B5B5B5]"
              iconColor="black"
            />
          </div>
        </div>
        <div className="w-full max-w-lg mt-8 md:mt-0">
          <BalanceCard
            balance={balance}
            hasIcon
            inTokens
            showVipLevel
            vipLevel={currentVipLevel}
          />
        </div>
      </div>
      <div className="pb-8 mt-8 md:mt-14">
        <div className="flex flex-col-reverse items-center justify-between md:flex-row">
          <h3 className="text-lg md:text-xl font-gotham-bold text-gray">
            {t('levels_unlocked')}
          </h3>
          <div className="flex flex-row justify-between w-full max-w-lg md:w-auto">
            <div className="mb-4 md:mb-0 md:mr-8 min-w-[45%] md:min-w-[150px]">
              <CustomButton
                label={t('lock_mit')}
                primaryAction={() => setShowForm((prevState) => !prevState)}
                disabled={!(balance > 0)}
              />
            </div>
            <div className="mb-8 md:mb-0 min-w-[45%] md:min-w-[150px]">
              <CustomButton
                label={t('unlock_mit')}
                primaryAction={unlock}
                disabled={!isUnlockable}
              />
            </div>
          </div>
        </div>
        {(transfersLoader || lockLoader) && (
          <div className="relative w-full mt-8 h-96">
            <Loader />
          </div>
        )}

        <>
          <Tabs
            tabs={['active-locking', 'expired-locking']}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className="tab-content" id="tabs-content">
            <div
              className={`tab-pane fade ${
                activeTab === 'active-locking' ? 'show active' : 'hidden'
              }`}
              id="active-tab"
            >
              {validEvents && validEvents.length > 0 ? (
                validEvents
                  .reverse()
                  .map((lockEvent) => (
                    <StakeItem stakingItem={lockEvent} key={lockEvent.hash} />
                  ))
              ) : (
                <p className="my-8 text-center text-gray">{t('no_stake')}</p>
              )}
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === 'expired-locking' ? 'show active' : 'hidden'
              }`}
              id="expired-locking-tab"
            >
              {historicalEvents && historicalEvents.length > 0 ? (
                historicalEvents
                  .reverse()
                  .map((lockEvent) => (
                    <StakeItem stakingItem={lockEvent} key={lockEvent.hash} />
                  ))
              ) : (
                <p className="my-8 text-center text-gray">{t('no_stake')}</p>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default withAuth(Staking, { roles: [roles.CONSUMER] });
