import { ethers, Event } from 'ethers';
import { doc, getDoc } from 'firebase/firestore';

import { firestore } from '../../firebase';

import { FormattedTransfer } from './types';

export const formatTransfer = async (transfer: Event) => {
  const block = await transfer.getBlock();
  const amount = Number(ethers.utils.formatEther(transfer.args?.value));

  let reason = '';

  try {
    const reasonRef = doc(
      firestore,
      'transfersReasons',
      transfer.transactionHash
    );

    const docSnap = await getDoc(reasonRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      reason = data?.reason;
    }
  } catch (error) {
    reason = '';
  }

  return {
    id: transfer.transactionHash,
    from: transfer.args?.from,
    to: transfer.args?.to,
    timestamp: block.timestamp,
    amount,
    reasonForPayment: reason,
  } as FormattedTransfer;
};
